import Nes from 'nes';

import { WS_URI } from '../config';

export default function apiWSConnect(cb) {
  const client = new Nes.Client(WS_URI);

  return client
    .connect({
      auth: {
        headers: {
          authorization: [
            localStorage.getItem('token_type') || 'Bearer',
            localStorage.getItem('access_token'),
          ].join(' '),
        },
      },
    })
    .then(() => cb(client));
}
