import moment from 'moment';
import compact from 'lodash/compact';

export default function renderHumanDateTime(date, opts = {}) {
  if (date) {
    return moment(date).format(compact([
      opts.before,
      'MM/DD/YYYY h:mm a',
      opts.after
    ]).join(opts.separator || ' '));
  }
}
