import { push } from 'connected-react-router';

import { apiRequest } from '../../utils';

import {
  authLoading,
  authStopLoading,
  authSuccessMessage,
  authError,
} from '../AuthActions';

// sendPassword

export default function sendPassword(user) {
  localStorage.removeItem('username');
  localStorage.removeItem('token_type');
  localStorage.removeItem('access_token');

  return dispatch => {
    dispatch(authLoading());

    return apiRequest
      .authPost('auth/reset_password', user)
      .then(() => {
        dispatch(push('/auth'));
        dispatch(authStopLoading());
        dispatch(
          authSuccessMessage(
            'You will receive an email with instructions on how to reset your password in a few minutes.'
          )
        );
      })
      .catch(error => dispatch(authError(error)));
  };
}
