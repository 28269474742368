import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { Layout, Skeleton } from 'antd';
import {
  validateToken,
  logoutUser as logoutUserAction,
} from '../../actions/AuthActions';

import { fetchOrganization as fetchOrganizationAction } from '../../actions/OrganizationActions';
import { fetchAgentSession as fetchAgentSessionAction } from '../../actions/AgentSessionActions';

import { PhoneBar } from '../../components';
import Navigation from '../../components/common/Navigation';
import { Loading } from '../../helpers';

import {
  main,
  organizations,
  callFlows,
  callQueues,
  messages,
  ringGroups,
  users,
  phoneNumbers,
  blacklists,
  favorites,
  voicemails,
  reports,
  requests,
} from '../../pages/routes';
import { LogRocket } from '../../utils';

const { Header, Content, Footer, Sider } = Layout;

class MainContainer extends Component {
  static propTypes = {
    authFetched: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    organization: PropTypes.shape({
      id: PropTypes.number,
      subdomain: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    validateToken: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    fetchAgentSession: PropTypes.func.isRequired,
  };

  state = {
    didIdentify: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    if (!this.props.organization.id && organizationId) {
      this.props.fetchOrganization(organizationId);
    }
    this.props.validateToken();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated &&
      this.props.currentUser.id &&
      !this.state.didIdentify
    ) {
      const {
        id,
        attributes: { email, first_name, last_name },
      } = this.props.currentUser;
      LogRocket.identify(String(id), {
        name: `${first_name} ${last_name}`,
        email,
      });
      this.setState(prevState => ({ ...prevState, didIdentify: true }));
    }
  }

  render() {
    const {
      location: { pathname },
      authFetched,
      isAuthenticated,
      currentUser,
      organization,
      logoutUser,
      agentSession,
      agentSessionLoading,
    } = this.props;

    return (
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={broken => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <Navigation
            isAuthenticated={isAuthenticated}
            userEmail={get(currentUser, 'attributes.email')}
            userName={get(currentUser, 'attributes.name')}
            organization={organization}
            withoutOrganizations={pathname === '/'}
            withoutUsers={pathname === '/'}
            onLogout={() => logoutUser()}
          />
        </Sider>
        <Layout>
          <Content style={{ margin: '24px 16px 0' }}>
            <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
              <Skeleton loading={!authFetched}>
                {main}
                {organizations}
                {callFlows}
                {callQueues}
                {messages}
                {ringGroups}
                {users}
                {phoneNumbers}
                {reports}
                {voicemails}
                {blacklists}
                {favorites}
                {requests}
              </Skeleton>
            </div>
          </Content>
          {organization &&
          agentSession &&
          !agentSessionLoading &&
          get(
            agentSession,
            'data.relationships.session.data.attributes.phone_token'
          ) ? (
            <PhoneBar
              token={get(
                agentSession,
                'data.relationships.session.data.attributes.phone_token'
              )}
              phoneUrl={get(
                agentSession,
                'data.relationships.session.data.attributes.phone_token'
              )}
              session={agentSession}
              onTokenExpired={() =>
                this.props.fetchAgentSession(get(organization, 'id'))
              }
            />
          ) : null}
          <Footer style={{ textAlign: 'center' }}>
            &copy;2018 ContactFrame
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default connect(
  ({
    auth: { authFetched, isAuthenticated, currentUser },
    organizations: { item: organization },
    agentSessions: {
      session: agentSession,
      sessionLoading: agentSessionLoading,
      sessionErrorMessage: agentSessionErrorMessage,
    },
  }) => ({
    authFetched,
    isAuthenticated,
    currentUser,
    organization,
    agentSession,
    agentSessionLoading,
    agentSessionErrorMessage,
  }),
  {
    validateToken,
    fetchOrganization: fetchOrganizationAction,
    logoutUser: logoutUserAction,
    fetchAgentSession: fetchAgentSessionAction,
  }
)(MainContainer);
