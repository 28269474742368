import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  SHOW_FAVORITE_START,
  SHOW_FAVORITE_SUCCESS,
  SHOW_FAVORITE_ERROR
} from '../../constants/Favorite';

// fetchFavorite

export function showFavoriteStart() {
  return {
    type: SHOW_FAVORITE_START
  };
}

export function showFavorite(item = {}) {
  return {
    type: SHOW_FAVORITE_SUCCESS,
    item
  };
}

export function showFavoriteError(error) {
  return {
    type: SHOW_FAVORITE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchFavorite(organizationId, id, options = {}) {
  return dispatch => {
    dispatch(showFavoriteStart());
    
    return apiRequest.get(`organizations/${organizationId}/favorites/${id}`, options)
      .then(({ data: { data: item } }) => dispatch(showFavorite(item)))
      .catch(error => dispatch(showFavoriteError(error)));
  };
}
