import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

function OrganizationInfo({ item }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'attributes.name')}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{get(item, 'attributes.description')}</td>
        </tr>
        <tr>
          <td>API Key</td>
          <td>{get(item, 'attributes.api_key_sid')}</td>
        </tr>
        <tr>
          <td>API Secret</td>
          <td>{get(item, 'attributes.api_key_secret')}</td>
        </tr>
        <tr>
          <td>Twilio Account SID</td>
          <td>{get(item, 'attributes.account_sid')}</td>
        </tr>
        <tr>
          <td>Twilio Workspace SID</td>
          <td>{get(item, 'attributes.workspace_sid')}</td>
        </tr>
        <tr>
          <td>Twilio Chat Service SID</td>
          <td>{get(item, 'attributes.chat_service_sid')}</td>
        </tr>
        <tr>
          <td>Salesforce Instance Type</td>
          <td>{get(item, 'attributes.sf_instance_type')}</td>
        </tr>
        <tr>
          <td>Salesforce Instance URL</td>
          <td>{get(item, 'attributes.sf_instance_url')}</td>
        </tr>
        <tr>
          <td>Salesforce Refresh Token</td>
          <td>{get(item, 'attributes.sf_refresh_token')}</td>
        </tr>
      </tbody>
    </table>
  );
}

OrganizationInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default OrganizationInfo;
