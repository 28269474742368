import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import OrganizationsIndexPage from './OrganizationsIndexPage/OrganizationsIndexPage';
import OrganizationAddPage from './OrganizationAddPage/OrganizationAddPage';
import OrganizationShowPage from './OrganizationShowPage/OrganizationShowPage';
import OrganizationEditPage from './OrganizationEditPage/OrganizationEditPage';
import OrganizationDeletePage from './OrganizationDeletePage/OrganizationDeletePage';

export default [
  <Route
    exact
    key="organizations"
    path="/organizations"
    component={RequireAuth(OrganizationsIndexPage)}
  />,
  <Route
    exact
    key="organization-new"
    path="/organizations/new"
    component={RequireAuth(OrganizationAddPage)}
  />,
  <Route
    exact
    key="organization-show"
    path="/organizations/:organizationId/show"
    component={RequireAuth(OrganizationShowPage)}
  />,
  <Route
    exact
    key="organization-edit"
    path="/organizations/:organizationId/edit"
    component={RequireAuth(OrganizationEditPage)}
  />,
  <Route
    exact
    key="organization-delete"
    path="/organizations/:organizationId/delete"
    component={RequireAuth(OrganizationDeletePage)}
  />
];
