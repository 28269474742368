import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import get from 'lodash/get';
import size from 'lodash/size';

import { Button } from '../../../helpers';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { orderFavorites } from '../../../actions/FavoriteActions';
import { message } from 'antd';

const grid = 8;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Row = ({ item, index, onDelete }) => {
  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging && 'lightblue',
    ...draggableStyle,
  });
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <td>{get(item, 'attributes.index')}</td>
          <td>{get(item, 'attributes.name')}</td>
          <td>{get(item, 'attributes.phone_number')}</td>

          <td>
            <div className="btn-group">
              <Button
                button
                color="outline-danger"
                icon="trash"
                onClick={() => onDelete(item.id)}
              >
                Remove
              </Button>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

function FavoritesTable({ items, meta, onPaginate, onDelete }) {
  const [state, setState] = useState({
    favorites: [],
  });

  useEffect(() => {
    setState({
      favorites: items.map((favorite) => ({
        ...favorite,
        id: String(favorite.id),
      })),
    });
  }, [items]);

  const { organizationId } = useParams();
  const dispatch = useDispatch();

  async function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const stateRef = Array.from(state.favorites);

    const favorites = reorder(
      state.favorites,
      result.source.index,
      result.destination.index
    );

    const updatedFavorites = favorites.map((fav, index) => ({
      ...fav,
      attributes: { ...fav.attributes, index },
    }));

    setState({ favorites: updatedFavorites });

    try {
      await dispatch(
        orderFavorites(
          organizationId,
          favorites.map(({ id }) => ({ id }))
        )
      );
    } catch (err) {
      message.error('There was a problem saving the order. Changes have been reverted, try again.')
      setState({ favorites: stateRef });
    }
  }

  if (size(items) === 0) {
    return <h1>No favorites found</h1>;
  }

  const Rows = React.memo(function Rows({ items }) {
    return items.map((item, index) => (
      <Row item={item} index={index} key={item.id} onDelete={onDelete} />
    ));
  });

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
        <tr>
          <th className="footable-sortable">
            Order
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Name
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Phone Number
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">Actions</th>
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="table">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              <Rows items={state.favorites} />
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  );
}

export default FavoritesTable;
