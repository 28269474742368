import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { UPLOAD_FILE_START, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_ERROR } from '../../constants/File';

// uploadFile

export function uploadFileStart() {
  return {
    type: UPLOAD_FILE_START
  };
}

export function uploadFileSuccess(item = {}) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    item
  };
}

export function uploadFileError(error) {
  return {
    type: UPLOAD_FILE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function uploadFile(organizationId, file) {
  return dispatch => {
    dispatch(uploadFileStart());

    return apiRequest
      .post(`organizations/${organizationId}/messages/upload`, null, file)
      .then(({ data }) => dispatch(uploadFileSuccess(data)))
      .catch(error => dispatch(uploadFileError(error)));
  };
}
