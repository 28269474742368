import Promise from 'bluebird';

import { REMOVE_CALL_FLOW_ENTRY_SUCCESS } from '../../constants/CallFlowEntry';

// removeCallFlowEntry

export function removeCallFlowEntrySuccess(item) {
  return {
    type: REMOVE_CALL_FLOW_ENTRY_SUCCESS,
    item
  };
}

export default function removeCallFlowEntry(entry) {
  return dispatch => Promise.resolve(dispatch(removeCallFlowEntrySuccess(entry)));
}
