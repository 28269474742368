import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createOrganization as createOrganizationAction } from '../../../actions/OrganizationActions';

import { OrganizationForm } from '../../../components';

function OrganizationAddPage({ errorMessage, createOrganization }) {
  return (
    <div className="row" id="organization-add">
      <div className="col-12">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">Create Organization</h3>
          </div>
        </div>

        <div className="card">
          <div className="card-block">
            <OrganizationForm
              errorMessage={errorMessage}
              isNew={true}
              onSubmit={values => createOrganization(values)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

OrganizationAddPage.propTypes = {
  errorMessage: PropTypes.string,
  createOrganization: PropTypes.func.isRequired,
};

OrganizationAddPage.defaultProps = {
  errorMessage: null
};

export default connect(({
  organizations: {
    errorMessage
  }
}) => ({ errorMessage }), { createOrganization: createOrganizationAction })(OrganizationAddPage);
