import React from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import cl from 'classnames';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import keys from 'lodash/keys';

const weekdays = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday'
};

const marks = {
  0: '00:00 AM',
  1: '01:00 AM',
  2: '02:00 AM',
  3: '03:00 AM',
  4: '04:00 AM',
  5: '05:00 AM',
  6: '06:00 AM',
  7: '07:00 AM',
  8: '08:00 AM',
  9: '09:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 AM',
  13: '01:00 PM',
  14: '02:00 PM',
  15: '03:00 PM',
  16: '04:00 PM',
  17: '05:00 PM',
  18: '06:00 PM',
  19: '07:00 PM',
  20: '08:00 PM',
  21: '09:00 PM',
  22: '10:00 PM',
  23: '11:00 PM'
};

function renderRangeSliderField({
  input, label,
  afterChange, meta: { touched, error, warning }
}) {
  return (
    <div
      className={cl('form-group', {
        'has-success': touched && !error && !warning,
        'has-danger': touched && error,
        'has-warning': touched && warning
      })}
    >
      {label ? <label htmlFor={`form-input-${input.name}`}>{label}</label> : null}
      {keys(weekdays).map(key => (
        <div key={key} className="row m-t-30">
          <div className="col-2">
            {weekdays[key]}
          </div>
          <div className="col-10">
            <Range
              min={0}
              max={23}
              value={[
                findKey(marks, mark => mark === get(input.value || {}, `${key}.start1`, '08:00 AM')),
                findKey(marks, mark => mark === get(input.value || {}, `${key}.end1`, '12:00 AM')),
                findKey(marks, mark => mark === get(input.value || {}, `${key}.start2`, '01:00 PM')),
                findKey(marks, mark => mark === get(input.value || {}, `${key}.end2`, '05:00 PM'))
              ].map(v => parseInt(v, 10))}
              marks={marks}
              count={3}
              pushable
              onChange={([start1, end1, start2, end2]) => {

                const value = {
                  ...input.value || {},
                  [key]: {
                    start1: marks[start1],
                    end1: marks[end1],
                    start2: marks[start2],
                    end2: marks[end2]
                  }
                };

                input.onChange(value);

                if (afterChange) {
                  afterChange(value);
                }
              }}
            />
          </div>
        </div>
      ))}
      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderRangeSliderField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  afterChange: PropTypes.func
};

renderRangeSliderField.defaultProps = {
  label: null,
  afterChange: null
};

export default renderRangeSliderField;
