import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  UPDATE_MESSAGE_START,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_ERROR
} from '../../constants/Message';

// updateMessage

export function updateMessageStart() {
  return {
    type: UPDATE_MESSAGE_START
  };
}

export function updateMessageSuccess(item = {}) {
  return {
    type: UPDATE_MESSAGE_SUCCESS,
    item
  };
}

export function updateMessageError(error) {
  return {
    type: UPDATE_MESSAGE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function updateMessage(organizationId, messageId, values) {
  return dispatch => {
    dispatch(updateMessageStart());

    return apiRequest
      .put(`organizations/${organizationId}/messages/${messageId}`, values)
      .then(({ data }) => Promise.resolve(dispatch(updateMessageSuccess(data))))
      .catch(error => Promise.reject(dispatch(updateMessageError(error))));
  };
}
