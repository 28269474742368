import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_SUBGROUP_TO_RINGGROUP_START,
  ADD_SUBGROUP_TO_RINGGROUP_SUCCESS,
  ADD_SUBGROUP_TO_RINGGROUP_ERROR
} from '../../constants/RingGroup';

// addSubGroupToRingGroup

export function addSubGroupToRingGroupStart() {
  return {
    type: ADD_SUBGROUP_TO_RINGGROUP_START
  };
}

export function addSubGroupToRingGroupSuccess(item = {}) {
  return {
    type: ADD_SUBGROUP_TO_RINGGROUP_SUCCESS,
    item
  };
}

export function addSubGroupToRingGroupError(error) {
  return {
    type: ADD_SUBGROUP_TO_RINGGROUP_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function addSubGroupToRingGroup(organizationId, ringGroupTag, subGroupTag) {
  return dispatch => {
    dispatch(addSubGroupToRingGroupStart());

    return apiRequest
      .post(`organizations/${organizationId}/ring_groups/${ringGroupTag}/subgroups/${subGroupTag}`)
      .then(({ data }) => Promise.resolve(dispatch(addSubGroupToRingGroupSuccess(data))))
      .catch(error => Promise.reject(dispatch(addSubGroupToRingGroupError(error))));
  };
}
