import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { Button, LinkTo, renderField } from '../../../helpers';

const fields = ['name', 'phone_number'];

class FavoriteForm extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }),
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
  };

  static defaultProps = {
    item: null,
    errorMessage: null
  };

  componentDidMount() {
    const { item, initialize } = this.props;

    if (item) {
      initialize(pick(get(item, 'attributes'), fields));
    }
  }

  render() {
    const { item, errorMessage, handleSubmit, onSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(values => onSubmit(values))}>
        <div className="row">
          <div className="col-12">
            <Field name="name" label="Name" component={renderField} />
            <Field
              name="phone_number"
              label="Phone Number"
              component={renderField}
            />
          </div>
        </div>

        {errorMessage ? (
          <div className="alert alert-danger">{errorMessage}</div>
        ) : null}

        <div className="form-group m-t-20">
          <div className="btn-group col-xs-12">
            <LinkTo
              button
              color="secondary"
              icon="times-circle"
              href="favorites"
            >
              Cancel
            </LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Favorite' : 'Create Favorite'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

function validate({ name, phone_number: number }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please enter a name for this favorite';
  }

  if (!number) {
    errors.number = 'Please enter a valid phone number';
  }

  return errors;
}

export default reduxForm({ form: 'favorite', validate })(FavoriteForm);
