import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import DashboardPage from './DashboardPage/DashboardPage';

export default (
  <Route exact path="/" component={RequireAuth(DashboardPage)} />
);
