import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { fetchMessages as fetchMessagesAction } from '../../../actions/MessageActions';

import { MessagesFilterForm, MessagesTable } from '../../../components';
import { ErrorMessage, LinkTo, Loading } from '../../../helpers';

import { Skeleton } from 'antd';


class MessagesIndexPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,

    organization: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    organizationFetched: PropTypes.bool.isRequired,
    organizationErrorMessage: PropTypes.string,

    messages: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired
    })).isRequired,
    messagesMeta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    messagesFetched: PropTypes.bool.isRequired,
    messagesErrorMessage: PropTypes.string,

    fetchOrganization: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired
  };

  static defaultProps = {
    organizationErrorMessage: null,
    messagesErrorMessage: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchMessages(organizationId, { page });
  }

  render() {
    const {
      match: { params: { organizationId } },
      organization,
      messages, messagesMeta, messagesFetched, messagesErrorMessage,
      fetchMessages
    } = this.props;

    const debouncedFetchMessages = debounce(values => fetchMessages(organizationId, {
      ...messagesMeta,
      ...values
    }), 250);

    return (
      <div id="messages">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Messages</h3>
          </div>
          <div className="col-md-6 col-4 align-self-center">
            <LinkTo
              button
              href={`organizations/${organizationId}/messages/new`}
              color="success"
              addClass="pull-right hidden-sm-down"
            >
              <i className="mdi mdi-plus-circle" /> Create Message
            </LinkTo>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <ErrorMessage>
                  {messagesErrorMessage}
                </ErrorMessage>

                <Skeleton active loading={!messagesFetched}>
                  <MessagesFilterForm
                    initialValues={messagesMeta}
                    agents={get(organization, 'relationships.user_roles.data', []).map(userRole => ({
                      value: userRole.id,
                      label: [
                        get(userRole, 'attributes.first_name'),
                        get(userRole, 'attributes.last_name')
                      ].join(' ')
                    }))}
                    onSubmit={values => debouncedFetchMessages(values)}
                    onClear={() => fetchMessages(organizationId, { page: 1 })}
                  />
                </Skeleton>

                <Skeleton active loading={!messagesFetched}>
                  <MessagesTable
                    organizationId={organizationId}
                    organization={organization}
                    items={messages}
                    meta={messagesMeta}
                    onPaginate={page => this.fetchData(page)}
                  />
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  organizations: {
    item: organization
  },
  messages: {
    items: messages,
    itemsMeta: messagesMeta,
    itemsFetched: messagesFetched,
    itemsErrorMessage: messagesErrorMessage
  }
}) => ({
  organization,

  messages,
  messagesMeta,
  messagesFetched,
  messagesErrorMessage
}), { fetchMessages: fetchMessagesAction })(MessagesIndexPage);
