import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { Button } from '../helpers';

class ModalButton extends Component {

  static propTypes = {
    buttonText: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    modalTitle: PropTypes.string,
    withoutFooter: PropTypes.bool,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    buttonText: null,
    icon: null,
    color: 'secondary',
    modalTitle: null,
    withoutFooter: false
  };

  state = {
    opened: false
  };

  render() {
    const { icon, color, buttonText, modalTitle, withoutFooter, children } = this.props;

    return (
      <Button icon={icon} color={color} onClick={() => this.setState({ opened: !this.state.opened })}>
        {buttonText}

        <Modal show={this.state.opened} onHide={() => this.setState({ opened: false })}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {children}
          </Modal.Body>
          {withoutFooter ? null : (
            <Modal.Footer>
              <Button onClick={() => this.setState({ opened: false })}>Close</Button>
            </Modal.Footer>
          )}
        </Modal>
      </Button>
    );
  }
}

export default ModalButton;
