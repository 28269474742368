import React from 'react';
import PropTypes from 'prop-types';

function ShowingItems({ meta }) {
  const page = parseInt(meta.page || 0, 10);
  const pageSize = parseInt(meta.pageSize || 0, 10);

  const from = (page === 1 ? 1 : ((page - 1) * pageSize));
  const to = page * pageSize;
  const of = parseInt(meta.count || 0, 10);

  return (
    <span>
      Showing {from} to {to <= of ? to : of} of {of} entries
    </span>);
}

ShowingItems.propTypes = {
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number
  }).isRequired
};

export default ShowingItems;
