import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, Form } from 'redux-form';
import size from 'lodash/size';

import { Button, renderDropdownList } from '../../../helpers';

function AttachRingGroupToUserForm({ items, errorMessage, handleSubmit, onSubmit }) {
  if (size(items) === 0) {
    return <small>User has no available groups to join</small>;
  }

  return (
    <Form onSubmit={handleSubmit(values => onSubmit(values))}>
      <div className="row">
        <div className="col-12">
          <Field name="id" label="Select Ring Group" component={renderDropdownList} data={items} />
        </div>
      </div>

      {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}

      <div className="btn-group">
        <Button submit color="success">
          Add user to ring group
        </Button>
      </div>
    </Form>
  );
}

AttachRingGroupToUserForm.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

AttachRingGroupToUserForm.defaultProps = {
  errorMessage: null
};

function validate({ user_ids: userIds }) {
  const errors = {};

  if (!userIds) {
    errors.user_ids = 'Please, select at least one member';
  }

  return errors;
}

export default reduxForm({ form: 'attach-user', validate })(AttachRingGroupToUserForm);
