import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchFavorite } from '../../../actions/FavoriteActions';

import { FavoriteInfo } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class FavoritesShowPage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchFavorite: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { match: { params: { organizationId, id } } } = this.props;

    this.props.fetchFavorite(organizationId, id);
  }

  render() {
    const { match: { params: { organizationId, id } }, item, itemFetched } = this.props;

    return (
      <div id="user-show">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">Favorite Info</h3>
          </div>
        </div>

        <CrudTabs model={`organizations/${organizationId}/favorites`} id={id}  active="show" />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <FavoriteInfo item={item} organizationId={organizationId}/>
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  favorites: {
    item,
    itemFetched
  }
}) => ({ item, itemFetched }), { fetchFavorite })(FavoritesShowPage);
