import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { renderDropdownList } from '../../../../../helpers';
import { actionTypes } from '../../utils';

function ActionTypeForm({ initialize, onActionChange }) {
  return (
    <form>
      <div className="row">
        <div className="col-2">
          New Action Type:
        </div>
        <div className="col-10">
          <Field
            name="action"
            component={renderDropdownList}
            data={actionTypes}
            afterChange={action => {
              onActionChange(action);
              initialize({ action: null });
            }}
          />
        </div>
      </div>
    </form>
  );
}

ActionTypeForm.propTypes = {
  initialize: PropTypes.func.isRequired,
  onActionChange: PropTypes.func.isRequired
};

function validate({ action }) {
  const errors = {};

  if (!action) {
    errors.action = 'Please, select an action type';
  }

  return errors;
}

export default reduxForm({ form: 'action-type', validate })(ActionTypeForm);
