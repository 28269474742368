import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_CALL_QUEUES_START,
  ADD_CALL_QUEUES_SUCCESS,
  ADD_CALL_QUEUES_ERROR
} from '../../constants/CallQueue';

// fetchCallQueues

export function addCallQueuesStart() {
  return {
    type: ADD_CALL_QUEUES_START
  };
}

export function addCallQueues(items = [], meta = {}) {
  return {
    type: ADD_CALL_QUEUES_SUCCESS,
    items,
    meta
  };
}

export function addCallQueuesError(error) {
  return {
    type: ADD_CALL_QUEUES_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchCallQueues(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addCallQueuesStart());

    return apiRequest.get(`organizations/${organizationId}/call_queues`, { page, pageSize })
      .then(({ data: { data: items } }) => Promise.resolve(dispatch(addCallQueues(items, { page, pageSize }))))
      .catch(error => Promise.reject(dispatch(addCallQueuesError(error))));
  };
}
