import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cl from 'classnames';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import last from 'lodash/last';
import size from 'lodash/size';
import { Button } from '.';


function renderFileField({
  className,
  inputClassName,
  input,
  label,
  tooltip,
  multiple,
  isLoading,
  accept,
  params,
  meta: { touched, error, warning },
  onUpload,
  onDelete
}) {
  {console.log('input', input)}
  return (
    <div
      className={cl(className || 'form-group', {
        'has-success': touched && !error && !warning,
        'has-danger': touched && error,
        'has-warning': touched && warning
      })}
    >
      {label ? (
        <label className="form-control-label" htmlFor={`form-input-${input.name}`}>
          {label} {tooltip}
        </label>
      ) : null}
      <input
        id={`form-input-${input.name}`}
        className={cl(inputClassName || 'form-control', {
          'has-feedback': touched && error,
          'form-control-success': touched && !error && !warning,
          'form-control-danger': touched && error,
          'form-control-warning': touched && warning
        })}
        {...input}
        type="hidden"
      />
      <Dropzone
        onDrop={acceptedFiles =>
          acceptedFiles.map(file => {
            const data = new FormData();

            if (isObject(params)) {
              keys(params).map(key => data.append(key, params[key]));
            }

            data.append('name', file.name);
            data.append('file', file);

            return onUpload(data).then(fileAction => {
              if (multiple) {
                return input.onChange([...(isArray(input.value) ? input.value : []), get(fileAction, 'item.key')]);
              }

              return input.onChange(get(fileAction, 'item.key'));
            });
          })
        }
        multiple={multiple}
        accept={accept}
      >
        <div>{isLoading ? 'Processing...' : 'Click here or select files to upload.'}</div>
      </Dropzone>

      {isArray(input.value) || input.value === '' ? null : (
        <ul className="list m-t-20">
          <li>{last(input.value.split('/'))} -   <Button disabled button icon="trash" color="danger" onClick={() => onDelete()}>DELETE FILE</Button></li>
        </ul>
      )}

      {isArray(input.value) && size(input.value) > 0 ? (
        <ul className="list m-t-20">{input.value.map(key => <li key={key}>{last(key.split('/'))}</li>)}</ul>
      ) : null}
      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderFileField.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  multiple: PropTypes.bool,
  isLoading: PropTypes.bool,
  accept: PropTypes.string,
  onUpload: PropTypes.func.isRequired
};

renderFileField.defaultProps = {
  className: null,
  inputClassName: null,
  label: null,
  tooltip: null,
  multiple: false,
  isLoading: false,
  accept: null
};

export default renderFileField;
