import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notification } from 'antd';
import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchUser, updateUser as updateUserAction } from '../../../actions/UserActions';
import { fetchRingGroups } from '../../../actions/RingGroupActions';

import {
  deleteRingGroupMember as deleteRingGroupMemberAction,
  createRingGroupMember as createRingGroupMemberAction
} from '../../../actions/RingGroupMemberActions';

import { UserForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class UserEditPage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    itemLoading: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    ringGroupsErrorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    fetchRingGroups: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    deleteRingGroupMember: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null,
    ringGroupsErrorMessage: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const {
      match: {
        params: { organizationId, username }
      }
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchUser(organizationId, username);
    this.props.fetchRingGroups(organizationId, { page });
  }

  render() {
    const {
      match: {
        params: { organizationId, username }
      },
      item,
      itemFetched,
      itemLoading,
      errorMessage,
      updateUser,
      deleteRingGroupMember,
      createRingGroupMember,
      ringGroupsFetched,
      ringGroupsErrorMessage,
      ringGroups,
      ringGroupsMeta
    } = this.props;
    return (
      <div className="row" id="user-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Update User</h3>
            </div>
          </div>

          <CrudTabs model={`organizations/${organizationId}/users`} id={username} active="edit" />

          <div className="card">
            <div className="card-block">
              <UserForm
                item={item}
                errorMessage={errorMessage || ringGroupsErrorMessage}
                organizationId={organizationId}
                onSubmit={async values => {
                  await updateUser(organizationId, username, values);
                  notification.success({ message: 'Success', description: 'User was updated' });
                }}
                onRemoveFromRingGroup={(ringGroupId, memberId) => deleteRingGroupMember(organizationId, ringGroupId, memberId).then(() => this.props.fetchUser(organizationId, username)
                )
                }
                onAddToRingGroup={(ringGroupId, userId) => createRingGroupMember(organizationId, ringGroupId, userId).then(() => this.props.fetchUser(organizationId, username)
                )
                }
                isLoading={itemLoading}
                ringGroups={ringGroups}
                ringGroupsFetched={ringGroupsFetched}
                ringGroupsMeta={ringGroupsMeta}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    users: { item, itemFetched, itemLoading, errorMessage },
    ringGroups: {
      items: ringGroups,
      itemsFetched: ringGroupsFetched,
      errorMessage: ringGroupsErrorMessage,
      meta: ringGroupsMeta
    }
  }) => ({
    item,
    itemFetched,
    itemLoading,
    errorMessage,
    ringGroups,
    ringGroupsFetched,
    ringGroupsErrorMessage,
    ringGroupsMeta
  }),
  {
    fetchOrganization,
    fetchUser,
    fetchRingGroups,
    updateUser: updateUserAction,
    deleteRingGroupMember: deleteRingGroupMemberAction,
    createRingGroupMember: createRingGroupMemberAction
  }
)(UserEditPage);
