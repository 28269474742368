import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_ORGANIZATION_START,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_ERROR,
} from '../../constants/Organization';

// deleteOrganization

export function deleteOrganizationStart() {
  return {
    type: DELETE_ORGANIZATION_START,
  };
}

export function deleteOrganizationSuccess(item = {}) {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    item,
  };
}

export function deleteOrganizationError(error) {
  return {
    type: DELETE_ORGANIZATION_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function deleteOrganization(organizationId) {
  return dispatch => {
    dispatch(deleteOrganizationStart());

    return apiRequest
      .delete(`organizations/${organizationId}`)
      .then(({ data }) => {
        dispatch(deleteOrganizationSuccess(data));
        dispatch(push('/organizations'));
      })
      .catch(error => dispatch(deleteOrganizationError(error)));
  };
}
