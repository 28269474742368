import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import UsersIndexPage from './UsersIndexPage/UsersIndexPage';
import UserAddPage from './UserAddPage/UserAddPage';
import UserShowPage from './UserShowPage/UserShowPage';
import UserEditPage from './UserEditPage/UserEditPage';
import UserDeletePage from './UserDeletePage/UserDeletePage';

export default [
  <Route
    exact
    key="users"
    path="/organizations/:organizationId/users"
    component={RequireAuth(UsersIndexPage)}
  />,
  <Route
    exact
    key="user-new"
    path="/organizations/:organizationId/users/new"
    component={RequireAuth(UserAddPage)}
  />,
  <Route
    exact
    key="user-show"
    path="/organizations/:organizationId/users/:username/show"
    component={RequireAuth(UserShowPage)}
  />,
  <Route
    exact
    key="user-edit"
    path="/organizations/:organizationId/users/:username/edit"
    component={RequireAuth(UserEditPage)}
  />,
  <Route
    exact
    key="user-delete"
    path="/organizations/:organizationId/users/:username/delete"
    component={RequireAuth(UserDeletePage)}
  />
];
