import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import find from 'lodash/find';
import get from 'lodash/get';
import size from 'lodash/size';

import { LinkTo, Paginate, AWSAudioPlayer } from '../../../helpers';

function MessagesTable({ organizationId, organization, items, meta, onPaginate }) {
  if (size(items) === 0) {
    return <h1>No Records</h1>;
  }

  const userRoles = get(organization, 'relationships.user_roles.data', []).map(userRole => ({
    username: get(userRole, 'attributes.username'),
    first_name: get(userRole, 'attributes.first_name'),
    last_name: get(userRole, 'attributes.last_name')
  }));

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
        <tr>
          <th className="footable-sortable">
            Name
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Description
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Type
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Owner
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Message
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item.id} className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}>
            <td>{get(item, 'attributes.name')}</td>
            <td>{get(item, 'attributes.description')}</td>
            <td>{get(item, 'attributes.type')}</td>
            <td>
              {compact([
                get(
                  find(
                    userRoles,
                    userRole =>
                      userRole.username === get(item, 'relationships.owner_user_role.data.attributes.username')
                  ),
                  'first_name'
                ),
                get(
                  find(
                    userRoles,
                    userRole =>
                      userRole.username === get(item, 'relationships.owner_user_role.data.attributes.username')
                  ),
                  'last_name'
                )
              ]).join(' ')}
            </td>
            <td>{get(item, 'attributes.message')}</td>
            <td>
              <div className="btn-group">
                {get(item, 'attributes.file_url') && get(item, 'attributes.type') === 'audio' ? (
                  <AWSAudioPlayer
                  streamUrl={get(item, 'attributes.file_url')}
                  preloadType="metadata"
                  hideTimer
                  />
                ) : null}
                <LinkTo
                  button
                  icon="search"
                  color="outline-success"
                  href={`organizations/${organizationId}/messages/${item.id}/show`}
                >
                  Show
                </LinkTo>
                <LinkTo
                  button
                  icon="pencil"
                  color="outline-info"
                  href={`organizations/${organizationId}/messages/${item.id}/edit`}
                >
                  Edit
                </LinkTo>
                <LinkTo
                  button
                  icon="trash"
                  color="outline-danger"
                  href={`organizations/${organizationId}/messages/${item.id}/delete`}
                >
                  Delete
                </LinkTo>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={5}>
            <Paginate
              count={meta.count}
              pageSize={meta.pageSize}
              activePage={meta.page}
              onSelect={page => onPaginate(page)}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

MessagesTable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number
  }).isRequired,
  onPaginate: PropTypes.func.isRequired
};

export default MessagesTable;
