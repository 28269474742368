import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { SHOW_RING_GROUP_START, SHOW_RING_GROUP_SUCCESS, SHOW_RING_GROUP_ERROR } from '../../constants/RingGroup';

// fetchRingGroup

export function showRingGroupStart() {
  return {
    type: SHOW_RING_GROUP_START
  };
}

export function showRingGroup(item = {}) {
  return {
    type: SHOW_RING_GROUP_SUCCESS,
    item
  };
}

export function showRingGroupError(error) {
  return {
    type: SHOW_RING_GROUP_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchRingGroup(organizationId, ringGroupTag, options = {}) {
  return dispatch => {
    dispatch(showRingGroupStart());

    return apiRequest
      .get(`organizations/${organizationId}/ring_groups/${ringGroupTag}`, options)
      .then(({ data: { data: item } }) => Promise.resolve(dispatch(showRingGroup(item))))
      .catch(error => Promise.reject(dispatch(showRingGroupError(error))));
  };
}
