import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchFavorite, updateFavorite as updateFavoriteAction } from '../../../actions/FavoriteActions';

import { FavoriteForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class FavoriteEditPage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    fetchFavorite: PropTypes.func.isRequired,
    updateFavorite: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null
  };

  componentDidMount() {
    const { match: { params: { organizationId, id } } } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchFavorite(organizationId, id);
  }

  render() {
    const { match: { params: { organizationId, id } }, item, itemFetched, errorMessage, updateFavorite } = this.props;

    return (
      <div className="row" id="user-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Update Favorite</h3>
            </div>
          </div>

          <CrudTabs model={`organizations/${organizationId}/favorites`} id={id} active="edit" />

          <div className="card">
            <div className="card-block">
              <Loading loaded={itemFetched}>
                <FavoriteForm
                  item={item}
                  errorMessage={errorMessage}
                  organizationId={organizationId}
                  onSubmit={values => updateFavorite(organizationId, id, values)}
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  favorites: {
    item,
    itemFetched,
    errorMessage
  }
}) => ({
  item,
  itemFetched,
  errorMessage
}), { fetchOrganization, fetchFavorite, updateFavorite: updateFavoriteAction })(FavoriteEditPage);
