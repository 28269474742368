import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchMessage,
  deleteMessage as deleteMessageAction,
} from '../../../actions/MessageActions';

import { MessageDelete } from '../../../components';
import { CrudTabs, ErrorMessage, Loading } from '../../../helpers';

class MessageDeletePage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    organizationFetched: PropTypes.bool.isRequired,
    organizationErrorMessage: PropTypes.string,

    message: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    messageFetched: PropTypes.bool.isRequired,
    messageLoading: PropTypes.bool.isRequired,
    messageErrorMessage: PropTypes.string,

    fetchOrganization: PropTypes.func.isRequired,
    fetchMessage: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    organizationErrorMessage: null,
    messageErrorMessage: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId, messageId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchMessage(organizationId, messageId);
  }

  render() {
    const {
      match: {
        params: { organizationId, messageId },
      },
      organizationFetched,
      organizationErrorMessage,
      message,
      messageFetched,
      messageLoading,
      messageErrorMessage,
      deleteMessage,
      push,
    } = this.props;

    return (
      <div className="row" id="message-delete">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Delete Message #{messageId}</h3>
            </div>
          </div>

          <CrudTabs
            model={`organizations/${organizationId}/messages`}
            id={messageId}
            active="delete"
          />

          <div className="card">
            <div className="card-block">
              <ErrorMessage>{organizationErrorMessage}</ErrorMessage>
              <Loading loaded={organizationFetched && messageFetched}>
                <MessageDelete
                  item={message}
                  organizationId={organizationId}
                  isLoading={messageLoading}
                  errorMessage={messageErrorMessage}
                  onDelete={() =>
                    deleteMessage(organizationId, messageId).then(() =>
                      push(`/organizations/${organizationId}/messages`)
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    organizations: {
      itemFetched: organizationFetched,
      itemErrorMessage: organizationErrorMessage,
    },
    messages: {
      item: message,
      itemFetched: messageFetched,
      itemLoading: messageLoading,
      itemErrorMessage: messageErrorMessage,
    },
  }) => ({
    organizationFetched,
    organizationErrorMessage,

    message,
    messageFetched,
    messageLoading,
    messageErrorMessage,
  }),
  {
    fetchOrganization,
    fetchMessage,
    deleteMessage: deleteMessageAction,
    push: pushAction,
  }
)(MessageDeletePage);
