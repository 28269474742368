import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';
import { push as pushAction } from 'connected-react-router';

import { LinkTo, Button, Paginate } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';

import { fetchAgentSession } from '../../../actions/AgentSessionActions';

class OrganizationsTable extends Component {
  handleLogin(subdomain) {
    const { currentAgentOrg, push } = this.props;
    if (currentAgentOrg !== subdomain) {
      return this.props
        .fetchAgentSession(subdomain)
        .then(() => push(`/organizations/${subdomain}/edit`));
    }
    return push(`/organizations/${subdomain}/edit`);
  }

  render() {
    const { items, meta, onPaginate } = this.props;

    if (size(items) === 0) {
      return <h1>No Records</h1>;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              {items.map(item => (
                <div key={item.id} className="card">
                  <div className="card-block">
                    <div className="row">
                      <div className="col-9">
                        <div className="organization-name">
                          <LinkTo
                            href={`organizations/${get(
                              item,
                              'attributes.subdomain'
                            )}/show`}
                          >
                            <h3>{get(item, 'attributes.name')}</h3>
                          </LinkTo>
                        </div>
                        <div className="organization-attributes">
                          {[
                            get(item, 'attributes.subdomain'),
                            `Last Modified: ${renderHumanDateTime(
                              get(item, 'attributes.updated_at')
                            )}`,
                          ].join(', ')}
                        </div>
                      </div>
                      <div className="col-3 text-right">
                        <Button
                          color="outline-primary"
                          icon="sign-in"
                          size="lg"
                          onClick={() =>
                            this.handleLogin(get(item, 'attributes.subdomain'))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Paginate
                count={meta.count}
                pageSize={meta.pageSize}
                activePage={meta.page}
                onSelect={page => onPaginate(page)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
OrganizationsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number,
  }).isRequired,
  onPaginate: PropTypes.func.isRequired,
};

export default connect(null, { fetchAgentSession, push: pushAction })(
  OrganizationsTable
);
