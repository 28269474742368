import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchUser,
  deleteUser as deleteUserAction,
} from '../../../actions/UserActions';

import { UserDelete } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class UserDeletePage extends Component {
  static propTypes = {
    fetchOrganization: PropTypes.func.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      match: {
        params: { organizationId, username },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchUser(organizationId, username);
  }

  render() {
    const {
      match: {
        params: { organizationId, username },
      },
      item,
      itemFetched,
      deleteUser,
      push,
    } = this.props;

    return (
      <div id="user-delete">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">Delete User</h3>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/users`}
          id={username}
          active="delete"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <UserDelete
                    organizationId={organizationId}
                    item={item}
                    onDelete={username =>
                      deleteUser(organizationId, username).then(() =>
                        push(`/organizations/${organizationId}/users`)
                      )
                    }
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ users: { item, itemFetched } }) => ({ item, itemFetched }),
  {
    fetchOrganization,
    fetchUser,
    deleteUser: deleteUserAction,
    push: pushAction,
  }
)(UserDeletePage);
