import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import get from 'lodash/get';

import { LinkTo } from '../../../helpers';

function MessageInfo({ item }) {
  return (
    <table className="table table-hover footable-loaded footable">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'attributes.name')}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{get(item, 'attributes.description')}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{get(item, 'attributes.type')}</td>
        </tr>
        <tr>
          <td>Owner</td>
          <td>
            {compact([
              get(item, 'relationships.owner_user_role.data.attributes.first_name'),
              get(item, 'relationships.owner_user_role.data.attributes.last_name')
            ]).join(' ')}
          </td>
        </tr>
        <tr>
          <td>Message</td>
          <td>{get(item, 'attributes.message')}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div className="btn-group">
              {get(item, 'attributes.file_url') ? (
                <LinkTo
                  button
                  color="outline-success"
                  icon="play"
                  href={get(item, 'attributes.file_url')}
                  target="_blank"
                  pureLink
                >
                  Play Recording
                </LinkTo>
              ) : null}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

MessageInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default MessageInfo;
