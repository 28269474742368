import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import CallFlowsIndexPage from './CallFlowsIndexPage/CallFlowsIndexPage';
import CallFlowAddPage from './CallFlowAddPage/CallFlowAddPage';
import CallFlowShowPage from './CallFlowShowPage/CallFlowShowPage';
import CallFlowEditPage from './CallFlowEditPage/CallFlowEditPage';
import CallFlowDeletePage from './CallFlowDeletePage/CallFlowDeletePage';
import CallFlowEntriesPage from './CallFlowEntriesPage/CallFlowEntriesPage';

export default [
  <Route
    exact
    key="organization-call-flows"
    path="/organizations/:organizationId/callFlows"
    component={RequireAuth(CallFlowsIndexPage)}
  />,
  <Route
    exact
    key="organization-call-flow-new"
    path="/organizations/:organizationId/callFlows/new"
    component={RequireAuth(CallFlowAddPage)}
  />,
  <Route
    exact
    key="organization-call-flow-show"
    path="/organizations/:organizationId/callFlows/:callFlowId/show"
    component={RequireAuth(CallFlowShowPage)}
  />,
  <Route
    exact
    key="organization-call-flow-edit"
    path="/organizations/:organizationId/callFlows/:callFlowId/edit"
    component={RequireAuth(CallFlowEditPage)}
  />,
  <Route
    exact
    key="organization-call-flow-delete"
    path="/organizations/:organizationId/callFlows/:callFlowId/delete"
    component={RequireAuth(CallFlowDeletePage)}
  />,
  <Route
    exact
    key="organization-call-flow-entries"
    path="/organizations/:organizationId/callFlows/:callFlowId/entries"
    component={RequireAuth(CallFlowEntriesPage)}
  />
];
