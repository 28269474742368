import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  FETCH_AGENT_SESSION_START,
  FETCH_AGENT_SESSION_SUCCESS,
  FETCH_AGENT_SESSION_ERROR
} from '../../constants/AgentSession';

// fetchAgent

function fetchAgentSessionStart() {
  return {
    type: FETCH_AGENT_SESSION_START
  };
}

function fetchAgentSessionSuccess(session) {
  return {
    type: FETCH_AGENT_SESSION_SUCCESS,
    session
  };
}

function fetchAgentSessionError(error) {
  console.log('error', error);
  return {
    type: FETCH_AGENT_SESSION_ERROR,
    sessionErrorMessage: get(error, 'response.data.message', 'Server-side Error')
  };
}

export default function fetchAgentSession(organizationId) {
  return dispatch => {
    dispatch(fetchAgentSessionStart());

    return apiRequest
      .get(
        `organizations/${organizationId}/agent`,
        null,
        `?endpoint=${navigator.userAgent.toLowerCase() + Math.floor(Math.random() * 1000 + 1)}`
      )
      .then(({ data }) => dispatch(fetchAgentSessionSuccess(data)))
      .catch(error => dispatch(fetchAgentSessionError(error)));
  };
}
