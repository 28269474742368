import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import pick from 'lodash/pick';
import size from 'lodash/size';
import merge from 'lodash/merge';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { Button } from 'antd';
import { LinkTo, renderField, renderDropdownList, Loading } from '../../../helpers';
import { AttachRingGroupToUserForm } from '../..';

const fields = ['username', 'email', 'first_name', 'last_name', 'role', 'extension'];

class UserForm extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number
    }),
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    item: null,
    errorMessage: null,
    isNew: false
  };

  componentDidUpdate(prevProps, prevState) {
    const { item, initialize } = this.props;

    if (prevProps.item !== this.props.item) {
      initialize(
        merge(pick(get(item, 'attributes'), fields), {
          username: get(item, 'relationships.user_role.data.attributes.username'),
          role: get(item, 'relationships.user_role.data.attributes.role'),
          extension: get(item, 'relationships.user_role.data.attributes.extension')
        })
      );
    }
  }

  render() {
    const {
      organizationId,
      item,
      errorMessage,
      handleSubmit,
      onSubmit,
      isNew,
      onRemoveFromRingGroup,
      onAddToRingGroup,
      ringGroups,
      ringGroupsFetched,
      ringGroupsErrorMessage,
      ringGroupsMeta,
      visible,
      onCancel,
      onCreate,
      isLoading
    } = this.props;

    const userRingGroupTags = get(item, 'relationships.ring_groups.data', []).map(r => r.id);

    return (
      <div>
        <form onSubmit={handleSubmit(values => onSubmit(values))}>
          <Field name="first_name" label="First Name" component={renderField} />
          <Field name="last_name" label="Last Name" component={renderField} />
          <Field name="email" label="Email" component={renderField} disabled={!isNew} />
          <Field name="username" label="Username" component={renderField} disabled={!isNew} />
          <Field
            name="role"
            label="Role"
            component={renderDropdownList}
            data={[
              {
                label: 'agent',
                value: 'agent'
              },
              {
                label: 'manager',
                value: 'manager'
              },
              {
                label: 'admin',
                value: 'admin'
              },
              {
                label: 'owner',
                value: 'owner'
              }
            ]}
          />
          <Field name="extension" label="Extension" component={renderField} />

          {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}
          <div className="form-group m-t-20">
            <div className="col-xs-12">
              <Button type="primary" size="large" htmlType="submit" disabled={isLoading}>
                {isLoading ? 'Processing...' : 'Save'}
              </Button>
            </div>
          </div>
        </form>
        <div className="col-sm-6">
          {size(get(item, 'relationships.ring_groups.data', [])) > 0 ? (
            <h2>Belongs to the following Ring Groups:</h2>
          ) : null}

          {size(get(item, 'relationships.ring_groups.data', [])) > 0 ? (
            <ul className="list" style={{ marginBottom: '30px' }}>
              {get(item, 'relationships.ring_groups.data', []).map(ringGroup => (
                <li key={ringGroup.id}>
                  <LinkTo href={['organizations', organizationId, 'ringGroups', ringGroup.id, 'show'].join('/')}>
                    {get(ringGroup, 'attributes.name')}
                  </LinkTo>
                  &nbsp;
                  <Button
                    color="danger"
                    icon="trash"
                    size="small"
                    onClick={() => onRemoveFromRingGroup(ringGroup.id, get(item, 'relationships.user_role.data.attributes.username'))
                    }
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          {isNew ? null : (
            <div>
              <h3>Add user to a Ring Group</h3>
              <AttachRingGroupToUserForm
                organizationId={organizationId}
                items={ringGroups.filter(({ id }) => !includes(userRingGroupTags, id)).map(filtered => ({
                  label: `${get(filtered, 'attributes.name')}`,
                  value: filtered.id
                }))}
                onSubmit={({ id: ringGroupId }) => onAddToRingGroup(ringGroupId, get(item, 'relationships.user_role.data.attributes.username'))
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function validate({ first_name: firstName, last_name: lastName, username }) {
  const errors = {};

  if (!firstName && !lastName) {
    errors.name = 'Please enter a name';
  }

  if (!username) {
    errors.username = 'Please enter a username';
  }

  return errors;
}

export default reduxForm({
  form: 'user',
  validate
})(UserForm);
