import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { createUser as createUserAction } from '../../../actions/UserActions';

import { UserForm } from '../../../components';

class UserAddPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    errorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      errorMessage,
      createUser,
      push,
    } = this.props;

    return (
      <div className="row" id="user-add">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Create User</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <UserForm
                isNew
                organizationId={organizationId}
                errorMessage={errorMessage}
                onSubmit={values =>
                  createUser(organizationId, values).then(() =>
                    push(`/organizations/${organizationId}/users`)
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ users: { errorMessage } }) => ({ errorMessage }), {
  fetchOrganization,
  createUser: createUserAction,
  push: pushAction,
})(UserAddPage);
