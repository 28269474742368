import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_SUBGROUP_FROM_RINGGROUP_START,
  DELETE_SUBGROUP_FROM_RINGGROUP_SUCCESS,
  DELETE_SUBGROUP_FROM_RINGGROUP_ERROR
} from '../../constants/RingGroup';

// deleteSubGroupFromRingGroup

export function deleteSubGroupFromRingGroupStart() {
  return {
    type: DELETE_SUBGROUP_FROM_RINGGROUP_START
  };
}

export function deleteSubGroupFromRingGroupSuccess(item = {}) {
  return {
    type: DELETE_SUBGROUP_FROM_RINGGROUP_SUCCESS,
    item
  };
}

export function deleteSubGroupFromRingGroupError(error) {
  return {
    type: DELETE_SUBGROUP_FROM_RINGGROUP_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteSubGroupFromRingGroup(organizationId, ringGroupTag, subGroupTag) {
  return dispatch => {
    dispatch(deleteSubGroupFromRingGroupStart());

    return apiRequest
      .deleteMany(`organizations/${organizationId}/ring_groups/${ringGroupTag}/subgroups/${subGroupTag}`)
      .then(({ data }) => Promise.resolve(dispatch(deleteSubGroupFromRingGroupSuccess(data))))
      .catch(error => Promise.reject(dispatch(deleteSubGroupFromRingGroupError(error))));
  };
}
