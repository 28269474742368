import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { LinkTo, Paginate } from '../../../helpers';

function RingGroupsTable({ organizationId, items, meta, onPaginate }) {
  if (size(items) === 0) {
    return <h1>No Records</h1>;
  }

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
        <tr>
          <th className="footable-sortable">
            Name
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Tag
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Description
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item.id} className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}>
            <td>
              <LinkTo href={`organizations/${organizationId}/ringGroups/${item.id}/show`}>
                {get(item, 'attributes.name')}
              </LinkTo>
            </td>
            <td>{get(item, 'attributes.tag')}</td>
            <td>{get(item, 'attributes.description')}</td>
            <td>
              <div className="btn-group">
                <LinkTo
                  button
                  color="outline-success"
                  icon="search"
                  href={`organizations/${organizationId}/ringGroups/${item.id}/show`}
                >
                  Show
                </LinkTo>
                <LinkTo
                  button
                  color="outline-info"
                  icon="pencil"
                  href={`organizations/${organizationId}/ringGroups/${item.id}/edit`}
                >
                  Edit
                </LinkTo>
                <LinkTo
                  button
                  color="outline-danger"
                  icon="trash"
                  href={`organizations/${organizationId}/ringGroups/${item.id}/delete`}
                >
                  Delete
                </LinkTo>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>
            <Paginate
              count={meta.count}
              pageSize={meta.pageSize}
              activePage={meta.page}
              onSelect={page => onPaginate(page)}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

RingGroupsTable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number
  }).isRequired,
  onPaginate: PropTypes.func.isRequired
};

export default RingGroupsTable;
