export const ADD_CALL_FLOWS_START = 'ADD_CALL_FLOWS_START';
export const ADD_CALL_FLOWS_SUCCESS = 'ADD_CALL_FLOWS_SUCCESS';
export const ADD_CALL_FLOWS_ERROR = 'ADD_CALL_FLOWS_ERROR';

export const SHOW_CALL_FLOW_START = 'SHOW_CALL_FLOW_START';
export const SHOW_CALL_FLOW_SUCCESS = 'SHOW_CALL_FLOW_SUCCESS';
export const SHOW_CALL_FLOW_ERROR = 'SHOW_CALL_FLOW_ERROR';

export const CREATE_CALL_FLOW_START = 'CREATE_CALL_FLOW_START';
export const CREATE_CALL_FLOW_SUCCESS = 'CREATE_CALL_FLOW_SUCCESS';
export const CREATE_CALL_FLOW_ERROR = 'CREATE_CALL_FLOW_ERROR';

export const UPDATE_CALL_FLOW_START = 'UPDATE_CALL_FLOW_START';
export const UPDATE_CALL_FLOW_SUCCESS = 'UPDATE_CALL_FLOW_SUCCESS';
export const UPDATE_CALL_FLOW_ERROR = 'UPDATE_CALL_FLOW_ERROR';

export const DELETE_CALL_FLOW_START = 'DELETE_CALL_FLOW_START';
export const DELETE_CALL_FLOW_SUCCESS = 'DELETE_CALL_FLOW_SUCCESS';
export const DELETE_CALL_FLOW_ERROR = 'DELETE_CALL_FLOW_ERROR';
