export const ADD_BLACKLISTS_START = 'ADD_BLACKLISTS_START';
export const ADD_BLACKLISTS_SUCCESS = 'ADD_BLACKLISTS_SUCCESS';
export const ADD_BLACKLISTS_ERROR = 'ADD_BLACKLISTS_ERROR';

export const SHOW_BLACKLIST_START = 'SHOW_BLACKLIST_START';
export const SHOW_BLACKLIST_SUCCESS = 'SHOW_BLACKLIST_SUCCESS';
export const SHOW_BLACKLIST_ERROR = 'SHOW_BLACKLIST_ERROR';

export const CREATE_BLACKLIST_START = 'CREATE_BLACKLIST_START';
export const CREATE_BLACKLIST_SUCCESS = 'CREATE_BLACKLIST_SUCCESS';
export const CREATE_BLACKLIST_ERROR = 'CREATE_BLACKLIST_ERROR';

export const UPDATE_BLACKLIST_START = 'UPDATE_BLACKLIST_START';
export const UPDATE_BLACKLIST_SUCCESS = 'UPDATE_BLACKLIST_SUCCESS';
export const UPDATE_BLACKLIST_ERROR = 'UPDATE_BLACKLIST_ERROR';

export const DELETE_BLACKLIST_START = 'DELETE_BLACKLIST_START';
export const DELETE_BLACKLIST_SUCCESS = 'DELETE_BLACKLIST_SUCCESS';
export const DELETE_BLACKLIST_ERROR = 'DELETE_BLACKLIST_ERROR';