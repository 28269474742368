import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_CALL_QUEUES_START,
  ADD_CALL_QUEUES_SUCCESS,
  ADD_CALL_QUEUES_ERROR,
  SHOW_CALL_QUEUE_START,
  SHOW_CALL_QUEUE_SUCCESS,
  SHOW_CALL_QUEUE_ERROR,
  CREATE_CALL_QUEUE_START,
  CREATE_CALL_QUEUE_SUCCESS,
  CREATE_CALL_QUEUE_ERROR,
  UPDATE_CALL_QUEUE_START,
  UPDATE_CALL_QUEUE_SUCCESS,
  UPDATE_CALL_QUEUE_ERROR,
  UPDATE_CALL_QUEUE_ENTRIES_START,
  UPDATE_CALL_QUEUE_ENTRIES_SUCCESS,
  UPDATE_CALL_QUEUE_ENTRIES_ERROR,
} from '../constants/CallQueue';

const defaultState = {
  path: null,

  items: [],
  itemsFetched: false,
  itemsLoading: false,
  itemsErrorMessage: null,
  itemsMeta: { page: 1, pageSize: 10 },

  item: {},
  itemLoading: false,
  itemFetched: false,
  itemErrorMessage: null,

  itemEntriesLoading: false,
  itemEntriesFetched: false,
  itemEntriesErrorMessage: null,
};

export default function CallQueueReducer(
  state = defaultState,
  { type, payload, items, meta, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case ADD_CALL_QUEUES_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case ADD_CALL_QUEUES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsErrorMessage: null,
        itemsFetched: true,
        items,
        itemsMeta: meta,
      };

    case ADD_CALL_QUEUES_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: false,
        itemsErrorMessage: errorMessage,
      };

    case SHOW_CALL_QUEUE_START:
      return {
        ...state,
        itemLoading: true,
        itemFetched: false,
      };

    case SHOW_CALL_QUEUE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        itemErrorMessage: null,
        item,
      };

    case SHOW_CALL_QUEUE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemFetched: false,
        itemErrorMessage: errorMessage,
      };

    case CREATE_CALL_QUEUE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case CREATE_CALL_QUEUE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case CREATE_CALL_QUEUE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    case UPDATE_CALL_QUEUE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case UPDATE_CALL_QUEUE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case UPDATE_CALL_QUEUE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    case UPDATE_CALL_QUEUE_ENTRIES_START:
      return {
        ...state,
        itemEntriesLoading: true,
      };

    case UPDATE_CALL_QUEUE_ENTRIES_SUCCESS:
      return {
        ...state,
        itemEntriesLoading: false,
        itemEntriesErrorMessage: null,
      };

    case UPDATE_CALL_QUEUE_ENTRIES_ERROR:
      return {
        ...state,
        itemEntriesLoading: false,
        itemEntriesErrorMessage: errorMessage,
      };

    default:
      return state;
  }
}
