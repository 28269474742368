import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cl from 'classnames';

function renderDropdownList({
  addClass, input, label, data, creatable, clearable, disabled, newOptionCreator, onNewOptionClick, promptTextCreator,
  meta: { touched, error, warning }, afterChange
}) {
  return (
    <div
      className={cl('form-group', addClass, {
        'has-success': touched && !error && !warning,
        'has-danger': touched && error,
        'has-warning': touched && warning
      })}
    >
      {label ? <label className="form-control-label" htmlFor={`form-input-${input.name}`}>{label}</label> : null}
      {creatable ? (
        <Select.Creatable
          id={`form-input-${input.name}`}
          name={input.name}
          value={input.value}
          className={cl({
            'has-feedback': touched && error,
            'form-control-success': touched && !error && !warning,
            'form-control-danger': touched && error,
            'form-control-warning': touched && warning
          })}
          clearable={clearable}
          newOptionCreator={newOptionCreator}
          onNewOptionClick={onNewOptionClick}
          promptTextCreator={promptTextCreator}
          options={data}
          onChange={i => {
            input.onChange(i && i.value);

            if (afterChange) {
              afterChange(i && i.value);
            }
          }}
        />
      ) : (
        <Select
          id={`form-input-${input.name}`}
          name={input.name}
          value={input.value}
          className={cl({
            'has-feedback': touched && error,
            'form-control-success': touched && !error && !warning,
            'form-control-danger': touched && error,
            'form-control-warning': touched && warning
          })}
          clearable={clearable}
          disabled={disabled}
          options={data}
          onChange={i => {
            input.onChange(i && i.value);

            if (afterChange) {
              afterChange(i && i.value);
            }
          }}
        />
      )}
      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderDropdownList.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  creatable: PropTypes.bool,
  clearable: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  afterChange: PropTypes.func
};

renderDropdownList.defaultProps = {
  creatable: false,
  clearable: false,
  label: null,
  afterChange: null
};

export default renderDropdownList;
