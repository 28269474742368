export const ADD_WORKFLOWS_START = 'ADD_WORKFLOWS_START';
export const ADD_WORKFLOWS_SUCCESS = 'ADD_WORKFLOWS_SUCCESS';
export const ADD_WORKFLOWS_ERROR = 'ADD_WORKFLOWS_ERROR';

export const SHOW_WORKFLOW_START = 'SHOW_WORKFLOW_START';
export const SHOW_WORKFLOW_SUCCESS = 'SHOW_WORKFLOW_SUCCESS';
export const SHOW_WORKFLOW_ERROR = 'SHOW_WORKFLOW_ERROR';

export const CREATE_WORKFLOW_START = 'CREATE_WORKFLOW_START';
export const CREATE_WORKFLOW_SUCCESS = 'CREATE_WORKFLOW_SUCCESS';
export const CREATE_WORKFLOW_ERROR = 'CREATE_WORKFLOW_ERROR';

export const UPDATE_WORKFLOW_START = 'UPDATE_WORKFLOW_START';
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS';
export const UPDATE_WORKFLOW_ERROR = 'UPDATE_WORKFLOW_ERROR';

export const DELETE_WORKFLOW_START = 'DELETE_WORKFLOW_START';
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_ERROR = 'DELETE_WORKFLOW_ERROR';
