import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchCallQueue,
  deleteCallQueue as deleteCallQueueAction,
} from '../../../actions/CallQueueActions';

import { CallQueueDelete } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class CallQueueDeletePage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        callQueueId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchCallQueue: PropTypes.func.isRequired,
    deleteCallQueue: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      match: {
        params: { organizationId, callQueueId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchCallQueue(callQueueId);
  }

  render() {
    const {
      match: {
        params: { organizationId, callQueueId },
      },
      item,
      itemFetched,
      deleteCallQueue,
      push,
    } = this.props;

    return (
      <div id="call-queue-delete">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">
              {isEmpty(item)
                ? 'Call Queue'
                : `Call Queue: ${item.id} | ${get(item, 'attributes.name')}`}
            </h3>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/callQueues`}
          id={callQueueId}
          active="delete"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <CallQueueDelete
                    organizationId={organizationId}
                    item={item}
                    onDelete={({ id }) =>
                      deleteCallQueue(id).then(() =>
                        push(`/organizations/${organizationId}/callQueues`)
                      )
                    }
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ callQueues: { item, itemFetched } }) => ({ item, itemFetched }),
  {
    fetchOrganization,
    fetchCallQueue,
    deleteCallQueue: deleteCallQueueAction,
    push: pushAction,
  }
)(CallQueueDeletePage);
