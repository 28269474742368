import { apiRequest } from '../../utils';

import { MONITOR_CALL_START, MONITOR_CALL_SUCCESS, MONITOR_CALL_ERROR } from '../../constants/Call';

function monitorCallStart() {
  return {
    type: MONITOR_CALL_START
  };
}

function monitorCallSuccess(data) {
  return {
    type: MONITOR_CALL_SUCCESS,
    data
  };
}

function monitorCallError(errorMessage) {
  console.log('monitorCallError', errorMessage);
  return {
    type: MONITOR_CALL_ERROR,
    errorMessage
  };
}

export default function monitorCall(organizationId, id) {
  return dispatch => {
    dispatch(monitorCallStart());

    return apiRequest
      .put(`organizations/${organizationId}/agent/calls/${id}`, null, {
        data: {
          attributes: {
            mode: 'mute'
          }
        }
      })
      .then(({ data: { data } }) => dispatch(monitorCallSuccess(data)))
      .catch(err => dispatch(monitorCallError(err)));
  };
}
