import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updatePassword as updatePasswordAction } from '../../../actions/AuthActions';

import { UpdatePasswordForm } from '../../../components';
import { LinkTo } from '../../../helpers';

function UpdatePasswordPage({
  match: { params: { code } },
  isLoading, successMessage, errorMessage,
  updatePassword
}) {
  return (
    <div className="login-box card">
      <div className="card-block">

        <h2 className="text-center db">
          <i className="fa fa-envelope" /> ContactFrame Admin
        </h2>

        <h3 className="box-title m-b-20">Update Password</h3>
        <p className="text-muted">Enter your new password!</p>

        <UpdatePasswordForm
          isLoading={isLoading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          onSubmit={user => updatePassword(code, user)}
        />

        <div className="form-group m-b-0">
          <div className="col-sm-12 text-center">
            <p>Don{"'"}t have an account? <LinkTo href="auth/signup" className="m-l-5"><b>Sign Up</b></LinkTo></p>
          </div>
        </div>
      </div>
    </div>
  );
}

UpdatePasswordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  updatePassword: PropTypes.func.isRequired
};

UpdatePasswordPage.defaultProps = {
  successMessage: null,
  errorMessage: null
};

export default connect(({
  auth: {
    isLoading,
    successMessage,
    errorMessage
  }
}) => ({
  isLoading,
  successMessage,
  errorMessage
}), { updatePassword: updatePasswordAction })(UpdatePasswordPage);
