import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

function Paginate({ count, pageSize, activePage, onSelect }) {
  const items = count && pageSize ? Math.ceil(count / pageSize) : null;
  
  if (items > 1) {
    return (
      <Pagination
        first={activePage > 1}
        prev={activePage > 1}
        next={activePage < items}
        last={activePage < items}
        ellipsis
        boundaryLinks
        maxButtons={5}
        items={items}
        activePage={activePage}
        onSelect={onSelect}
      />
    );
  }

  return null;
}

Paginate.propTypes = {
  count: PropTypes.number,
  pageSize: PropTypes.number,
  activePage: PropTypes.number,
  onSelect: PropTypes.func.isRequired
};

Paginate.defaultProps = {
  count: null,
  pageSize: null,
  activePage: null
};

export default Paginate;
