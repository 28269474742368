import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from '../../constants/User';

// deleteUser

export function deleteUserStart() {
  return {
    type: DELETE_USER_START,
  };
}

export function deleteUserSuccess(item = {}) {
  return {
    type: DELETE_USER_SUCCESS,
    item,
  };
}

export function deleteUserError(error) {
  return {
    type: DELETE_USER_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function deleteUser(organizationId, username) {
  return dispatch => {
    dispatch(deleteUserStart());

    return apiRequest
      .delete(`organizations/${organizationId}/users/${username}`)
      .then(({ data }) => {
        dispatch(deleteUserSuccess(data));
      })
      .catch(error => dispatch(deleteUserError(error)));
  };
}
