import Promise from 'bluebird';
import findIndex from 'lodash/findIndex';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';

import { apiRequest } from '../../utils';

import {
  UPDATE_CALL_FLOW_ENTRIES_START,
  UPDATE_CALL_FLOW_ENTRIES_SUCCESS,
  UPDATE_CALL_FLOW_ENTRIES_ERROR
} from '../../constants/CallFlowEntry';

// updateCallFlowEntries

export function updateCallFlowEntriesStart() {
  return {
    type: UPDATE_CALL_FLOW_ENTRIES_START
  };
}

export function updateCallFlowEntriesSuccess() {
  return {
    type: UPDATE_CALL_FLOW_ENTRIES_SUCCESS,
    successMessage: 'Updated entries'
  };
}

export function updateCallFlowEntriesError(error) {
  return {
    type: UPDATE_CALL_FLOW_ENTRIES_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function updateCallFlowEntries(callFlowId, values) {
  return dispatch => {
    dispatch(updateCallFlowEntriesStart());

    const entries = keys(values).map(key => values[key]);

    const data = entries.map(({ name, type, message, message_id: messageId, prompt, call_queue_id: callQueueId, timeout, next }) => ({
      attributes: pickBy({
        name,
        type,
        message,
        message_id: messageId,
        timeout,
        prompt: prompt && fromPairs(prompt.numbers.map((number, index) => ([
          `${prompt[`number_${index}`]}:${prompt[`key_${index}`]}`,
          findIndex(entries, entry => entry.id === prompt[`value_${index}`])
        ]))),
        next: next && findIndex(entries, entry => entry.id === next),
        call_queue_id: callQueueId
      }, value => !isNil(value))
    }));

    return apiRequest.put(`call_flows/${callFlowId}/entries`, null, { data })
      .then(() => Promise.resolve(dispatch(updateCallFlowEntriesSuccess())))
      .catch(error => Promise.reject(dispatch(updateCallFlowEntriesError(error))));
  };
}
