import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  fetchCallFlow,
  deleteCallFlow as deleteCallFlowAction,
} from '../../../actions/CallFlowActions';

import { CallFlowDelete } from '../../../components';
import { CrudTabs, LinkTo, Loading } from '../../../helpers';

class CallFlowDeletePage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        callFlowId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchCallFlow: PropTypes.func.isRequired,
    deleteCallFlow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      match: {
        params: { callFlowId },
      },
    } = this.props;

    this.props.fetchCallFlow(callFlowId);
  }

  render() {
    const {
      match: {
        params: { organizationId, callFlowId },
      },
      item,
      itemFetched,
      deleteCallFlow,
      push,
    } = this.props;

    return (
      <div id="call-flow-delete">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">
              {isEmpty(item)
                ? 'Call Flow'
                : `Call Flow: ${item.id} | ${get(item, 'attributes.name')}`}
            </h3>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/callFlows`}
          id={callFlowId}
          active="delete"
          afterTabs={[
            <LinkTo
              icon="list"
              className="nav-link"
              href={`organizations/${organizationId}/callFlows/${item.id}/entries`}
            >
              Call Flow Entries
            </LinkTo>,
          ]}
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <CallFlowDelete
                    organizationId={organizationId}
                    item={item}
                    onDelete={({ id }) =>
                      deleteCallFlow(id).then(() =>
                        push(`/organizations/${organizationId}/callFlows`)
                      )
                    }
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ callFlows: { item, itemFetched } }) => ({ item, itemFetched }),
  {
    fetchCallFlow,
    deleteCallFlow: deleteCallFlowAction,
    push: pushAction,
  }
)(CallFlowDeletePage);
