import get from 'lodash/get';
import moment from 'moment';

import { apiRequest, formatPhone } from '../../utils';

import {
  FETCH_CALLS_START,
  FETCH_CALLS_SUCCESS,
  FETCH_CALLS_ERROR
} from '../../constants/Call';

function fetchUserCallsStart() {
  return {
    type: FETCH_CALLS_START
  };
}

function fetchUserCallsSuccess(items = [], meta = {}) {
  return {
    type: FETCH_CALLS_SUCCESS,
    items,
    meta
  };
}

function fetchUserCallsError(error) {
  return {
    type: FETCH_CALLS_ERROR,
    ErrorMessage: get(error, 'response.data.message', 'Server-side Error')
  };
}

export default function fetchUserCalls(organizationId, username, { page = 1, pageSize = 50, ...params }) {

  return dispatch => {
    dispatch(fetchUserCallsStart());

    return apiRequest.get(`organizations/${organizationId}/users/${username}/calls`, { 
      page, 
      ...params,
      ...(params.to_phone_number ? { to_phone_number: formatPhone(params.to_phone_number) } : {}),
      ...(params.from_phone_number ? { from_phone_number: formatPhone(params.from_phone_number) } : {}) 
    })
      .then(({ data: { data: items, meta } }) => dispatch(fetchUserCallsSuccess(items, {
        ...params,
        ...get(meta, 'pagination', {})
      })))
      .catch(error => dispatch(fetchUserCallsError(error)));
  };
}