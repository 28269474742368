import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, change as changeFormAction } from 'redux-form';
import { push as pushAction } from 'connected-react-router';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import size from 'lodash/size';
import first from 'lodash/first';
import sortBy from 'lodash/sortBy';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchRingGroups } from '../../../actions/RingGroupActions';
import {
  fetchCallQueue,
  fetchCallQueues,
  updateCallQueue as updateCallQueueAction,
  updateCallQueueEntries as updateCallQueueEntriesAction,
} from '../../../actions/CallQueueActions';

import { fetchMessages as fetchMessagesAction } from '../../../actions/MessageActions';

import { CallQueueForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class CallQueueEditPage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        callQueueId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    formValues: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      workflow_sid: PropTypes.string,
      business_hours: PropTypes.shape(),
      fallthrough_type: PropTypes.string,
      redirect_call_queue_id: PropTypes.number,
      max_reserve_workers: PropTypes.number,
      task_reservation_timeout: PropTypes.number,
    }),
    callQueues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        attributes: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ).isRequired,
    callQueuesFetched: PropTypes.bool.isRequired,
    organizationFetched: PropTypes.bool.isRequired,
    messagesFetched: PropTypes.bool.isRequired,
    callQueuesErrorMessage: PropTypes.string,
    organizationErrorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    fetchCallQueues: PropTypes.func.isRequired,
    fetchRingGroups: PropTypes.func.isRequired,
    fetchCallQueue: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired,
    updateCallQueue: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    callQueuesErrorMessage: null,
    organizationErrorMessage: null,
    formValues: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const {
      match: {
        params: { organizationId, callQueueId },
      },
      messagesFetched,
    } = this.props;

    this.props.fetchCallQueue(callQueueId).then(() => {
      this.props.fetchOrganization(organizationId);
      this.props.fetchCallQueues(organizationId, { page });
      this.props.fetchRingGroups(organizationId, { page });
    });

    if (!messagesFetched) {
      this.props.fetchMessages(organizationId, { page });
    }
  }

  render() {
    const {
      match: {
        params: { organizationId, callQueueId },
      },
      formValues,
      callQueues,
      callQueuesFetched,
      callQueuesErrorMessage,
      organizationFetched,
      organizationErrorMessage,
      item,
      itemFetched,
      itemErrorMessage,
      itemEntriesErrorMessage,
      messagesErrorMessage,
      messages,
      ringGroups,
      ringGroupsFetched,
      ringGroupsErrorMessage,
      updateCallQueue,
      updateCallQueueEntries,
      changeForm,
      push,
    } = this.props;

    return (
      <div className="row" id="call-queue-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">
                {isEmpty(item)
                  ? 'Call Queue'
                  : `Call Queue: ${item.id} | ${get(item, 'attributes.name')}`}
              </h3>
            </div>
          </div>

          <CrudTabs
            model={`organizations/${organizationId}/callQueues`}
            id={callQueueId}
            active="edit"
          />

          <div className="card">
            <div className="card-block">
              <Loading
                loaded={
                  itemFetched &&
                  callQueuesFetched &&
                  ringGroupsFetched &&
                  organizationFetched
                }
              >
                <CallQueueForm
                  initialValues={{
                    ...pick(get(item, 'attributes'), [
                      'name',
                      'description',
                      'business_hours',
                      'fallthrough_type',
                      'redirect_call_queue_id',
                      'max_reserve_workers',
                      'task_reservation_timeout',
                    ]),
                    fallthrough_type: get(
                      item,
                      'attributes.fallthrough_type',
                      'voicemail'
                    ),
                    entries: sortBy(
                      get(item, 'relationships.entries.data', []).map(
                        (entry, index) => ({
                          id: entry.id,
                          timeout: get(entry, 'attributes.timeout_sec'),
                          ring_group: get(entry, 'attributes.ring_group'),
                        })
                      ),
                      'id'
                    ),
                    voicemail_message_id: get(
                      item,
                      'relationships.voicemail_message.data.id'
                    ),
                  }}
                  formValues={formValues}
                  organizationId={organizationId}
                  item={item}
                  callQueues={callQueues.map(callQueue => ({
                    id: callQueue.id,
                    name: get(callQueue, 'attributes.name'),
                  }))}
                  ringGroups={ringGroups.map(ringGroup => ({
                    id: ringGroup.id,
                    name: get(ringGroup, 'attributes.name'),
                  }))}
                  errorMessage={
                    itemErrorMessage ||
                    itemEntriesErrorMessage ||
                    callQueuesErrorMessage ||
                    organizationErrorMessage ||
                    ringGroupsErrorMessage ||
                    messagesErrorMessage
                  }
                  messages={messages}
                  activeMessage={first(
                    filter(
                      messages,
                      m =>
                        m.id ===
                        get(item, 'relationships.voicemail_message.data.id')
                    )
                  )}
                  onSubmit={values => {
                    Promise.all([
                      updateCallQueue(callQueueId, omit(values, ['entries'])),
                      updateCallQueueEntries(
                        callQueueId,
                        get(pick(values, ['entries']), 'entries')
                      ),
                    ]).then(() =>
                      push(`/organizations/${organizationId}/callQueues`)
                    );
                  }}
                  onAddEntry={() =>
                    changeForm('call-queue', 'entries', [
                      ...formValues.entries,
                      {
                        id: size(formValues.entries),
                      },
                    ])
                  }
                  onRemoveEntry={entry =>
                    changeForm(
                      'call-queue',
                      'entries',
                      compact(
                        filter(formValues.entries, ({ id }) => id !== entry.id)
                      ).map((eachEntry, index) => ({
                        ...eachEntry,
                        id: index,
                      }))
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('call-queue');

const formFields = [
  'name',
  'description',
  'workflow_sid',
  'business_hours',
  'fallthrough_type',
  'redirect_call_queue_id',
  'entries',
  'task_reservation_timeout',
  'max_reserve_workers',
];

export default connect(
  state => {
    const {
      callQueues: {
        item,
        itemFetched,
        itemErrorMessage,
        itemEntriesErrorMessage,
        items: callQueues,
        itemsFetched: callQueuesFetched,
        itemsErrorMessage: callQueuesErrorMessage,
      },
      messages: {
        items: messages,
        itemsMeta: messagesMeta,
        itemsFetched: messagesFetched,
        itemsErrorMessage: messagesErrorMessage,
      },
      ringGroups: {
        items: ringGroups,
        itemsFetched: ringGroupsFetched,
        itemsErrorMessage: ringGroupsErrorMessage,
      },
      organizations: {
        itemFetched: organizationFetched,
        itemErrorMessage: organizationErrorMessage,
      },
    } = state;

    return {
      item,
      itemFetched,
      itemErrorMessage,
      itemEntriesErrorMessage,
      messages,
      messagesMeta,
      messagesFetched,
      messagesErrorMessage,
      callQueues,
      callQueuesFetched,
      callQueuesErrorMessage,
      ringGroups,
      ringGroupsFetched,
      ringGroupsErrorMessage,
      organizationFetched,
      organizationErrorMessage,
      formValues: fromPairs(
        formFields.map(formField => [formField, selector(state, formField)])
      ),
    };
  },
  {
    fetchOrganization,
    fetchCallQueue,
    fetchCallQueues,
    fetchRingGroups,
    fetchMessages: fetchMessagesAction,
    updateCallQueue: updateCallQueueAction,
    updateCallQueueEntries: updateCallQueueEntriesAction,
    changeForm: changeFormAction,
    push: pushAction,
  }
)(CallQueueEditPage);
