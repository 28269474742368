import { apiRequest } from '../../utils';

import {
  MONITOR_WHISPER_CALL_START,
  MONITOR_WHISPER_CALL_SUCCESS,
  MONITOR_WHISPER_CALL_ERROR
} from '../../constants/Call';

function monitorWhisperCallStart() {
  return {
    type: MONITOR_WHISPER_CALL_START
  };
}

function monitorWhisperCallSuccess(data) {
  return {
    type: MONITOR_WHISPER_CALL_SUCCESS,
    data
  };
}

function monitorWhisperCallError(errorMessage) {
  return {
    type: MONITOR_WHISPER_CALL_ERROR,
    errorMessage
  };
}

export default function monitorWhisperCall(organizationId, id) {
  return dispatch => {
    dispatch(monitorWhisperCallStart());

    return apiRequest
      .put(`organizations/${organizationId}/agent/calls/${id}`, null, {
        data: {
          attributes: {
            mode: 'whisper'
          }
        }
      })
      .then(({ data: { data } }) => dispatch(monitorWhisperCallSuccess(data)))
      .catch(err => dispatch(monitorWhisperCallError(err)));
  };
}
