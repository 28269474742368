import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { LinkTo, Button, Paginate } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';

function VoicemailsTable({ voicemails, meta, onPaginate, onUpdateVoicemail }) {
  if (size(voicemails) === 0) {
    return <h1>No Records</h1>;
  }

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
        <tr>
          <th className="footable-sortable">
            Created At
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Duration
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            From
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            To
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Transcription
          </th>
          <th className="footable-sortable">
            Status
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">Actions</th>
        </tr>
      </thead>
      <tbody>
        {voicemails.map((voicemail, index) => (
          <tr key={voicemail.id} className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}>
            <td>
              {renderHumanDateTime(get(voicemail, 'attributes.created_at'))}
            </td>
            <td>
              {get(voicemail, 'attributes.duration')}
            </td>
            <td>
                {get(voicemail, 'relationships.call.data.relationships.from.data.id')}
            </td>
            <td>
                {get(voicemail, 'relationships.call.data.relationships.to.data.attributes.name')}
                
                {get(voicemail, 'relationships.user_role.data') ? get(voicemail, 'relationships.user_role.data.attributes.username') : null}
                
                {get(voicemail, 'relationships.call.data.relationships.to.data.type') === 'phone_numbers' ? get(voicemail, 'relationships.call.data.relationships.to.data.id') : null}
            </td>
            <td>
              {get(voicemail, 'attributes.transcription_text')}
            </td>
            <td>
              {get(voicemail, 'attributes.status')}
            </td>
            <td>
              <div className="btn-group">
                <LinkTo
                  button
                  color="outline-success"
                  icon="play"
                  href={`${voicemail.attributes.recording_url}`}
                  target="_blank"
                  pureLink
                >
                  Play Recording
                </LinkTo>
                {get(voicemail, 'attributes.status') === 'seen' ? (<Button
                  button
                  color="outline-warning"
                  icon="check"
                  onClick={() => onUpdateVoicemail(voicemail.id, 'unseen')}
                >
                  Mark as unseen
                </Button>)
                : (<Button
                  button
                  color="outline-warning"
                  icon="check"
                  onClick={() => onUpdateVoicemail(voicemail.id, 'seen')}
                >
                  Mark as seen
                </Button>)}
                
                {get(voicemail, 'attributes.status') === 'archived' ? null : 
                (<Button
                  button
                  color="outline-danger"
                  icon="archive"
                  disabled={get(voicemail, 'attributes.status') === 'archived'}
                  onClick={() => onUpdateVoicemail(voicemail.id, 'archived')}
                >
                  Archive
                </Button>)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>
            <Paginate
              count={meta.count}
              pageSize={meta.pageSize}
              activePage={meta.page}
              onSelect={page => onPaginate(page)}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

VoicemailsTable.propTypes = {
  voicemails: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number
  }).isRequired,
  onPaginate: PropTypes.func.isRequired,
  onUpdateVoicemail: PropTypes.func.isRequired
};

export default VoicemailsTable;
