import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import BlacklistsIndexPage from './BlacklistsIndexPage/BlacklistsIndexPage';
import BlacklistAddPage from './BlacklistAddPage/BlacklistAddPage';
import BlacklistShowPage from './BlacklistShowPage/BlacklistShowPage';
import BlacklistEditPage from './BlacklistEditPage/BlacklistEditPage';
import BlacklistDeletePage from './BlacklistDeletePage/BlacklistDeletePage';

export default [
  <Route
    exact
    key="blacklists"
    path="/organizations/:organizationId/blacklists"
    component={RequireAuth(BlacklistsIndexPage)}
  />,
  <Route
    exact
    key="blacklist-new"
    path="/organizations/:organizationId/blacklists/new"
    component={RequireAuth(BlacklistAddPage)}
  />,
  <Route
    exact
    key="blacklist-show"
    path="/organizations/:organizationId/blacklists/:id/show"
    component={RequireAuth(BlacklistShowPage)}
  />,
  <Route
    exact
    key="blacklist-edit"
    path="/organizations/:organizationId/blacklists/:id/edit"
    component={RequireAuth(BlacklistEditPage)}
  />,
  <Route
    exact
    key="blacklist-delete"
    path="/organizations/:organizationId/blacklists/:id/delete"
    component={RequireAuth(BlacklistDeletePage)}
  />
];
