import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { LinkTo } from '../../../helpers';

function UserInfo({ item, organizationId }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Username</td>
          <td>{get(item, 'relationships.user_role.data.attributes.username')}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{get(item, 'attributes.email')}</td>
        </tr>
        <tr>
          <td>Role</td>
          <td>{get(item, 'relationships.user_role.data.attributes.role')}</td>
        </tr>
        <tr>
          <td>Ring Groups</td>
          <td>
            {get(item, 'relationships.ring_groups.data', []).map((ringGroup, index) => (
              <span key={ringGroup.id}>
                {!!index && ' / '}
                <LinkTo
                  href={`organizations/${organizationId}/ringGroups/${ringGroup.id}/show`}
                >
                  {get(ringGroup, 'attributes.name')}
                </LinkTo>
              </span>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

UserInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default UserInfo;
