import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_CALL_FLOWS_START,
  ADD_CALL_FLOWS_SUCCESS,
  ADD_CALL_FLOWS_ERROR
} from '../../constants/CallFlow';

// fetchCallFlows

export function addCallFlowsStart() {
  return {
    type: ADD_CALL_FLOWS_START
  };
}

export function addCallFlows(items = [], meta = {}) {
  return {
    type: ADD_CALL_FLOWS_SUCCESS,
    items,
    meta
  };
}

export function addCallFlowsError(error) {
  return {
    type: ADD_CALL_FLOWS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchCallFlows(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addCallFlowsStart());

    return apiRequest.get(`organizations/${organizationId}/call_flows`, { page, pageSize })
      .then(({ data: { data: items } }) => Promise.resolve(dispatch(addCallFlows(items, { page, pageSize }))))
      .catch(error => Promise.reject(dispatch(addCallFlowsError(error))));
  };
}
