import Promise from 'bluebird';

import { COPY_CALL_FLOW_ENTRY_SUCCESS } from '../../constants/CallFlowEntry';

// copyCallFlowEntry

export function copyCallFlowEntrySuccess(item) {
  return {
    type: COPY_CALL_FLOW_ENTRY_SUCCESS,
    item
  };
}

export default function copyCallFlowEntry(entry) {
  return dispatch => Promise.resolve(dispatch(copyCallFlowEntrySuccess(entry)));
}
