export const ADD_PHONE_NUMBERS_START = 'ADD_PHONE_NUMBERS_START';
export const ADD_PHONE_NUMBERS_SUCCESS = 'ADD_PHONE_NUMBERS_SUCCESS';
export const ADD_PHONE_NUMBERS_ERROR = 'ADD_PHONE_NUMBERS_ERROR';

export const ADD_AVAILABLE_PHONE_NUMBERS_START = 'ADD_AVAILABLE_PHONE_NUMBERS_START';
export const ADD_AVAILABLE_PHONE_NUMBERS_SUCCESS = 'ADD_AVAILABLE_PHONE_NUMBERS_SUCCESS';
export const ADD_AVAILABLE_PHONE_NUMBERS_ERROR = 'ADD_AVAILABLE_PHONE_NUMBERS_ERROR';

export const ADD_CAMPAIGN_PHONE_NUMBERS_START = 'ADD_CAMPAIGN_PHONE_NUMBERS_START';
export const ADD_CAMPAIGN_PHONE_NUMBERS_SUCCESS = 'ADD_CAMPAIGN_PHONE_NUMBERS_SUCCESS';
export const ADD_CAMPAIGN_PHONE_NUMBERS_ERROR = 'ADD_CAMPAIGN_PHONE_NUMBERS_ERROR';

export const ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_START = 'ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_START';
export const ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_SUCCESS = 'ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_SUCCESS';
export const ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_ERROR = 'ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_ERROR';

export const BUY_PHONE_NUMBER_START = 'BUY_PHONE_NUMBER_START';
export const BUY_PHONE_NUMBER_SUCCESS = 'BUY_PHONE_NUMBER_SUCCESS';
export const BUY_PHONE_NUMBER_ERROR = 'BUY_PHONE_NUMBER_ERROR';

export const SYNC_PHONE_NUMBERS_START = 'SYNC_PHONE_NUMBERS_START';
export const SYNC_PHONE_NUMBERS_SUCCESS = 'SYNC_PHONE_NUMBERS_SUCCESS';
export const SYNC_PHONE_NUMBERS_ERROR = 'SYNC_PHONE_NUMBERS_ERROR';

export const SHOW_PHONE_NUMBER_START = 'SHOW_PHONE_NUMBER_START';
export const SHOW_PHONE_NUMBER_SUCCESS = 'SHOW_PHONE_NUMBER_SUCCESS';
export const SHOW_PHONE_NUMBER_ERROR = 'SHOW_PHONE_NUMBER_ERROR';

export const CREATE_PHONE_NUMBER_START = 'CREATE_PHONE_NUMBER_START';
export const CREATE_PHONE_NUMBER_SUCCESS = 'CREATE_PHONE_NUMBER_SUCCESS';
export const CREATE_PHONE_NUMBER_ERROR = 'CREATE_PHONE_NUMBER_ERROR';

export const UPDATE_PHONE_NUMBER_START = 'UPDATE_PHONE_NUMBER_START';
export const UPDATE_PHONE_NUMBER_SUCCESS = 'UPDATE_PHONE_NUMBER_SUCCESS';
export const UPDATE_PHONE_NUMBER_ERROR = 'UPDATE_PHONE_NUMBER_ERROR';

export const DELETE_PHONE_NUMBER_START = 'DELETE_PHONE_NUMBER_START';
export const DELETE_PHONE_NUMBER_SUCCESS = 'DELETE_PHONE_NUMBER_SUCCESS';
export const DELETE_PHONE_NUMBER_ERROR = 'DELETE_PHONE_NUMBER_ERROR';