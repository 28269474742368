import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { SHOW_USER_START, SHOW_USER_SUCCESS, SHOW_USER_ERROR } from '../../constants/User';

// fetchUser

export function showUserStart() {
  return {
    type: SHOW_USER_START
  };
}

export function showUser(item = {}) {
  return {
    type: SHOW_USER_SUCCESS,
    item
  };
}

export function showUserError(error) {
  return {
    type: SHOW_USER_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchUser(organizationId, username, options = {}) {
  return dispatch => {
    dispatch(showUserStart());

    return apiRequest
      .get(`organizations/${organizationId}/users/${username}`, options)
      .then(({ data: { data: item } }) => dispatch(showUser(item)))
      .catch(error => dispatch(showUserError(error)));
  };
}
