import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Button } from '../../../helpers';
import cl from 'classnames';

import {
  monitorCall as monitorCallAction,
  monitorWhisperCall as monitorWhisperCallAction,
  monitorSpeakCall as monitorSpeakCallAction,
  stopMonitoringCall as stopMonitoringCallAction,
} from '../../../actions/MonitorActions';

class MonitorControls extends Component {
  hangup() {
    return window.Twilio.Device.disconnectAll();
  }

  render() {
    const {
      call,
      showMonitoringControls,
      monitoringMode,
      organizationId,
    } = this.props;

    return showMonitoringControls ? (
      <>
        <Button
          size="xs"
          children="Listen (muted)"
          color={cl({
            primary: monitoringMode === 'mute',
          })}
          flat
          onClick={() => this.props.monitorCall(organizationId, call.id)}
        />
        <Button
          size="xs"
          children="Whisper (to agent only)"
          color={cl({
            primary: monitoringMode === 'whisper',
          })}
          onClick={() => this.props.monitorWhisperCall(organizationId, call.id)}
        />
        <Button
          size="xs"
          children="Speak (to both)"
          color={cl({
            primary: monitoringMode === 'speak',
          })}
          onClick={() => this.props.monitorSpeakCall(organizationId, call.id)}
        />
        <Button
          size="xs"
          color="warning"
          children="Stop Monitoring"
          outline
          onClick={() => this.props.stopMonitoringCall(organizationId, call.id)}
        />
      </>
    ) : null;
  }
}

export default connect(null, {
  monitorCall: monitorCallAction,
  monitorWhisperCall: monitorWhisperCallAction,
  monitorSpeakCall: monitorSpeakCallAction,
  stopMonitoringCall: stopMonitoringCallAction,
})(MonitorControls);
