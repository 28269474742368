import React from 'react';
import PropTypes from 'prop-types';

function Boolean({ value }) {
  if (value) {
    return <i className="fa fa-check-square" />;
  }

  return <i className="fa fa-square-o" />;
}

Boolean.propTypes = {
  value: PropTypes.bool.isRequired
};

export default Boolean;
