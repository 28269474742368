import { push } from "react-router-redux";
import get from "lodash/get";

import { apiRequest, formatPhone } from "../../utils";

import {
  CREATE_FAVORITE_START,
  CREATE_FAVORITE_SUCCESS,
  CREATE_FAVORITE_ERROR
} from "../../constants/Favorite";

// createFavorite

export function createFavoriteStart() {
  return {
    type: CREATE_FAVORITE_START
  };
}

export function createFavoriteSuccess(item = {}) {
  return {
    type: CREATE_FAVORITE_SUCCESS,
    item
  };
}

export function createFavoriteError(error) {
  return {
    type: CREATE_FAVORITE_ERROR,
    errorMessage: get(
      error,
      "response.data.errors[0].detail",
      "Server-side error"
    )
  };
}

export default function createFavorite(organizationId, values) {
  return dispatch => {
    dispatch(createFavoriteStart());

    return apiRequest
      .post(`organizations/${organizationId}/favorites`, {
        ...values,
        phone_number: formatPhone(values.phone_number)
      })
      .then(({ data }) => {
        dispatch(createFavoriteSuccess(data));
      })
      .catch(error => dispatch(createFavoriteError(error)));
  };
}
