import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { sendPassword as sendPasswordAction } from '../../../actions/AuthActions';

import { ResetPasswordForm } from '../../../components';
import { LinkTo } from '../../../helpers';

function ResetPasswordPage({ isLoading, successMessage, errorMessage, sendPassword }) {
  return (
    <div className="login-box card">
      <div className="card-block">

        <h1 className="text-center db">
          <i className="fa fa-envelope" /> ContactFrame Admin
        </h1>

        <h3 className="box-title m-b-20">Recover Password</h3>
        <p className="text-muted">Enter your username and instructions will be sent to you!</p>

        <ResetPasswordForm
          isLoading={isLoading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          onSubmit={user => sendPassword(user)}
        />

        <div className="form-group m-b-0">
          <div className="col-sm-12 text-center">
            <p>Don{"'"}t have an account? <LinkTo href="auth/signup" className="m-l-5"><b>Sign Up</b></LinkTo></p>
          </div>
        </div>
      </div>
    </div>
  );
}

ResetPasswordPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  sendPassword: PropTypes.func.isRequired
};

ResetPasswordPage.defaultProps = {
  successMessage: null,
  errorMessage: null
};

export default connect(({
  auth: {
    isLoading,
    successMessage,
    errorMessage
  }
}) => ({
  isLoading,
  successMessage,
  errorMessage
}), { sendPassword: sendPasswordAction })(ResetPasswordPage);
