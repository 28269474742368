import { LOCATION_CHANGE } from 'connected-react-router';

import {
  CREATE_RING_GROUP_MEMBER_START,
  CREATE_RING_GROUP_MEMBER_SUCCESS,
  CREATE_RING_GROUP_MEMBER_ERROR,
  DELETE_RING_GROUP_MEMBER_START,
  DELETE_RING_GROUP_MEMBER_SUCCESS,
  DELETE_RING_GROUP_MEMBER_ERROR,
} from '../constants/RingGroupMember';

const defaultState = {
  path: null,
  item: {},
  itemLoading: false,
  itemFetched: false,
  itemErrorMessage: null,
};

export default function RingGroupMemberReducer(
  state = defaultState,
  { type, payload, item, errorMessage }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case CREATE_RING_GROUP_MEMBER_START:
      return {
        ...state,
        itemLoading: true,
      };

    case CREATE_RING_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case CREATE_RING_GROUP_MEMBER_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    case DELETE_RING_GROUP_MEMBER_START:
      return {
        ...state,
        itemLoading: true,
      };

    case DELETE_RING_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case DELETE_RING_GROUP_MEMBER_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    default:
      return state;
  }
}
