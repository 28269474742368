import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import omit from 'lodash/omit';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchUser } from '../../../actions/UserActions';
import { fetchUserCalls } from '../../../actions/CallActions';

import {
  UserInfo,
  CallLogsTable,
  CallsHistoryFilterForm,
} from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class UsersShowPage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    fetchUserCalls: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      match: {
        params: { organizationId, username },
      },
      itemsMeta,
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchUser(organizationId, username);
    this.props.fetchUserCalls(organizationId, username, { page: 1 });
  }

  render() {
    const {
      match: {
        params: { organizationId, username },
      },
      item,
      itemFetched,
      items,
      itemsMeta,
      itemsFetched,
      itemsErrorMessage,
      fetchUserCalls,
    } = this.props;

    return (
      <div id="user-show">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">User Info</h3>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/users`}
          id={username}
          active="show"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <UserInfo item={item} organizationId={organizationId} />
                  <div className="row">
                    <h4>Calls History</h4>
                  </div>
                  <CallsHistoryFilterForm
                    initialValues={itemsMeta}
                    agents={[]}
                    onSubmit={values =>
                      fetchUserCalls(organizationId, username, {
                        ...itemsMeta,
                        ...values,
                        ...omit(itemsMeta, [
                          'pageSize',
                          'count',
                          'pageCount',
                          'page_count',
                          'page_size',
                          'count',
                        ]),
                      })
                    }
                    onClear={() =>
                      fetchUserCalls(organizationId, username, { page: 1 })
                    }
                  />
                  <CallLogsTable
                    items={items}
                    username={username}
                    meta={itemsMeta}
                    onPaginate={page =>
                      fetchUserCalls(organizationId, username, {
                        ...itemsMeta,
                        page,
                      })
                    }
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    users: { item, itemFetched },
    calls: { items, itemsMeta, itemsFetched, itemsErrorMessage },
  }) => ({
    item,
    itemFetched,
    items,
    itemsMeta,
    itemsFetched,
    itemsErrorMessage,
  }),
  { fetchOrganization, fetchUser, fetchUserCalls }
)(UsersShowPage);
