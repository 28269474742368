import React from 'react';
import PropTypes from 'prop-types';

function PhoneNumberInfo({ item, errorMessage }) {
  return (
    <div className="row">
      <div className="col-12">
        {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}

        <table className="table table-hover table-bordered table-striped">
          <tbody>
            <tr>
              <td>ID</td>
              <td>{item.id}</td>
            </tr>
            <tr>
              <td>SID</td>
              <td>{item.sid}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{item.number}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

PhoneNumberInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    sid: PropTypes.string,
    number: PropTypes.string
  }).isRequired,
  errorMessage: PropTypes.string
};

PhoneNumberInfo.defaultProps = {
  errorMessage: null
};

export default PhoneNumberInfo;
