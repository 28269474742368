import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import { Button } from '../../../helpers';

import './style.css';

class PhoneBar extends Component {
  componentDidMount() {
    return this.initializePhone();
  }

  initializePhone() {
    const { token } = this.props;

    console.log('InitializePhone event received', token);

    window.Twilio.Device.setup(token, { debug: true });

    window.Twilio.Device.ready(device => {
      console.log('device ready', device);
    });

    window.Twilio.Device.error(error => {
      console.log('Twilio.Device.error', error);
    });

    window.Twilio.Device.connect(connection => {
      console.log('Twilio.Device.connect', connection);
    });

    window.Twilio.Device.disconnect(connection => {
      console.log('Twilio.Device.disconnect', connection);
    });

    window.Twilio.Device.offline(device => {
      console.log('Twilio.Device.offline', device);
    });

    window.Twilio.Device.incoming(connection => {
      console.log('Twilio.Device.incoming', connection);

      connection.accept();

      connection.disconnect(conn => {
        console.log('Twilio.Device.incoming connection.disconnect', conn);
      });
    });
  }

  hangup() {
    return window.Twilio.Device.disconnectAll();
  }

  render() {
    // const { call, organizationId, onCallComplete, terminateCall, isMonitoring, monitoringMode } = this.props;
    return <div />;
  }
}

PhoneBar.propTypes = {
  token: PropTypes.string.isRequired
};

export default PhoneBar;
