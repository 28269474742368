import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { signinUser as signinUserAction } from '../../../actions/AuthActions';

import { SigninForm } from '../../../components';
import { LinkTo } from '../../../helpers';

function SigninPage({ isLoading, successMessage, errorMessage, signinUser }) {
  return (
    <div className="login-box card">
      <div className="card-block">

        <h2 className="text-center db">
          <i className="fa fa-envelope" /> ContactFrame Admin
        </h2>

        <h3 className="box-title m-b-20">Sign In</h3>

        <SigninForm
          isLoading={isLoading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          onSubmit={signinUser}
        />

        <div className="form-group m-b-0">
          <div className="col-sm-12 text-center">
            <p>Don{"'"}t have an account? <LinkTo href="auth/signup" className="m-l-5"><b>Sign Up</b></LinkTo></p>
          </div>
        </div>
      </div>
    </div>
  );
}

SigninPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  signinUser: PropTypes.func.isRequired
};

SigninPage.defaultProps = {
  successMessage: null,
  errorMessage: null
};

export default connect(({
  auth: {
    isLoading,
    successMessage,
    errorMessage
  }
}) => ({ isLoading, successMessage, errorMessage }), { signinUser: signinUserAction })(SigninPage);
