import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { push as pushAction } from 'connected-react-router';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import {
  fetchMessage,
  updateMessage as updateMessageAction,
} from '../../../actions/MessageActions';
import {
  uploadFile as uploadFileAction,
  deleteFile as deleteFileAction,
} from '../../../actions/FileActions';

import { MessageForm } from '../../../components';
import { CrudTabs, ErrorMessage, Loading } from '../../../helpers';

class MessageEditPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    formValues: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      owner_username: PropTypes.string,
      message: PropTypes.string,
      file_url: PropTypes.string,
    }).isRequired,

    organization: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,

    message: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    messageFetched: PropTypes.bool.isRequired,
    messageLoading: PropTypes.bool.isRequired,
    messageErrorMessage: PropTypes.string,

    fileLoading: PropTypes.bool.isRequired,
    fileErrorMessage: PropTypes.string,

    fetchMessage: PropTypes.func.isRequired,
    updateMessage: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    messageErrorMessage: null,
    fileErrorMessage: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId, messageId },
      },
    } = this.props;

    this.props.fetchMessage(organizationId, messageId);
  }

  render() {
    const {
      match: {
        params: { organizationId, messageId },
      },
      formValues,
      organization,
      message,
      messageFetched,
      messageLoading,
      messageErrorMessage,
      fileLoading,
      fileErrorMessage,
      updateMessage,
      uploadFile,
      push,
    } = this.props;

    return (
      <div className="row" id="message-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Update Message #{messageId}</h3>
            </div>
          </div>

          <CrudTabs
            model={`organizations/${organizationId}/messages`}
            id={messageId}
            active="edit"
          />

          <div className="card">
            <div className="card-block">
              <ErrorMessage>{messageErrorMessage}</ErrorMessage>
              <Loading loaded={messageFetched}>
                <MessageForm
                  item={message}
                  initialValues={{
                    name: get(message, 'attributes.name'),
                    description: get(message, 'attributes.description'),
                    type: get(message, 'attributes.type'),
                    owner_username: get(
                      message,
                      'relationships.owner_user_role.data.attributes.username'
                    ),
                    message: get(message, 'attributes.message'),
                    file_url: get(message, 'attributes.file_url'),
                  }}
                  formValues={formValues}
                  organizationId={organizationId}
                  isLoading={messageLoading || fileLoading}
                  isFileLoading={fileLoading}
                  errorMessage={messageErrorMessage || fileErrorMessage}
                  agents={get(
                    organization,
                    'relationships.user_roles.data',
                    []
                  ).map(userRole => ({
                    value: get(userRole, 'attributes.username'),
                    label: [
                      get(userRole, 'attributes.first_name'),
                      get(userRole, 'attributes.last_name'),
                    ].join(' '),
                  }))}
                  onUpload={file => uploadFile(organizationId, file)}
                  onSubmit={values =>
                    updateMessage(organizationId, messageId, values).then(() =>
                      push(`/organizations/${organizationId}/messages`)
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('message');

const formFields = [
  'name',
  'description',
  'type',
  'owner_username',
  'message',
  'file_url',
];

export default connect(
  state => {
    const {
      organizations: { item: organization },
      messages: {
        item: message,
        itemFetched: messageFetched,
        itemLoading: messageLoading,
        itemErrorMessage: messageErrorMessage,
      },
      files: { itemLoading: fileLoading, itemErrorMessage: fileErrorMessage },
    } = state;

    return {
      organization,

      message,
      messageFetched,
      messageLoading,
      messageErrorMessage,

      fileLoading,
      fileErrorMessage,

      formValues: fromPairs(
        formFields.map(formField => [formField, selector(state, formField)])
      ),
    };
  },
  {
    fetchMessage,
    updateMessage: updateMessageAction,
    deleteFile: deleteFileAction,
    uploadFile: uploadFileAction,
    push: pushAction,
  }
)(MessageEditPage);
