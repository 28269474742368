import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Divider, Empty, Tabs, Tag, Col, Row } from 'antd';
import {
  selectRequestsLoading,
  selectAllRequests,
  subscribeToRequests,
} from '../../reducers/requestsSlice';

const { TabPane } = Tabs;

const Requests = () => {
  const requests = useSelector(selectAllRequests);
  const loading = useSelector(selectRequestsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchRequests());
    dispatch(subscribeToRequests());
  }, [dispatch]);

  return (
    <Table
      dataSource={requests}
      loading={loading}
      rowKey="id"
      columns={[
        {
          title: 'Method',
          dataIndex: 'method',
        },
        {
          title: 'Path',
          dataIndex: 'path',
        },
        {
          title: 'Time',
          dataIndex: 'time',
          render: (text, record) => `${text * 1000}ms`,
        },
        {
          title: 'IP',
          dataIndex: 'ip',
        },
        {
          title: 'Status',
          dataIndex: 'response.status_code',
        },
      ]}
      expandedRowRender={request => (
        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Headers" key="1">
              <Col style={{ fontSize: 10 }}>
                <pre>{JSON.stringify(request.headers, null, 2)}</pre>
              </Col>
            </TabPane>
            <TabPane tab="Response" key="3">
              <h4>Headers</h4>
              <Col>
                <Col style={{ fontSize: 10, wordWrap: 'break-word' }}>
                  <pre>{JSON.stringify(request.response.headers, null, 2)}</pre>
                </Col>
              </Col>
              <Divider />
              <h4>Payload</h4>
              <Col style={{ fontSize: 10 }}>
                <pre>{JSON.stringify(request.response.payload, null, 2)}</pre>
              </Col>
            </TabPane>
          </Tabs>

          <Divider orientation="left">Logs</Divider>
          {request.logs.length > 0 ? (
            request.logs.map((log, index) => (
              <>
                <Row>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      marginRight: 10,
                    }}
                  >
                    {new Date(log.timestamp).toLocaleString('en-us')}
                  </span>{' '}
                  {log.tags.map(tag => (
                    <Tag>{tag}</Tag>
                  ))}
                  <Col style={{ fontSize: 10 }}>
                    <pre>{JSON.stringify(log.data, null, 2)}</pre>
                  </Col>
                </Row>
              </>
            ))
          ) : (
            <Empty />
          )}
        </div>
      )}
    />
  );
};

export default Requests;
