import {
  FETCH_AGENT_SESSION_START,
  FETCH_AGENT_SESSION_SUCCESS,
  FETCH_AGENT_SESSION_ERROR
} from '../constants/AgentSession';

const defaultState = {
  session: null,
  sessionFetched: false,
  sessionLoading: false,
  sessionErrorMessage: null
};

export default function AgentSessionReducer(state = defaultState, { type, session, sessionErrorMessage }) {
  switch (type) {
    case FETCH_AGENT_SESSION_START:
      return {
        ...state,
        sessionFetched: false,
        sessionLoading: true
      };

    case FETCH_AGENT_SESSION_SUCCESS:
      return {
        ...state,
        sessionFetched: true,
        sessionLoading: false,
        sessionErrorMessage: null,
        session
      };

    case FETCH_AGENT_SESSION_ERROR:
      return {
        ...state,
        sessionFetched: true,
        sessionLoading: false,
        sessionErrorMessage
      };

    default:
      return state;
  }
}
