import { push } from 'connected-react-router';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import { authLoading, authUser, authError } from '../AuthActions';

// signupUser

export default function signupUser(values) {
  return dispatch => {
    dispatch(authLoading());

    apiRequest
      .authPost('users', {
        data: { attributes: omit(values, ['passwordConfirmation']) },
      })
      .then(({ data: { access_token: token, user } }) => {
        dispatch(
          authUser({
            username: user.username,
            accessToken: token,
            currentUser: user,
          })
        );
        dispatch(push('/profile'));
      })
      .catch(error => dispatch(authError(error)));
  };
}
