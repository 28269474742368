import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { LinkTo } from '../helpers';

function CrudTabs({ model, id, active, all, add, show, edit, destroy, beforeTabs, afterTabs }) {
  return (
    <div className="row">
      <div className="col-12">
        <ul className="nav nav-tabs customtab" role="tablist">
          {beforeTabs ? beforeTabs.map((beforeTab, index) => (
            <li key={index} className="nav-item">
              {beforeTab}
            </li>
          )) : null}
          {all ? (
            <li className="nav-item">
              <LinkTo icon="th-list" className={cl('nav-link', { active: active === 'all' })} href={model}>
                All
              </LinkTo>
            </li>
          ) : null}
          {add ? (
            <li className="nav-item">
              <LinkTo
                icon="plus"
                className={cl('nav-link', { active: active === 'add' })}
                href={`${model}/new`}
              >
                Add
              </LinkTo>
            </li>
          ) : null}
          {show ? (
            <li className="nav-item">
              <LinkTo
                icon="search"
                className={cl('nav-link', { active: active === 'show' })}
                href={`${model}/${id}/show`}
              >
                Show
              </LinkTo>
            </li>
          ) : null}
          {edit ? (
            <li className="nav-item">
              <LinkTo
                icon="pencil"
                className={cl('nav-link', { active: active === 'edit' })}
                href={`${model}/${id}/edit`}
              >
                Edit
              </LinkTo>
            </li>
          ) : null}
          {destroy ? (
            <li className="nav-item">
              <LinkTo
                icon="trash"
                className={cl('nav-link', { active: active === 'delete' })}
                href={`${model}/${id}/delete`}
              >
                Delete
              </LinkTo>
            </li>
          ) : null}
          {afterTabs ? afterTabs.map((afterTab, index) => (
            <li key={index} className="nav-item">
              {afterTab}
            </li>
          )) : null}
        </ul>
      </div>
    </div>
  );
}

CrudTabs.propTypes = {
  model: PropTypes.string.isRequired,
  id: PropTypes.string,
  active: PropTypes.string.isRequired,
  all: PropTypes.bool,
  add: PropTypes.bool,
  show: PropTypes.bool,
  edit: PropTypes.bool,
  destroy: PropTypes.bool,
  beforeTabs: PropTypes.arrayOf(PropTypes.node),
  afterTabs: PropTypes.arrayOf(PropTypes.node)
};

CrudTabs.defaultProps = {
  id: null,
  all: true,
  add: true,
  show: true,
  edit: true,
  destroy: true,
  beforeTabs: null,
  afterTabs: null
};

export default CrudTabs;
