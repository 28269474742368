import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_USERS_START,
  ADD_USERS_SUCCESS,
  ADD_USERS_ERROR
} from '../../constants/User';

// fetchUsers

export function addUsersStart() {
  return {
    type: ADD_USERS_START
  };
}

export function addUsers(items = [], meta = {}) {
  return {
    type: ADD_USERS_SUCCESS,
    items,
    meta
  };
}

export function addUsersError(error) {
  return {
    type: ADD_USERS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchUsers(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addUsersStart());
    return apiRequest.get(`organizations/${organizationId}/users`, { page, pageSize })
      .then(({ data: { data: items } }) => dispatch(addUsers(items, {
        page,
        pageSize
      })))
      .catch(error => dispatch(addUsersError(error)));
  };
}
