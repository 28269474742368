import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import {
  Button,
  renderDropdownList,
  renderDateTimePicker,
} from '../../../helpers';

function CallsHistoryFilterForm({ agents, onClear, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <div className="row">
            <div className="col-xs-12 col-md-2">
              <Field
                name="before"
                label="Before"
                component={renderDateTimePicker}
              />
            </div>
            <div className="col-xs-12 col-md-2">
              <Field
                name="after"
                label="After"
                component={renderDateTimePicker}
              />
            </div>
            <div className="col-xs-12 col-md-2">
              <Field
                name="status"
                label="Status"
                component={renderDropdownList}
                data={[
                  { value: '', label: 'All' },
                  { value: 'started', label: 'started' },
                  { value: 'in-progress', label: 'in-progress' },
                  { value: 'completed', label: 'completed' },
                  { value: 'missed', label: 'missed' },
                ]}
              />
            </div>
            <div className="col-xs-12 col-md-2">
              <label>Campaign Ph#</label>
              <div>
                <Field
                  name="caller_id"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-xs-12 col-md-2">
              <label>To #</label>
              <div>
                <Field
                  name="to_phone_number"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-xs-12 col-md-2">
              <label>From #</label>
              <div>
                <Field
                  name="from_phone_number"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-xs-12 col-md-2">
              <Field
                name="to_username"
                label="To Agent"
                component={renderDropdownList}
                data={[{ value: '', label: 'All' }, ...agents]}
              />
            </div>
            <div className="col-xs-12 col-md-2">
              <Field
                name="from_username"
                label="From Agent"
                component={renderDropdownList}
                data={[{ value: '', label: 'All' }, ...agents]}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Button color="primary" submit>
                Search
              </Button>
              <Button color="secondary" onClick={() => onClear()}>
                Reset
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

CallsHistoryFilterForm.propTypes = {
  onClear: PropTypes.func.isRequired,
  agents: PropTypes.array.isRequired,
};

export default reduxForm({ form: 'calls-filter' })(CallsHistoryFilterForm);
