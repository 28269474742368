import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { LinkTo } from '../../../helpers';

class Profile extends Component {

  static propTypes = {
    userName: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired
  };

  state = {
    showMenu: false
  };

  render() {
    const { userName, onLogout } = this.props;
    const { showMenu } = this.state;

    return (
      <div className="user-profile">
        <div className={cl('profile-text', { show: showMenu })}>
          <LinkTo pureLink href="#" onClick={() => this.setState({ showMenu: !showMenu })} className="dropdown-toggle link u-dropdown">
            {userName} <span className="caret" />
          </LinkTo>
          <div className="dropdown-menu animated flipInY">
            <LinkTo pureLink href="#" onClick={() => onLogout()} className="dropdown-item"><i className="fa fa-power-off" /> Logout</LinkTo>
          </div>
        </div>
      </div>
    );
  }
}

export default Profile;
