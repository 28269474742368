import React from 'react';
import PropTypes from 'prop-types';

function ErrorMessage({ children }) {
  return children ? (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-danger">{children}</div>
      </div>
    </div>
  ) : null;
}

ErrorMessage.propTypes = {
  children: PropTypes.node
};

ErrorMessage.defaultProps = {
  children: null
};

export default ErrorMessage;
