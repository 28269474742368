import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  UPDATE_CALL_FLOW_START,
  UPDATE_CALL_FLOW_SUCCESS,
  UPDATE_CALL_FLOW_ERROR
} from '../../constants/CallFlow';

// updateCallFlow

export function updateCallFlowStart() {
  return {
    type: UPDATE_CALL_FLOW_START
  };
}

export function updateCallFlowSuccess(item = {}) {
  return {
    type: UPDATE_CALL_FLOW_SUCCESS,
    item
  };
}

export function updateCallFlowError(error) {
  return {
    type: UPDATE_CALL_FLOW_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function updateCallFlow(callFlowId, values) {
  return dispatch => {
    dispatch(updateCallFlowStart());

    return apiRequest.put(`call_flows/${callFlowId}`, values)
      .then(({ data }) => Promise.resolve(dispatch(updateCallFlowSuccess(data))))
      .catch(error => Promise.reject(dispatch(updateCallFlowError(error))));
  };
}
