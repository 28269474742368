import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  UPDATE_VOICEMAIL_START,
  UPDATE_VOICEMAIL_SUCCESS,
  UPDATE_VOICEMAIL_ERROR
} from '../../constants/Voicemail';

// fetchVoicemails

export function updateVoicemailStart() {
  return {
    type: UPDATE_VOICEMAIL_START
  };
}

export function updateVoicemailSuccess(voicemail = [], meta = {}) {
  return {
    type: UPDATE_VOICEMAIL_SUCCESS,
    voicemail,
    meta
  };
}

export function updateVoicemailError(error) {
  return {
    type: UPDATE_VOICEMAIL_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function updateVoicemail(organizationId, voicemailId, status) {
  return dispatch => {
    dispatch(updateVoicemailStart());

    return apiRequest.put(`organizations/${organizationId}/voicemails/${voicemailId}`, { status })
      .then(({ data: { data: voicemail } }) => Promise.resolve(dispatch(updateVoicemailSuccess(voicemail))))
      .catch(error => Promise.reject(dispatch(updateVoicemailError(error))));
  };
}
