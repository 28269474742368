import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import cl from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { LinkTo } from '../../../helpers';

class Header extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    userEmail: PropTypes.string,
    userName: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      subdomain: PropTypes.string
    }),
    withoutOrganizations: PropTypes.bool,
    onLogout: PropTypes.func.isRequired
  };

  static defaultProps = {
    isAuthenticated: false,
    userEmail: null,
    userName: null,
    organization: null,
    withoutOrganizations: false
  };

  state = {
    collapsed: false,
    showProfile: false
  };

  toggleCollapsed() {
    const { collapsed } = this.state;

    this.setState({ collapsed: !collapsed }, () => document.body.classList.toggle('mini-sidebar', !collapsed));
  }

  handleClickOutside() {
    this.setState({ showProfile: false });
  }

  render() {
    const {
      isAuthenticated,
      userEmail,
      userName,
      organization,
      withoutOrganizations,
      onLogout
    } = this.props;
    const { collapsed, showProfile } = this.state;

    return (
      <header className="topbar">
        <nav className="navbar top-navbar navbar-toggleable-sm navbar-light">
          <div className="navbar-header">
            <LinkTo className="navbar-brand" href="">
              <b>
                <i className="fa fa-phone" />{' '}
                {collapsed ? null : 'ContactFrame'}
              </b>
            </LinkTo>
          </div>

          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto mt-md-0">
              <li className="nav-item">
                <LinkTo
                  pureLink
                  href="#"
                  className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
                >
                  <i className="ti-menu" />
                </LinkTo>
              </li>
              <li className="nav-item">
                <LinkTo
                  pureLink
                  href="#"
                  onClick={() => this.toggleCollapsed()}
                  className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"
                >
                  <i className={cl('icon-arrow-left-circle', { 'ti-menu': collapsed })} />
                </LinkTo>
              </li>
              <li className="nav-item">
                {withoutOrganizations ? null : (
                  <LinkTo
                    href="organizations"
                    className="nav-link text-muted waves-effect waves-dark"
                  >
                    <i className="mdi mdi-border-all" /> Organizations
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    href={`organizations/${get(organization, 'attributes.subdomain')}/users`}
                    className="nav-link text-muted waves-effect waves-dark"
                  >
                    <i className="mdi mdi-border-all" /> Users
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/callFlows`}
                  >
                    <i className="mdi mdi-view-grid" /> Call Flows
                  </LinkTo>
                )}

                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/callQueues`}
                  >
                    <i className="mdi mdi-view-grid" /> Call Queues
                  </LinkTo>
                )}

                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/ringGroups`}
                  >
                    <i className="mdi mdi-contacts" /> Ring Groups
                  </LinkTo>
                )}

                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/phoneNumbers`}
                  >
                    <i className="mdi mdi-border-all" /> Phone numbers
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/calls`}
                  >
                    <i className="mdi mdi-table-large" /> Calls History
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/calls/live`}
                  >
                    <i className="mdi mdi-table-large" /> Live
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/voicemails`}
                  >
                    <i className="mdi mdi-border-all" /> Voicemails
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/favorites`}
                  >
                    <i className="mdi mdi-border-all" /> Favorites
                  </LinkTo>
                )}

                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/blacklists`}
                  >
                    <i className="mdi mdi-border-all" /> Blacklists
                  </LinkTo>
                )}
                {isEmpty(organization) ? null : (
                  <LinkTo
                    className="nav-link text-muted waves-effect waves-dark"
                    href={`organizations/${get(organization, 'attributes.subdomain')}/messages`}
                  >
                    <i className="mdi mdi-audiobook" /> Messages
                  </LinkTo>
                )}
                {/**

                 {isEmpty(organization) ? null : (
                   <LinkTo
                     className="nav-link text-muted waves-effect waves-dark"
                     href={`organizations/${get(organization, 'attributes.subdomain')}/logs`}
                   >
                     <i className="mdi mdi-border-all" /> Logs
                   </LinkTo>
                 )}

                 {isEmpty(organization) ? null : (
                   <LinkTo
                     className="nav-link text-muted waves-effect waves-dark"
                     href={`organizations/${get(organization, 'attributes.subdomain')}/changeSets`}
                   >
                     <i className="mdi mdi-border-all" /> Changesets
                   </LinkTo>
                 )}
                 */}
              </li>
            </ul>

            {isAuthenticated ? (
              <ul className="navbar-nav my-lg-0">
                <li className={cl('nav-item dropdown', { show: showProfile })}>
                  <LinkTo
                    pureLink
                    className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                    href="#"
                    onClick={() => this.setState({ showProfile: !showProfile })}
                  >
                    <i className="mdi mdi-account-circle" /> {userName}
                  </LinkTo>
                  <div className="dropdown-menu dropdown-menu-right animated flipInY">
                    <ul className="dropdown-user">
                      <li>
                        <div className="dw-user-box">
                          <div className="u-text">
                            <p className="text-muted">{userEmail}</p>
                          </div>
                        </div>
                      </li>
                      <li className="divider" />
                      <li>
                        <LinkTo pureLink href="#" onClick={() => onLogout()}>
                          <i className="fa fa-power-off" /> Logout
                        </LinkTo>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            ) : null}
          </div>
        </nav>
      </header>
    );
  }
}

export default enhanceWithClickOutside(Header);
