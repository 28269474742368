import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Button } from '../helpers';

function PopoverListButton({ data, onClick }) {
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={(
        <Popover id="popover-list-button">
          {data.map(({ value, label }) => (
            <Button onClick={() => onClick(value)}>{label}</Button>
          ))}
        </Popover>
      )}
    >
      <Button color="secondary" icon="chevron-down" />
    </OverlayTrigger>
  );
}

PopoverListButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  onClick: PropTypes.func.isRequired
};

PopoverListButton.defaultProps = {
  data: []
};

export default PopoverListButton;
