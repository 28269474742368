import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { DELETE_RING_GROUP_START, DELETE_RING_GROUP_SUCCESS, DELETE_RING_GROUP_ERROR } from '../../constants/RingGroup';

// deleteRingGroup

export function deleteRingGroupStart() {
  return {
    type: DELETE_RING_GROUP_START
  };
}

export function deleteRingGroupSuccess(item = {}) {
  return {
    type: DELETE_RING_GROUP_SUCCESS,
    item
  };
}

export function deleteRingGroupError(error) {
  return {
    type: DELETE_RING_GROUP_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteRingGroup(organizationId, ringGroupId) {
  return dispatch => {
    dispatch(deleteRingGroupStart());

    return apiRequest
      .delete(`organizations/${organizationId}/ring_groups/${ringGroupId}`)
      .then(() => Promise.resolve(dispatch(deleteRingGroupSuccess())))
      .catch(error => Promise.reject(dispatch(deleteRingGroupError(error))));
  };
}
