import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchOrganization } from "../../../actions/OrganizationActions";
import {
  fetchFavorites,
  deleteFavorite
} from "../../../actions/FavoriteActions";

import { FavoritesTable } from "../../../components";
import { LinkTo, Loading } from "../../../helpers";

class FavoritesIndexPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    meta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    itemsFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchFavorites: PropTypes.func.isRequired,
    deleteFavorite: PropTypes.func
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchFavorites(organizationId, { page });
  }

  render() {
    const {
      match: { params: { organizationId } },
      items,
      itemsFetched,
      meta
    } = this.props;

    return (
      <div id="favorites">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Favorites</h3>
          </div>
          <div className="col-md-6 col-4 align-self-center">
            <LinkTo
              button
              href={`organizations/${organizationId}/favorites/new`}
              color="success"
              addClass="pull-right hidden-sm-down"
            >
              <i className="mdi mdi-plus-circle" /> Create Favorite
            </LinkTo>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemsFetched}>
                  <FavoritesTable
                    organizationId={organizationId}
                    items={items}
                    meta={meta}
                    onPaginate={page => this.fetchData(page)}
                    onDelete={id =>
                      this.props
                        .deleteFavorite(organizationId, id)
                        .then(data => this.fetchData())}
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ favorites: { items, itemsFetched, meta } }) => ({
    items,
    itemsFetched,
    meta
  }),
  { fetchOrganization, fetchFavorites, deleteFavorite }
)(FavoritesIndexPage);
