import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cl from 'classnames';

function Footer({ center }) {
  return (
    <footer className={cl('footer', { 'text-center': center })}>
      © {moment().format('YYYY')} ContactFrame Admin
    </footer>
  );
}

Footer.propTypes = {
  center: PropTypes.bool
};

Footer.defaultProps = {
  center: false
};

export default Footer;
