import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';

import {
  Button,
  renderField,
  renderDropdownList,
  renderTextareaField,
  AWSAudioPlayer,
} from '../../../../../helpers';
import { actionTypes } from '../../utils';

function QueueForm({
  item,
  entries,
  callQueues,
  messages,
  activeMessage,
  errorMessage,
  afterChange,
  onScrollToAction,
  handleSubmit,
  onSubmit,
}) {
  return (
    <form onSubmit={handleSubmit(values => onSubmit(values))}>
      <div className="row">
        <div className="col-12">
          {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Field
            name="name"
            label="Name"
            component={renderField}
            afterChange={value => afterChange('name', value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <Field
            name="type"
            label="Type"
            component={renderDropdownList}
            data={actionTypes}
            afterChange={value => afterChange('type', value)}
          />
        </div>
        <div className="col-8">
          <Field
            name="call_queue_id"
            label="Destination Queue"
            component={renderDropdownList}
            data={callQueues}
            afterChange={value => afterChange('call_queue_id', value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Field
            name="message"
            label="Message (text to speech)"
            info="If no message is set, the call will be transferred immediately"
            component={renderTextareaField}
            afterChange={value => afterChange('message', value)}
          />
          <div className="form-group">
            <Field
              name="message_id"
              label="Select Prerecorded Message"
              component={renderDropdownList}
              className="col-sm-8"
              data={messages}
              afterChange={value => afterChange('message_id', value)}
            />
            {activeMessage
            && get(activeMessage, 'attributes.file_url')
            && get(activeMessage, 'attributes.type') === 'audio' ? (
              <AWSAudioPlayer
                streamUrl={get(activeMessage, 'attributes.file_url')}
                trackTitle={get(activeMessage, 'attributes.name')}
                preloadType="metadata"
              />
              ) : null}
          </div>
        </div>
      </div>

      <div className="row m-t-20">
        <div className="col-2">Next Action?</div>
        <div className="col-8">
          <Field
            name="next"
            placeholder="Next Action?"
            component={renderDropdownList}
            data={filter(entries, ({ id }) => id !== item.id).map(({ id: value, name }) => ({
              value,
              label: compact([name, `ID: ${value}`]).join(' | '),
            }))}
            afterChange={value => afterChange('next', value)}
          />
        </div>
        <div className="col-2 text-right">
          {isNumber(item.next) ? (
            <Button color="secondary" onClick={() => onScrollToAction(item.next)}>
              View Action
            </Button>
          ) : null}
        </div>
      </div>
    </form>
  );
}

QueueForm.propTypes = {
  item: PropTypes.shape({
    next: PropTypes.number,
  }).isRequired,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  callQueues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  errorMessage: PropTypes.string,
  afterChange: PropTypes.func.isRequired,
  onScrollToAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

QueueForm.defaultProps = {
  errorMessage: null,
  onSubmit: null,
};

function validate({
  name, type, call_queue_id: callQueueId, message, next,
}) {
  const errors = {};

  if (!name) {
    errors.name = 'Please, enter a name';
  }

  if (!type) {
    errors.type = 'Please, select type';
  }

  if (!message) {
    errors.message = 'Please, enter message';
  }

  if (!callQueueId) {
    errors.call_queue_id = 'Please, select destination queue';
  }

  if (!next) {
    errors.next = 'Please, select next action';
  }

  return errors;
}

export default reduxForm({ form: 'queue', validate })(QueueForm);
