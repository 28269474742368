import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
} from '../../constants/User';

// createUser

export function createUserStart() {
  return {
    type: CREATE_USER_START,
  };
}

export function createUserSuccess(item = {}) {
  return {
    type: CREATE_USER_SUCCESS,
    item,
  };
}

export function createUserError(error) {
  return {
    type: CREATE_USER_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function createUser(organizationId, values) {
  return dispatch => {
    dispatch(createUserStart());
    
    return apiRequest
      .post(`organizations/${organizationId}/users`, values)
      .then(({ data }) => {
        dispatch(createUserSuccess(data));
      })
      .catch(error => dispatch(createUserError(error)));
  };
}
