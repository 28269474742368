import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { ErrorMessage, Button, LinkTo } from '../../../helpers';

function MessageDelete({ organizationId, item, errorMessage, isLoading, onDelete }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-danger">
          You are about to delete:&nbsp;
          <LinkTo href={`organizations/${organizationId}/messages${item.id}/show`}>
            {get(item, 'attributes.name')}
          </LinkTo>
          .&nbsp;
          Are you sure?
        </div>

        <ErrorMessage>{errorMessage}</ErrorMessage>

        <div className="btn-group">
          <LinkTo button disabled={isLoading} icon="times-circle" color="secondary" href="organizations">
            Cancel
          </LinkTo>
          <Button disabled={isLoading} icon="trash" color="danger" href="organizations" onClick={() => onDelete(item)}>
            {isLoading ? 'Processing...' : 'Yes, Delete'}
          </Button>
        </div>
      </div>
    </div>
  );
}

MessageDelete.propTypes = {
  organizationId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
};

MessageDelete.defaultProps = {
  errorMessage: null
};

export default MessageDelete;
