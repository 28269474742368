import { LOCATION_CHANGE } from 'connected-react-router';

import {
  FETCH_CALLS_START,
  FETCH_CALLS_SUCCESS,
  FETCH_CALLS_ERROR,
  MONITOR_CALL_START,
  MONITOR_CALL_ERROR,
  MONITOR_CALL_SUCCESS,
  REMOVE_ACTIVE_CALL,
  STOP_MONITORING_CALL_START,
  STOP_MONITORING_CALL_SUCCESS,
  STOP_MONITORING_CALL_ERROR,
  MONITOR_SPEAK_CALL_START,
  MONITOR_SPEAK_CALL_SUCCESS,
  MONITOR_SPEAK_CALL_ERROR,
  MONITOR_WHISPER_CALL_START,
  MONITOR_WHISPER_CALL_SUCCESS,
  MONITOR_WHISPER_CALL_ERROR,
} from '../constants/Call';

const defaultState = {
  path: null,

  data: {},
  isLoading: false,
  errorMessage: null,
  isMonitoring: false,
  monitoringMode: null,

  items: [],
  itemsMeta: { page: 1, pageSize: 10 },
  itemsFetched: false,
  itemsLoading: false,
  itemsErrorMessage: null,
};

export default function CallReducer(
  state = defaultState,
  { type, data, payload, items, meta, errorMessage }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case FETCH_CALLS_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case FETCH_CALLS_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsErrorMessage: null,
        itemsFetched: true,
        items,
        itemsMeta: meta,
      };

    case FETCH_CALLS_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: true,
        itemsErrorMessage: errorMessage,
      };

    case REMOVE_ACTIVE_CALL:
      return {
        ...state,
        data: null,
        errorMessage: null,
        isLoading: false,
        isMonitoring: false,
        monitoringMode: null,
      };

    case MONITOR_CALL_START:
      return {
        ...state,
        isLoading: true,
      };

    case MONITOR_CALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isMonitoring: true,
        monitoringMode: 'mute',
        data,
      };

    case MONITOR_CALL_ERROR:
      return {
        ...state,
        isLoading: false,
        isMonitoring: false,
        errorMessage,
      };

    case MONITOR_SPEAK_CALL_START:
      return {
        ...state,
        isLoading: true,
      };

    case MONITOR_SPEAK_CALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isMonitoring: true,
        monitoringMode: 'speak',
        data,
      };

    case MONITOR_SPEAK_CALL_ERROR:
      return {
        ...state,
        isLoading: false,
        isMonitoring: false,
        errorMessage,
      };

    case MONITOR_WHISPER_CALL_START:
      return {
        ...state,
        isLoading: true,
      };

    case MONITOR_WHISPER_CALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        isMonitoring: true,
        monitoringMode: 'whisper',
        data,
      };

    case MONITOR_WHISPER_CALL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage,
      };

    case STOP_MONITORING_CALL_SUCCESS:
      return {
        ...state,
        data: null,
        errorMessage: null,
        isLoading: false,
        isMonitoring: false,
        monitoringMode: null,
      };

    case STOP_MONITORING_CALL_START:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_MONITORING_CALL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage,
      };

    default:
      return state;
  }
}
