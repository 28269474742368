import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  UPDATE_PHONE_NUMBER_START,
  UPDATE_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_NUMBER_ERROR,
} from '../../constants/PhoneNumber';

// updatePhoneNumber

export function updatePhoneNumberStart() {
  return {
    type: UPDATE_PHONE_NUMBER_START,
  };
}

export function updatePhoneNumberSuccess(item = {}) {
  return {
    type: UPDATE_PHONE_NUMBER_SUCCESS,
    item,
  };
}

export function updatePhoneNumberError(error) {
  return {
    type: UPDATE_PHONE_NUMBER_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function updatePhoneNumber(
  organizationId,
  phoneNumberId,
  values
) {
  return dispatch => {
    dispatch(updatePhoneNumberStart());
    return apiRequest
      .put(
        `organizations/${organizationId}/phone_numbers/${phoneNumberId}`,
        values
      )
      .then(({ data }) => {
        dispatch(updatePhoneNumberSuccess(data));
        dispatch(push(`/organizations/${organizationId}/phoneNumbers`));
      })
      .catch(error => dispatch(updatePhoneNumberError(error)));
  };
}
