import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import momentLocalizer from 'react-widgets/lib/localizers/moment';
import cl from 'classnames';

momentLocalizer(moment);

function renderDateTimePicker({ input, label, meta: { touched, error, warning } }) {
  return (
    <fieldset className={cl('form-group', { 'has-error': (touched && error) })}>
      {label ? <label htmlFor={`form-input-${input.name}`}>{label}</label> : null}
      <DateTimePicker
        time
        id={`form-input-${input.name}`}
        {...input}
        onChange={(_, value) => input.onChange(value)}
        format={'MM/DD/YYYY HH:mm'}
        onBlur={() => input.onBlur()}
        value={input.value ? moment(input.value, 'MM/DD/YYYY HH:mm').toDate() : null}
      />
      {touched && ((error && <div className="error help-block">{error}</div>) || (warning && <div className="error">{warning}</div>))}
    </fieldset>
  );
}

renderDateTimePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string
};

renderDateTimePicker.defaultProps = {
  label: null,
  type: 'text'
};

export default renderDateTimePicker;
