import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  fetchOrganization,
  updateOrganization as updateOrganizationAction
} from '../../../actions/OrganizationActions';

import { OrganizationForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class OrganizationEditPage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    updateOrganization: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null
  };

  componentDidMount() {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchOrganization(organizationId);
  }

  render() {
    const { match: { params: { organizationId } }, item, itemFetched, errorMessage, updateOrganization } = this.props;

    return (
      <div className="row" id="organization-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">
                {isEmpty(item) ? 'Organization' : `Organization: ${item.id} | ${get(item, 'attributes.name')}`}
              </h3>
            </div>
          </div>

          <CrudTabs model="organizations" id={organizationId} active="edit" />

          <div className="card">
            <div className="card-block">
              <Loading loaded={itemFetched}>
                <OrganizationForm
                  item={item}
                  errorMessage={errorMessage}
                  onSubmit={values => updateOrganization(organizationId, values)}
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  organizations: {
    item,
    itemFetched,
    errorMessage
  }
}) => ({
  item,
  itemFetched,
  errorMessage
}), { fetchOrganization, updateOrganization: updateOrganizationAction })(OrganizationEditPage);
