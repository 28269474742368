import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_PHONE_NUMBERS_START,
  ADD_PHONE_NUMBERS_SUCCESS,
  ADD_PHONE_NUMBERS_ERROR,
  ADD_AVAILABLE_PHONE_NUMBERS_START,
  ADD_AVAILABLE_PHONE_NUMBERS_SUCCESS,
  ADD_AVAILABLE_PHONE_NUMBERS_ERROR,
  ADD_CAMPAIGN_PHONE_NUMBERS_START,
  ADD_CAMPAIGN_PHONE_NUMBERS_SUCCESS,
  ADD_CAMPAIGN_PHONE_NUMBERS_ERROR,
  SHOW_PHONE_NUMBER_START,
  SHOW_PHONE_NUMBER_SUCCESS,
  SHOW_PHONE_NUMBER_ERROR,
  ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_START,
  ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_SUCCESS,
  ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_ERROR,
  BUY_PHONE_NUMBER_START,
  BUY_PHONE_NUMBER_SUCCESS,
  BUY_PHONE_NUMBER_ERROR,
  DELETE_PHONE_NUMBER_START,
  DELETE_PHONE_NUMBER_SUCCESS,
  DELETE_PHONE_NUMBER_ERROR,
} from '../constants/PhoneNumber';

const defaultState = {
  path: null,
  isLoading: false,
  errorMessage: null,

  items: [],
  itemsFetched: false,
  itemsLoading: false,
  itemsErrorMessage: null,
  meta: { page: 1, pageSize: 10 },

  item: {},
  itemFetched: false,
  itemLoading: false,
  itemErrorMessage: null,

  campaignItems: [],
  campaignItemsLoading: false,
  campaignItemsFetched: false,

  availableItemsIsLoading: false,
  availableItemsFetched: false,
  availableItems: [],
  availableItemsErrorMessage: null,
};

export default function PhoneNumberReducer(
  state = defaultState,
  { type, payload, items, meta, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case ADD_PHONE_NUMBERS_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case ADD_PHONE_NUMBERS_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: true,
        itemsErrorMessage: null,
        items,
        meta,
      };

    case ADD_PHONE_NUMBERS_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: true,
        itemsErrorMessage: errorMessage,
      };

    case ADD_AVAILABLE_PHONE_NUMBERS_START:
      return {
        ...state,
        availableItemsIsLoading: true,
        availableItemsFetched: false,
      };

    case ADD_AVAILABLE_PHONE_NUMBERS_SUCCESS:
      return {
        ...state,
        availableItemsIsLoading: false,
        availableItemsFetched: true,
        availableItemsErrorMessage: null,
        availableItems: items,
      };

    case ADD_AVAILABLE_PHONE_NUMBERS_ERROR:
      return {
        ...state,
        availableItemsIsLoading: false,
        availableItemsFetched: true,
        availableItemsErrorMessage: errorMessage,
      };

    case ADD_CAMPAIGN_PHONE_NUMBERS_START:
      return {
        ...state,
        campaignItemsLoading: true,
        campaignItemsFetched: false,
      };

    case ADD_CAMPAIGN_PHONE_NUMBERS_SUCCESS:
      return {
        ...state,
        campaignItemsLoading: false,
        campaignItemsFetched: true,
        campaignItems: items,
      };

    case ADD_CAMPAIGN_PHONE_NUMBERS_ERROR:
      return {
        ...state,
        campaignItemsLoading: false,
        campaignItemsFetched: true,
        errorMessage,
      };

    case SHOW_PHONE_NUMBER_START:
      return {
        ...state,
        itemLoading: true,
        itemFetched: false,
      };

    case SHOW_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        itemErrorMessage: null,
        item,
      };

    case SHOW_PHONE_NUMBER_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        itemErrorMessage: errorMessage,
      };

    case ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_START:
      return {
        ...state,
        isLoading: true,
      };

    case ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ATTACH_PHONE_NUMBERS_TO_CAMPAIGN_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage,
      };

    case BUY_PHONE_NUMBER_START:
      return {
        ...state,
        isLoading: true,
      };

    case BUY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case BUY_PHONE_NUMBER_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage,
      };

    case DELETE_PHONE_NUMBER_START:
      return {
        ...state,
        itemLoading: true,
      };

    case DELETE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item: {},
      };

    case DELETE_PHONE_NUMBER_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    default:
      return state;
  }
}
