export const FETCH_CALLS_START = 'FETCH_CALLS_START';
export const FETCH_CALLS_SUCCESS = 'FETCH_CALLS_SUCCESS';
export const FETCH_CALLS_ERROR = 'FETCH_CALLS_ERROR';

export const FETCH_LIVE_CALLS_START = 'FETCH_LIVE_CALLS_START';
export const FETCH_LIVE_CALLS_SUCCESS = 'FETCH_LIVE_CALLS_SUCCESS';
export const FETCH_LIVE_CALLS_ERROR = 'FETCH_LIVE_CALLS_ERROR';

export const SUBSCRIBE_TO_LIVE_CALLS_START = 'SUBSCRIBE_TO_LIVE_CALLS_START';
export const SUBSCRIBE_TO_LIVE_CALLS_SUCCESS = 'SUBSCRIBE_TO_LIVE_CALLS_SUCCESS';
export const SUBSCRIBE_TO_LIVE_CALLS_ERROR = 'SUBSCRIBE_TO_LIVE_CALLS_ERROR';

export const ADD_LIVE_CALL = 'ADD_LIVE_CALL';
export const REMOVE_LIVE_CALL = 'REMOVE_LIVE_CALL';

export const TERMINATE_CALL_START = 'TERMINATE_CALL_START';
export const TERMINATE_CALL_SUCCESS = 'TERMINATE_CALL_SUCCESS';
export const TERMINATE_CALL_ERROR = 'TERMINATE_CALL_ERROR';

export const REMOVE_ACTIVE_CALL = 'REMOVE_ACTIVE_CALL';

export const MONITOR_CALL_START = 'MONITOR_CALL_START';
export const MONITOR_CALL_SUCCESS = 'MONITOR_CALL_SUCCESS';
export const MONITOR_CALL_ERROR = 'MONITOR_CALL_ERROR';

export const MONITOR_WHISPER_CALL_START = 'MONITOR_WHISPER_CALL_START';
export const MONITOR_WHISPER_CALL_SUCCESS = 'MONITOR_WHISPER_CALL_SUCCESS';
export const MONITOR_WHISPER_CALL_ERROR = 'MONITOR_WHISPER_CALL_ERROR';

export const MONITOR_SPEAK_CALL_START = 'MONITOR_SPEAK_CALL_START';
export const MONITOR_SPEAK_CALL_SUCCESS = 'MONITOR_SPEAK_CALL_SUCCESS';
export const MONITOR_SPEAK_CALL_ERROR = 'MONITOR_SPEAK_CALL_ERROR';

export const STOP_MONITORING_CALL = 'STOP_MONITORING_CALL';

export const STOP_MONITORING_CALL_START = 'STOP_MONITORING_CALL_START';
export const STOP_MONITORING_CALL_SUCCESS = 'STOP_MONITORING_CALL_SUCCESS';
export const STOP_MONITORING_CALL_ERROR = 'STOP_MONITORING_CALL_ERROR';
