import Promise from 'bluebird';
import get from 'lodash/get';
import filter from 'lodash/filter';

import { apiRequest } from '../../utils';

import {
  FETCH_LIVE_CALLS_START,
  FETCH_LIVE_CALLS_SUCCESS,
  FETCH_LIVE_CALLS_ERROR
} from '../../constants/Call';

// fetchLiveCalls

export function fetchLiveCallsStart() {
  return {
    type: FETCH_LIVE_CALLS_START
  };
}

export function fetchLiveCallsSuccess(items = [], meta = {}) {
  return {
    type: FETCH_LIVE_CALLS_SUCCESS,
    items,
    meta
  };
}

export function fetchLiveCallsError(error) {
  return {
    type: FETCH_LIVE_CALLS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchLiveCalls(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(fetchLiveCallsStart());

    const today = new Date();
    today.setHours(0, 0, 0);

    return apiRequest.get(`organizations/${organizationId}/calls/live`, { page, pageSize })
      .then(({ data: { data: items } }) => Promise.resolve(dispatch(fetchLiveCallsSuccess(filter(items, function (i) {
        return new Date(i.attributes.started_at) >= today;
      }), { page, pageSize }))))
      .catch(error => Promise.reject(dispatch(fetchLiveCallsError(error))));
  };
}
