import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';

import {
  fetchUsers,
  createUser,
  remoteSubmitUserForm as remoteSubmitUserFormAction
} from '../../../actions/UserActions';

import { UsersTable, UserFormModal } from '../../../components';
import { Loading } from '../../../helpers';

class UsersIndexPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    meta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    itemsFetched: PropTypes.bool.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    remoteSubmitUserForm: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    errorMessage: PropTypes.string
  };

  static defaultProps = {
    errorMessage: null
  }

  state = {
    visible: false
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = values => {
    const {
      match: {
        params: { organizationId }
      }
    } = this.props;

    this.props
      .createUser(organizationId, values)
      .then(() => this.setState({ visible: false }))
      .then(() => this.fetchData());
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const {
      match: {
        params: { organizationId }
      }
    } = this.props;

    this.props.fetchUsers(organizationId, { page });
  }

  render() {
    const {
      match: {
        params: { organizationId }
      },
      items,
      itemsFetched,
      meta,
      errorMessage
    } = this.props;

    return (
      <div id="users">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Users</h3>
          </div>
          <div className="col-md-6 col-4 align-self-center">
            <Button onClick={this.showModal} icon="user-add">
              Create User
            </Button>
          </div>
        </div>
        <UserFormModal
          isNew
          organizationId={organizationId}
          errorMessage={errorMessage}
          visible={this.state.visible}
          onSubmit={this.handleCreate}
          onCancel={this.handleCancel}
          onCreate={() => this.props.remoteSubmitUserForm()}
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemsFetched}>
                  <UsersTable
                    organizationId={organizationId}
                    items={items}
                    meta={meta}
                    onPaginate={page => this.fetchData(page)}
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ users: { items, itemsFetched, meta, errorMessage } }) => ({
    items,
    itemsFetched,
    meta,
    errorMessage
  }),
  { createUser, fetchUsers, remoteSubmitUserForm: remoteSubmitUserFormAction }
)(UsersIndexPage);
