import { LOGOUT_USER } from '../../constants/Auth';

// logoutUser

export default function logoutUser() {
  localStorage.removeItem('username');
  localStorage.removeItem('token_type');
  localStorage.removeItem('access_token');

  return {
    type: LOGOUT_USER
  };
}
