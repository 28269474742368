import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { Button } from '../helpers';

class renderHiddenField extends Component {

  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    withoutFormGroup: PropTypes.bool,
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
      warning: PropTypes.string
    }).isRequired,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    addon: PropTypes.string,
    tooltip: PropTypes.string,
    material: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    afterChange: PropTypes.func
  };

  static defaultProps = {
    className: null,
    inputClassName: null,
    withoutFormGroup: false,
    label: null,
    type: 'text',
    placeholder: null,
    prefix: null,
    addon: null,
    tooltip: null,
    material: null,
    required: null,
    disabled: null,
    afterChange: null,
  };

  state = {
    hidden: true
  };

  render() {
    const {
      className, inputClassName, withoutFormGroup,
      input, type, label, placeholder, prefix, addon, tooltip, material, required, disabled,
      meta: { touched, error, warning },
      afterChange
    } = this.props;
    const { hidden } = this.state;

    const inputView = (
      <input
        id={`form-input-${input.name}`}
        className={cl(inputClassName || 'form-control', {
          'has-feedback': touched && error,
          'form-control-success': !material && touched && !error && !warning,
          'form-control-danger': !material && touched && error,
          'form-control-warning': !material && touched && warning
        })}
        {...input}
        readOnly={hidden}
        value={hidden
          ? `${input.value.substr(0, 5)}******${input.value.substr(input.value.length - 6, input.value.length - 1)}`
          : input.value}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        onChange={({ target: { value } }) => {
          input.onChange(value === '' ? null : value);
          if (afterChange) {
            afterChange(value === '' ? null : value);
          }
        }}
      />
    );

    if (material) {
      return (
        <div className={cl('form-group m-b-40', {
          error: touched && error,
          'has-error': touched && error,
          warning: touched && warning,
          'has-warning': touched && warning
        })}
        >
          {inputView}
          <span className="bar" />
          <label htmlFor={input.name}>{label}</label>
          {touched && (error && <div className="error help-block"><ul><li>{error}</li></ul></div>)}
          {touched && (warning && <div className="text-warning help-block">{warning}</div>)}
        </div>
      );
    }

    if (withoutFormGroup) {
      return inputView;
    }

    return (
      <div
        className={cl(className || 'form-group', {
          'has-success': touched && !error && !warning,
          'has-danger': touched && error,
          'has-warning': touched && warning
        })}
      >
        {label ? (
          <label className="form-control-label" htmlFor={`form-input-${input.name}`}>
            {label} {tooltip}
          </label>
        ) : null}
        <div className="input-group">
          {prefix ? <div className="input-group-addon">{prefix}</div> : null}
          {inputView}
          <span className="bar" />
          <Button className="input-group-addon" onClick={() => this.setState({ hidden: !this.state.hidden })}>
            {hidden ? 'Show' : 'Hide'}
          </Button>
          {addon ? <div className="input-group-addon">{addon}</div> : null}
        </div>
        {touched && error && <div className="form-control-feedback">{error}</div>}
        {touched && warning && <div className="form-control-feedback">{warning}</div>}
      </div>
    );
  }
}

export default renderHiddenField;
