import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { ADD_RING_GROUPS_START, ADD_RING_GROUPS_SUCCESS, ADD_RING_GROUPS_ERROR } from '../../constants/RingGroup';

// fetchRingGroups

export function addRingGroupsStart() {
  return {
    type: ADD_RING_GROUPS_START
  };
}

export function addRingGroups(items = [], meta = {}) {
  return {
    type: ADD_RING_GROUPS_SUCCESS,
    items,
    meta
  };
}

export function addRingGroupsError(error) {
  return {
    type: ADD_RING_GROUPS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchRingGroups(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addRingGroupsStart());

    return apiRequest
      .get(`organizations/${organizationId}/ring_groups`, { page, pageSize })
      .then(({ data: { data: items } }) => Promise.resolve(dispatch(addRingGroups(items, { page, pageSize }))))
      .catch(error => Promise.reject(dispatch(addRingGroupsError(error))));
  };
}
