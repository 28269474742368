import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";

import { renderDropdownList, renderDateTimePicker } from "../../../helpers";

import { Row, Col, Button } from "antd";

function VoicemailsFilterForm({ ringGroups, phoneNumbers, agents, callFlows, onClear, onSubmit, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit(values => onSubmit(values))}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            name="status"
            label="Status"
            component={renderDropdownList}
            data={[
              { value: "*", label: "All" },
              { value: "seen", label: "Seen" },
              { value: "unseen", label: "Unseen" },
              { value: "archived", label: "Archived" }
            ]}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            name="before"
            label="Before"
            component={renderDateTimePicker}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field name="after" label="After" component={renderDateTimePicker} />
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            name="to_phone_number"
            label="Inbound Number"
            component={renderDropdownList}
            data={[{ value: "", label: "All" }, ...phoneNumbers]}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            name="to_username"
            label="Assigned To Agent"
            component={renderDropdownList}
            data={[{ value: "", label: "All" }, ...agents]}
          />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            name="ring_group"
            label="Ring Group"
            component={renderDropdownList}
            data={[{ value: "", label: "All" }, ...ringGroups]}
          />
        </Col>
      </Row>
      <Row>
      <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Field
            name="to_call_flow_id"
            label="Call Flow"
            component={renderDropdownList}
            data={[{ value: "", label: "All" }, ...callFlows]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
        <Button color="secondary" type="primary" htmlType="submit">
            Search
          </Button>
          <Button color="secondary" onClick={() => onClear()}>
            Clear Filters
          </Button>
        </Col>
      </Row>
    </form>
  );
}

VoicemailsFilterForm.propTypes = {
  ringGroups: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isReqiured,
      label: PropTypes.string.isReqiured
    })
  ).isRequired,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isReqiured,
      label: PropTypes.string.isReqiured
    })
  ).isRequired,
  onClear: PropTypes.func.isRequired
};

export default reduxForm({ form: "voicemails-filter" })(VoicemailsFilterForm);
