import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { Button, LinkTo, renderField, renderCheckbox } from '../../../helpers';

const fields = [
  'name',
  'description',
  'subdomain',
  'username',
  'transcriptions_enabled',
  'direct_workflow_timeout',
  'call_recording_enabled',
  'voice_prompt_enabled'
];

class OrganizationForm extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }),
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    isNew: PropTypes.bool
  };

  static defaultProps = {
    item: null,
    errorMessage: null,
    isNew: false
  };

  componentDidMount() {
    const { item, initialize } = this.props;

    if (item) {
      initialize(pick(get(item, 'attributes'), fields));
    }
  }

  render() {
    const { item, errorMessage, handleSubmit, onSubmit, isNew } = this.props;

    return (
      <form onSubmit={handleSubmit(values => onSubmit(values))}>
        <div className="row">
          <div className="col-12">
            <Field name="name" label="Name" component={renderField} />
            <Field name="description" label="Description" component={renderField} />
            <Field name="subdomain" label="Subdomain" component={renderField} disabled={!isNew} />
            {isEmpty(item) ? <Field name="username" label="Username" component={renderField} /> : null}
            <Field name="transcriptions_enabled" label="Transcribe Voicemails?" component={renderCheckbox} />
            <Field name="call_recording_enabled" label="Record calls?" component={renderCheckbox} />
            <Field
              name="voice_prompt_enabled"
              label="Enable voice recognition in prompts? (there is an additional cost charged at $.02 per 15 seconds)"
              component={renderCheckbox}
            />

            <Field name="direct_workflow_timeout" label="Direct Workflow Timeout" component={renderField} />
          </div>
        </div>

        {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}

        <div className="form-group m-t-20">
          <div className="btn-group col-xs-12">
            <LinkTo button color="secondary" icon="times-circle" href="organizations">
              Cancel
            </LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Organization' : 'Create Organization'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

function validate({ name, display_name: displayName, timezone, twilio_auth_token: twilioAuthToken }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please enter a name';
  }

  if (!displayName) {
    errors.display_name = 'Please enter a display name';
  }

  if (!timezone) {
    errors.timezone = 'Please enter a timezone';
  }

  if (!twilioAuthToken) {
    errors.twilio_auth_token = 'Please enter an Twilio Auth Token';
  }

  return errors;
}

export default reduxForm({ form: 'organization', validate })(OrganizationForm);
