import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import compact from 'lodash/compact';
import get from 'lodash/get';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import range from 'lodash/range';
import set from 'lodash/set';
import size from 'lodash/size';

import {
  Button,
  renderField,
  renderDropdownList,
  renderTextareaField,
  LinkTo,
  AWSAudioPlayer
} from '../../../../../helpers';
import { actionTypes } from '../../utils';

function PromptForm({
  item,
  entries,
  errorMessage,
  afterChange,
  onScrollToAction,
  handleSubmit,
  onSubmit,
  messages,
  activeMessage
}) {
  const selectedNumbers = get(item, 'prompt.numbers', []).map((number, index) => get(item, `prompt[number_${index}]`));
  return (
    <form onSubmit={handleSubmit(values => onSubmit(values))}>
      <div className="row">
        <div className="col-12">{errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}</div>
      </div>

      <div className="row">
        <div className="col-12">
          <Field name="name" label="Name" component={renderField} afterChange={value => afterChange('name', value)} />
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <Field
            name="type"
            label="Type"
            component={renderDropdownList}
            data={actionTypes}
            afterChange={value => afterChange('type', value)}
          />
        </div>
        <div className="col-6">
          <Field
            name="message"
            label="Message"
            component={renderTextareaField}
            afterChange={value => afterChange('message', value)}
          />
          <Field
            name="message_id"
            label="Select Prerecorded Message"
            component={renderDropdownList}
            data={messages}
            clearable
            afterChange={value => afterChange('message_id', value)}
          />
          {activeMessage
          && get(activeMessage, 'attributes.file_url')
          && get(activeMessage, 'attributes.type') === 'audio' ? (
            <AWSAudioPlayer
                key={get(activeMessage, 'id')}
                streamUrl={get(activeMessage, 'attributes.file_url')}
                trackTitle={get(activeMessage, 'attributes.name')}
                preloadType="metadata"
              />
            ) : null}
        </div>
        <div className="col-2">
          <Field
            name="timeout"
            label="Timeout"
            type="number"
            min={0}
            component={renderField}
            afterChange={value => afterChange('timeout', value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {get(item, 'prompt.numbers', []).map((number, index) => (
            <div key={index} className="row">
              <div className="col-2">
                <Field
                  name={`prompt[number_${index}]`}
                  placeholder="Digit"
                  component={renderDropdownList}
                  data={filter(
                    range(0, 10),
                    i => !includes(selectedNumbers, `${i}`) || `${i}` === get(item, `prompt[number_${index}]`)
                  ).map(i => ({
                    label: i,
                    value: `${i}`
                  }))}
                  afterChange={value => afterChange(`prompt[number_${index}]`, value)}
                />
              </div>
              <div className="col-4">
                <Field
                  name={`prompt[key_${index}]`}
                  placeholder="Trigger Word"
                  component={renderField}
                  afterChange={value => afterChange(`prompt[key_${index}]`, value)}
                />
              </div>
              <div className="col-5">
                <Field
                  name={`prompt[value_${index}]`}
                  placeholder="Action"
                  component={renderDropdownList}
                  data={entries.map(({ id: value, name }) => ({
                    value,
                    label: compact([name, `ID: ${value}`]).join(' | ')
                  }))}
                  afterChange={value => afterChange(`prompt[value_${index}]`, value)}
                />
              </div>
              <div className="col-1 text-right">
                <Button
                  icon="minus"
                  color="danger"
                  onClick={() => afterChange('prompt', {
                    ...omit(item.prompt || {}, [`key_${number}`, `value_${number}`]),
                    numbers: filter(get(item, 'prompt.numbers', []), promptNumber => promptNumber !== number)
                  })
                  }
                />
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-12">
              <Button
                icon="plus"
                color="success"
                onClick={() => {
                  const number = size(get(item, 'prompt.numbers', [])) + 1;

                  afterChange('prompt', {
                    ...(get(item, 'prompt') || {}),
                    [`number_${number}`]: number,
                    [`key_${number}`]: null,
                    [`value_${number}`]: null,
                    numbers: [...get(item, 'prompt.numbers', []), number]
                  });
                }}
              >
                Add Another
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-t-20">
        <div className="col-2">Next Action?</div>
        <div className="col-8">
          <Field
            name="next"
            placeholder="Next Action?"
            component={renderDropdownList}
            data={filter(entries, ({ id }) => id !== item.id).map(({ id: value, name }) => ({
              value,
              label: compact([name, `ID: ${value}`]).join(' | ')
            }))}
            afterChange={value => afterChange('next', value)}
          />
        </div>
        <div className="col-2 text-right">
          {isNumber(item.next) ? (
            <Button color="secondary" onClick={() => onScrollToAction(item.next)}>
              View Action
            </Button>
          ) : null}
        </div>
      </div>
    </form>
  );
}

PromptForm.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    message: PropTypes.string,
    timeout: PropTypes.number,
    prompt: PropTypes.shape({
      numbers: PropTypes.arrayOf(PropTypes.number)
    }),
    next: PropTypes.number
  }).isRequired,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  errorMessage: PropTypes.string,
  afterChange: PropTypes.func.isRequired,
  onScrollToAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

PromptForm.defaultProps = {
  errorMessage: null,
  onSubmit: null
};

function validate({ name, type, message, message_id: messageId, timeout, prompt, next }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please, enter a name';
  }

  if (!type) {
    errors.type = 'Please, select type';
  }

  if (!message && !messageId) {
    errors.message = 'Please, enter a message or select an existing message';
  }

  if (!timeout) {
    errors.timeout = 'Please, enter timeout';
  }

  get(prompt, 'numbers', []).map(number => {
    if (!get(prompt, `number_${number}`)) {
      set(errors, `prompt.number_${number}`, 'Please, enter a digit');
    }

    if (!get(prompt, `key_${number}`)) {
      set(errors, `prompt.key_${number}`, 'Please, enter a trigger word');
    }

    if (!get(prompt, `value_${number}`)) {
      set(errors, `prompt.value_${number}`, 'Please, select an action');
    }
  });

  if (!next) {
    errors.next = 'Please, select next action';
  }

  return errors;
}

export default reduxForm({ form: 'prompt', validate })(PromptForm);
