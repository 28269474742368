import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_FAVORITES_START,
  ADD_FAVORITES_SUCCESS,
  ADD_FAVORITES_ERROR
} from '../../constants/Favorite';

// fetchUsers

export function addFavoritesStart() {
  return {
    type: ADD_FAVORITES_START
  };
}

export function addFavorites(items = [], meta = {}) {
  return {
    type: ADD_FAVORITES_SUCCESS,
    items,
    meta
  };
}

export function addFavoritesError(error) {
  return {
    type: ADD_FAVORITES_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchFavorites(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addFavoritesStart());
    return apiRequest.get(`organizations/${organizationId}/favorites`, { page, pageSize })
      .then(({ data: { data: items } }) => dispatch(addFavorites(items, {
        page,
        pageSize
      })))
      .catch(error => dispatch(addFavoritesError(error)));
  };
}
