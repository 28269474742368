import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchMessage } from '../../../actions/MessageActions';

import { MessageInfo } from '../../../components';
import { CrudTabs, ErrorMessage, Loading } from '../../../helpers';

class MessageShowPage extends Component {

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,

    organizationFetched: PropTypes.bool.isRequired,
    organizationErrorMessage: PropTypes.string,

    message: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    messageFetched: PropTypes.bool.isRequired,
    messageErrorMessage: PropTypes.string,

    fetchOrganization: PropTypes.func.isRequired,
    fetchMessage: PropTypes.func.isRequired
  };

  static defaultProps = {
    organizationErrorMessage: null,
    messageErrorMessage: null
  };

  componentDidMount() {
    const { match: { params: { organizationId, messageId } } } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchMessage(organizationId, messageId);
  }

  render() {
    const {
      match: { params: { organizationId, messageId } },
      organizationFetched, organizationErrorMessage,
      message, messageFetched, messageErrorMessage
    } = this.props;

    return (
      <div className="row" id="message-show">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Message #{messageId}</h3>
            </div>
          </div>

          <CrudTabs model={`organizations/${organizationId}/messages`} id={messageId} active="show" />

          <div className="card">
            <div className="card-block">
              <ErrorMessage>{organizationErrorMessage || messageErrorMessage}</ErrorMessage>
              <Loading loaded={organizationFetched && messageFetched}>
                <MessageInfo item={message} />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  organizations: {
    itemFetched: organizationFetched,
    itemErrorMessage: organizationErrorMessage
  },
  messages: {
    item: message,
    itemFetched: messageFetched,
    itemErrorMessage: messageErrorMessage
  }
}) => ({
  organizationFetched,
  organizationErrorMessage,

  message,
  messageFetched,
  messageErrorMessage
}), {
  fetchOrganization,
  fetchMessage
})(MessageShowPage);
