import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchCallQueue } from '../../../actions/CallQueueActions';

import { CallQueueInfo } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class CallQueueShowPage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        callQueueId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchCallQueue: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { match: { params: { organizationId, callQueueId } } } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchCallQueue(callQueueId);
  }

  render() {
    const { match: { params: { organizationId, callQueueId } }, item, itemFetched } = this.props;

    return (
      <div id="call-queue-show">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">
              {isEmpty(item) ? 'Call Queue' : `Call Queue: ${item.id} | ${get(item, 'attributes.name')}`}
            </h3>
          </div>
        </div>

        <CrudTabs model={`organizations/${organizationId}/callQueues`} id={callQueueId} active="show" />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <CallQueueInfo item={item} />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  callQueues: {
    item,
    itemFetched
  }
}) => ({ item, itemFetched }), { fetchOrganization, fetchCallQueue })(CallQueueShowPage);
