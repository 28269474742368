import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import RingGroupsIndexPage from './RingGroupsIndexPage/RingGroupsIndexPage';
import RingGroupAddPage from './RingGroupAddPage/RingGroupAddPage';
import RingGroupShowPage from './RingGroupShowPage/RingGroupShowPage';
import RingGroupEditPage from './RingGroupEditPage/RingGroupEditPage';
import RingGroupDeletePage from './RingGroupDeletePage/RingGroupDeletePage';

export default [
  <Route
    exact
    key="organization-ring-groups"
    path="/organizations/:organizationId/ringGroups"
    component={RequireAuth(RingGroupsIndexPage)}
  />,
  <Route
    exact
    key="organization-ring-group-new"
    path="/organizations/:organizationId/ringGroups/new"
    component={RequireAuth(RingGroupAddPage)}
  />,
  <Route
    exact
    key="organization-ring-group-show"
    path="/organizations/:organizationId/ringGroups/:ringGroupTag/show"
    component={RequireAuth(RingGroupShowPage)}
  />,
  <Route
    exact
    key="organization-ring-group-edit"
    path="/organizations/:organizationId/ringGroups/:ringGroupTag/edit"
    component={RequireAuth(RingGroupEditPage)}
  />,
  <Route
    exact
    key="organization-ring-group-delete"
    path="/organizations/:organizationId/ringGroups/:ringGroupTag/delete"
    component={RequireAuth(RingGroupDeletePage)}
  />
];
