import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { UPDATE_CALL_QUEUE_START, UPDATE_CALL_QUEUE_SUCCESS, UPDATE_CALL_QUEUE_ERROR } from '../../constants/CallQueue';

// updateCallQueue

export function updateCallQueueStart() {
  return {
    type: UPDATE_CALL_QUEUE_START
  };
}

export function updateCallQueueSuccess(item = {}) {
  return {
    type: UPDATE_CALL_QUEUE_SUCCESS,
    item
  };
}

export function updateCallQueueError(error) {
  return {
    type: UPDATE_CALL_QUEUE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function updateCallQueue(callQueueId, values) {
  return dispatch => {
    dispatch(updateCallQueueStart());

    return apiRequest
      .put(`call_queues/${callQueueId}`, values)
      .then(({ data }) => Promise.resolve(dispatch(updateCallQueueSuccess(data))))
      .catch(error => Promise.reject(dispatch(updateCallQueueError(error))));
  };
}
