import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest, camelCaseObjectKeys } from '../../utils';

import {
  ADD_VOICEMAILS_START,
  ADD_VOICEMAILS_SUCCESS,
  ADD_VOICEMAILS_ERROR
} from '../../constants/Voicemail';

// fetchVoicemails

export function addVoicemailsStart() {
  return {
    type: ADD_VOICEMAILS_START
  };
}

export function addVoicemails(voicemails = [], meta = {}) {
  return {
    type: ADD_VOICEMAILS_SUCCESS,
    voicemails,
    meta
  };
}

export function addVoicemailsError(error) {
  return {
    type: ADD_VOICEMAILS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchVoicemails(organizationId, { page = 1, pageSize = 50, ...params }) {
  return dispatch => {
    dispatch(addVoicemailsStart());

    return apiRequest.get(`organizations/${organizationId}/voicemails`, { page, pageSize, ...params })
      .then(({ data: { data: voicemails, meta } }) => Promise.resolve(dispatch(addVoicemails(voicemails, {
        page,
        pageSize,
        ...params,
        ...camelCaseObjectKeys(get(meta, 'pagination', {}))
      }))))
      .catch(error => Promise.reject(dispatch(addVoicemailsError(error))));
  };
}
