import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest, camelCaseObjectKeys, formatPhone } from '../../utils';

import { FETCH_CALLS_START, FETCH_CALLS_SUCCESS, FETCH_CALLS_ERROR } from '../../constants/Call';

// fetchCalls

export function fetchCallsStart() {
  return {
    type: FETCH_CALLS_START
  };
}

export function fetchCallsSuccess(items = [], meta = {}) {
  return {
    type: FETCH_CALLS_SUCCESS,
    items,
    meta
  };
}

export function fetchCallsError(error) {
  return {
    type: FETCH_CALLS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchCalls(organizationId, { page = 1, pageSize = 50, ...params }) {
  return dispatch => {
    dispatch(fetchCallsStart());

    return apiRequest
      .get(`organizations/${organizationId}/calls`, {
        ...params,
        ...(params.caller_id ? { caller_id: formatPhone(params.caller_id) } : {}),
        ...(params.to_phone_number ? { to_phone_number: formatPhone(params.to_phone_number) } : {}),
        ...(params.from_phone_number ? { from_phone_number: formatPhone(params.from_phone_number) } : {}),
        page,
        page_size: pageSize
      })
      .then(({ data: { data: items, meta } }) =>
        Promise.resolve(
          dispatch(
            fetchCallsSuccess(items, {
              ...page,
              ...params,
              ...camelCaseObjectKeys(get(meta, 'pagination', {}))
            })
          )
        )
      )
      .catch(error => Promise.reject(dispatch(fetchCallsError(error))));
  };
}
