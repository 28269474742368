import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

function CallQueueInfo({ item }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'attributes.name')}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{get(item, 'attributes.description')}</td>
        </tr>
        <tr>
          <td>Workflow SID</td>
          <td>{get(item, 'attributes.workflow_sid')}</td>
        </tr>
        <tr>
          <td>Business Hours</td>
          <td>{JSON.stringify(get(item, 'attributes.business_hours', {}))}</td>
        </tr>
        <tr>
          <td>Fallthrough Type</td>
          <td>{get(item, 'attributes.fallthrough_type')}</td>
        </tr>
        <tr>
          <td>Redirect Call Queue SID</td>
          <td>{get(item, 'attributes.redirect_call_queue_sid')}</td>
        </tr>
      </tbody>
    </table>
  );
}

CallQueueInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default CallQueueInfo;
