import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import capitalize from 'lodash/capitalize';
import AudioRecorder from 'react-audio-recorder';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

import {
  CancelSubmitButtons,
  ErrorMessage,
  renderField,
  renderTextareaField,
  renderFileField,
  renderDropdownList
} from '../../../helpers';

function MessageForm({
  organizationId,
  formValues,
  item,
  agents,
  isFileLoading,
  isLoading,
  errorMessage,
  onUpload,
  handleSubmit,
  onSubmit
}) {
  return (
    <form
      onSubmit={handleSubmit(values =>
        onSubmit({
          ...omitBy(values, isUndefined),
          message: formValues.type === 'text' ? formValues.message : null,
          file_url: formValues.type === 'audio' ? formValues.file_url : null
        })
      )}
    >
      <div className="row">
        <div className="col-12">
          <Field name="name" label="Name" component={renderField} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Field name="description" label="Description" component={renderTextareaField} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Field
            name="type"
            label="Type"
            component={renderDropdownList}
            data={['text', 'audio'].map(type => ({ value: type, label: capitalize(type) }))}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Field name="owner_username" label="Owner User Role" clearable={true} component={renderDropdownList} data={agents} />
        </div>
      </div>

      {formValues.type === 'text' ? (
        <div className="row">
          <div className="col-12">
            <Field name="message" label="Message" component={renderTextareaField} />
          </div>
        </div>
      ) : null}

      {formValues.type === 'audio' ? (
        <div className="row">
          <div className="col-12">
            <Field
              name="file_url"
              label="File"
              params={{ organization_id: organizationId }}
              component={renderFileField}
              isLoading={isFileLoading}
              onUpload={onUpload}
            />
            <AudioRecorder
              onChange={evt => {
                document.getElementById('audio-duration').innerText =
                  `Recording: ${Math.round(evt.duration * 100) / 100} sec`;
              }}
            />
            <div id="audio-duration" />
          </div>
        </div>
      ) : null}

      <ErrorMessage>{errorMessage}</ErrorMessage>

      <CancelSubmitButtons cancelUrl={`organizations/${organizationId}/messages`} isLoading={isLoading}>
        {item ? 'Update Message' : 'Create Message'}
      </CancelSubmitButtons>
    </form>
  );
}

MessageForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    owner_username: PropTypes.string,
    message: PropTypes.string,
    file_url: PropTypes.string
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number
  }),
  agents: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFileLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

MessageForm.defaultProps = {
  item: null,
  errorMessage: null
};

function validate({ name, type, message, file_url: fileUrl }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please, enter a name';
  }

  if (!type) {
    errors.type = 'Please, select type';
  }

  if (type === 'text' && !message) {
    errors.message = 'Please, enter a message';
  }
  /**
  if (type === 'audio' && !fileUrl) {
    errors.file_url = 'Please, upload file';
  }
* */
  return errors;
}

export default reduxForm({ form: 'message', validate })(MessageForm);
