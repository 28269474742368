import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import pick from 'lodash/pick';
import omit from 'lodash/omit';


import { Button, LinkTo, renderField, renderCheckbox } from '../../../helpers';

const fields = [
  'name',
  'description',
  'application_sid',
  'phone_number',
  'voice_channel',
  'chat_channel',
  'video_channel'
];

class CallFlowForm extends Component {

  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number
    }),
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
  };

  static defaultProps = {
    item: null,
    errorMessage: null
  };

  componentDidMount() {
    const { item, initialize } = this.props;

    if (item) {
      initialize(omit(pick(get(item, 'attributes'), fields), 'phone_number'));
    }
  }

  render() {
    const { organizationId, item, errorMessage, handleSubmit, onSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(values => onSubmit(values))}>

        <div className="row">
          <div className="col-12">
            <Field name="name" label="Name" component={renderField} />
            <Field name="description" label="Description" component={renderField} />
            <Field name="voice_channel" label="Voice Channel" component={renderCheckbox} />
            <Field name="chat_channel" label="Chat Channel" component={renderCheckbox} />
            <Field name="video_channel" label="Video Channel" component={renderCheckbox} />
          </div>
        </div>

        {errorMessage ? (
          <div className="alert alert-danger">
            {errorMessage}
          </div>
        ) : null}

        <div className="form-group m-t-20">
          <div className="btn-group col-xs-12">
            <LinkTo button color="secondary" icon="times-circle" href={`organizations/${organizationId}/callFlows`}>
              Cancel
            </LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Call Flow' : 'Create Call Flow'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

function validate({ name }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please enter a name';
  }

  return errors;
}

export default reduxForm({ form: 'call-flow', validate })(CallFlowForm);
