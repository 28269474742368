export const ADD_RING_GROUPS_START = 'ADD_RING_GROUPS_START';
export const ADD_RING_GROUPS_SUCCESS = 'ADD_RING_GROUPS_SUCCESS';
export const ADD_RING_GROUPS_ERROR = 'ADD_RING_GROUPS_ERROR';

export const SHOW_RING_GROUP_START = 'SHOW_RING_GROUP_START';
export const SHOW_RING_GROUP_SUCCESS = 'SHOW_RING_GROUP_SUCCESS';
export const SHOW_RING_GROUP_ERROR = 'SHOW_RING_GROUP_ERROR';

export const CREATE_RING_GROUP_START = 'CREATE_RING_GROUP_START';
export const CREATE_RING_GROUP_SUCCESS = 'CREATE_RING_GROUP_SUCCESS';
export const CREATE_RING_GROUP_ERROR = 'CREATE_RING_GROUP_ERROR';

export const UPDATE_RING_GROUP_START = 'UPDATE_RING_GROUP_START';
export const UPDATE_RING_GROUP_SUCCESS = 'UPDATE_RING_GROUP_SUCCESS';
export const UPDATE_RING_GROUP_ERROR = 'UPDATE_RING_GROUP_ERROR';

export const DELETE_RING_GROUP_START = 'DELETE_RING_GROUP_START';
export const DELETE_RING_GROUP_SUCCESS = 'DELETE_RING_GROUP_SUCCESS';
export const DELETE_RING_GROUP_ERROR = 'DELETE_RING_GROUP_ERROR';

export const ADD_SUBGROUP_TO_RINGGROUP_START = 'ADD_SUBGROUP_TO_RINGGROUP_START';
export const ADD_SUBGROUP_TO_RINGGROUP_SUCCESS = 'ADD_SUBGROUP_TO_RINGGROUP_SUCCESS';
export const ADD_SUBGROUP_TO_RINGGROUP_ERROR = 'ADD_SUBGROUP_TO_RINGGROUP_ERROR';

export const DELETE_SUBGROUP_FROM_RINGGROUP_START = 'DELETE_SUBGROUP_FROM_RINGGROUP_START';
export const DELETE_SUBGROUP_FROM_RINGGROUP_SUCCESS = 'DELETE_SUBGROUP_FROM_RINGGROUP_SUCCESS';
export const DELETE_SUBGROUP_FROM_RINGGROUP_ERROR = 'DELETE_SUBGROUP_FROM_RINGGROUP_ERROR';
