import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { Button, renderField } from '../helpers';

function SearchForm({ handleSubmit, onSearch }) {
  return (
    <form onSubmit={handleSubmit(({ search }) => onSearch(search))}>
      <div className="input-group">
        <Field withoutFormGroup inputClassName="form-control input-xs" name="search" component={renderField} />

        <div className="input-group-btn">
          <Button submit color="secondary" icon="search" />
        </div>
      </div>
    </form>
  );
}

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
};

function validate({ search }) {
  const errors = {};

  if (!search) {
    errors.search = 'Please enter a value';
  }

  return errors;
}

export default reduxForm({ form: 'search', validate })(SearchForm);
