import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchRingGroups as fetchRingGroupsAction,
  fetchRingGroup as fetchRingGroupAction,
  updateRingGroup as updateRingGroupAction,
  deleteSubGroupFromRingGroup as deleteSubGroupFromRingGroupAction,
} from '../../../actions/RingGroupActions';

import { deleteRingGroupMember as deleteRingGroupMemberAction } from '../../../actions/RingGroupMemberActions';

import { RingGroupForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class RingGroupEditPage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    organization: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        ringGroupTag: PropTypes.string,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    organizationFetched: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    organizationErrorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    fetchRingGroup: PropTypes.func.isRequired,
    updateRingGroup: PropTypes.func.isRequired,
    deleteRingGroupMember: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
    organizationErrorMessage: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const {
      match: {
        params: { organizationId, ringGroupTag },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchRingGroup(organizationId, ringGroupTag);
    this.props.fetchRingGroups(organizationId, { page });
  }

  render() {
    const {
      match: {
        params: { organizationId, ringGroupTag },
      },
      item,
      itemFetched,
      errorMessage,
      organization,
      organizationFetched,
      organizationErrorMessage,
      fetchRingGroup,
      updateRingGroup,
      push,
      deleteRingGroupMember,
      ringGroups,
      ringGroupsFetched,
      deleteSubGroupFromRingGroup,
    } = this.props;

    return (
      <div className="row" id="ring-group-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">
                {isEmpty(item)
                  ? 'Ring Group'
                  : `Ring Group: ${item.id} | ${get(item, 'attributes.name')}`}
              </h3>
            </div>
          </div>

          <CrudTabs
            model={`organizations/${organizationId}/ringGroups`}
            id={ringGroupTag}
            active="edit"
          />

          <div className="card">
            <div className="card-block">
              <Loading
                loaded={itemFetched && organizationFetched && ringGroupsFetched}
              >
                <RingGroupForm
                  organizationId={organizationId}
                  ringGroupTag={ringGroupTag}
                  item={item}
                  ringGroups={ringGroups}
                  organization={organization}
                  errorMessage={errorMessage || organizationErrorMessage}
                  onRemoveMember={username =>
                    deleteRingGroupMember(
                      organizationId,
                      ringGroupTag,
                      username
                    ).then(() =>
                      this.props.fetchRingGroup(organizationId, ringGroupTag)
                    )
                  }
                  onRemoveSubGroup={subGroupTag =>
                    deleteSubGroupFromRingGroup(
                      organizationId,
                      ringGroupTag,
                      subGroupTag
                    ).then(() =>
                      this.props.fetchRingGroup(organizationId, ringGroupTag)
                    )
                  }
                  onRefresh={() => fetchRingGroup(organizationId, ringGroupTag)}
                  onSubmit={values =>
                    updateRingGroup(organizationId, ringGroupTag, values).then(
                      () => {
                        // push(`/organizations/${organizationId}/ringGroups`)
                      }
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    ringGroups: {
      item,
      itemFetched,
      errorMessage,
      itemsFetched: ringGroupsFetched,
      items: ringGroups,
    },
    organizations: {
      item: organization,
      itemFetched: organizationFetched,
      itemErrorMessage: organizationErrorMessage,
    },
  }) => ({
    item,
    itemFetched,
    errorMessage,
    organization,
    organizationFetched,
    organizationErrorMessage,
    ringGroups,
    ringGroupsFetched,
  }),
  {
    fetchOrganization,
    fetchRingGroups: fetchRingGroupsAction,
    deleteRingGroupMember: deleteRingGroupMemberAction,
    fetchRingGroup: fetchRingGroupAction,
    updateRingGroup: updateRingGroupAction,
    deleteSubGroupFromRingGroup: deleteSubGroupFromRingGroupAction,
    push: pushAction,
  }
)(RingGroupEditPage);
