import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_RING_GROUP_MEMBER_START,
  DELETE_RING_GROUP_MEMBER_SUCCESS,
  DELETE_RING_GROUP_MEMBER_ERROR
} from '../../constants/RingGroupMember';

// deleteRingGroupMember

export function deleteRingGroupMemberStart() {
  return {
    type: DELETE_RING_GROUP_MEMBER_START
  };
}

export function deleteRingGroupMemberSuccess(item = {}) {
  return {
    type: DELETE_RING_GROUP_MEMBER_SUCCESS,
    item
  };
}

export function deleteRingGroupMemberError(error) {
  return {
    type: DELETE_RING_GROUP_MEMBER_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteRingGroupMember(organizationId, ringGroupId, username) {
  return dispatch => {
    dispatch(deleteRingGroupMemberStart());

    return apiRequest
      .deleteMany(`organizations/${organizationId}/ring_groups/${ringGroupId}/members/${username}`)
      .then(({ data }) => Promise.resolve(dispatch(deleteRingGroupMemberSuccess(data))))
      .catch(error => Promise.reject(dispatch(deleteRingGroupMemberError(error))));
  };
}
