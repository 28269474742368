import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import get from 'lodash/get';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import first from 'lodash/first';


import { ActionTypeForm, CollapsedForm, PromptForm, MessageForm, QueueForm, VoicemailForm, CallbackForm, ExtensionDirectoryForm  } from './components';
import { Button } from '../../../helpers';

function CallFlowEntries({ entries, callQueues, messages, onCreate, onChange, onCopy, onRemove, onScrollToAction }) {
  return (
    <div className="row">
      <div className="col-12">
        {entries.map((entry, index) => (
          <div key={entry.id} id={entry.id} className="row">
            <div className="col-10">
              <div className="card">
                <div className="card-block">
                  <div className="row">
                    <div className="col-1">
                      {index + 1}
                    </div>
                    <div className="col-11">
                      {entry.expanded ? null : (
                        <CollapsedForm
                          item={entry}
                          entries={entries}
                          form={`collapsed-form-${entry.id}`}
                          initialValues={pick(entry, ['name', 'type', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                        />
                      )}
                      {entry.expanded && entry.type === 'prompt' ? (
                        <PromptForm
                          item={entry}
                          entries={entries}
                          messages={messages.map(message => ({
                            value: message.id,
                            label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`,
                          }))}
                          activeMessage={first(filter(messages, function(m) {
                              return m.id === get(entry, 'message_id')
                          }))}
                          form={`prompt-form-${entry.id}`}
                          initialValues={pick(entry, ['name', 'type', 'message', 'message_id', 'timeout', 'prompt', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                          onScrollToAction={onScrollToAction}
                        />
                      ) : null}
                      {entry.expanded && entry.type === 'message' ? (
                        <MessageForm
                          item={entry}
                          entries={entries}
                          form={`message-form-${entry.id}`}
                          messages={messages.map(message => ({
                            value: message.id,
                            label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`,
                          }))}
                          activeMessage={first(filter(messages, function(m) {
                              return m.id === get(entry, 'message_id')
                          }))}
                          initialValues={pick(entry, ['name', 'type', 'message', 'message_id', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                          onScrollToAction={onScrollToAction}
                        />
                      ) : null}
                      {entry.expanded && entry.type === 'extensions' ? (
                        <ExtensionDirectoryForm
                          item={entry}
                          entries={entries}
                          form={`extensions-form-${entry.id}`}
                          messages={messages.map(message => ({
                            value: message.id,
                            label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`,
                          }))}
                          activeMessage={first(filter(messages, function(m) {
                              return m.id === get(entry, 'message_id')
                          }))}
                          initialValues={pick(entry, ['name', 'type', 'message', 'message_id', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                          onScrollToAction={onScrollToAction}
                        />
                      ) : null}
                      {entry.expanded && entry.type === 'voicemail' ? (
                        <VoicemailForm
                          item={entry}
                          entries={entries}
                          form={`voicemail-form-${entry.id}`}
                          messages={messages.map(message => ({
                            value: message.id,
                            label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`,
                          }))}
                          activeMessage={first(filter(messages, function(m) {
                              return m.id === get(entry, 'message_id')
                          }))}
                          initialValues={pick(entry, ['name', 'type', 'message', 'message_id', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                          onScrollToAction={onScrollToAction}
                        />
                      ) : null}
                      {entry.expanded && entry.type === 'call_queue' ? (
                        <QueueForm
                          item={entry}
                          entries={entries}
                          callQueues={callQueues.map(callQueue => ({
                            value: callQueue.id,
                            label: get(callQueue, 'attributes.name')
                          }))}
                          messages={messages.map(message => ({
                            value: message.id,
                            label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`,
                          }))}
                          activeMessage={first(filter(messages, function(m) {
                              return m.id === get(entry, 'message_id')
                          }))}
                          form={`queue-form-${entry.id}`}
                          initialValues={pick(entry, ['name', 'type', 'message', 'message_id', 'call_queue_id', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                          onScrollToAction={onScrollToAction}
                        />
                      ) : null}
                      {entry.expanded && entry.type === 'callback' ? (
                        <CallbackForm
                          item={entry}
                          entries={entries}
                          callQueues={callQueues.map(callQueue => ({
                            value: callQueue.id,
                            label: get(callQueue, 'attributes.name')
                          }))}
                          messages={messages.map(message => ({
                            value: message.id,
                            label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`
                          }))}
                          form={`queue-form-${entry.id}`}
                          initialValues={pick(entry, ['name', 'type', 'message', 'message_id', 'call_queue_id', 'next'])}
                          afterChange={(name, value) => onChange(entry.id, name, value)}
                          onScrollToAction={onScrollToAction}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="btn-group pull-right">
                <Button
                  color="secondary"
                  onClick={() => onChange(entry.id, 'expanded', !entry.expanded)}
                >
                  <i
                    className={cl('mdi', {
                      'mdi-plus-circle': !entry.expanded,
                      'mdi-minus-circle': entry.expanded
                    })}
                  />
                </Button>
                <Button
                  color="secondary"
                  onClick={() => onCopy(entry)}
                >
                  <i className="mdi mdi-content-copy" />
                </Button>
                <Button
                  color="danger"
                  onClick={() => onRemove(entry)}
                >
                  <i className="mdi mdi-delete-empty" />
                </Button>
              </div>
            </div>
          </div>
        ))}

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <ActionTypeForm onActionChange={onCreate} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CallFlowEntries.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })).isRequired,
  callQueues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  })).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  })).isRequired,
  onCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onScrollToAction: PropTypes.func.isRequired
};

export default CallFlowEntries;
