import Promise from 'bluebird';

import { CHANGE_CALL_FLOW_ENTRIES_SUCCESS } from '../../constants/CallFlowEntry';

// changeCallFlowEntries

export function changeCallFlowEntriesSuccess({ expanded }) {
  return {
    type: CHANGE_CALL_FLOW_ENTRIES_SUCCESS,
    updateValues: { expanded }
  };
}

export default function changeCallFlowEntries({ expanded }) {
  return dispatch => Promise.resolve(dispatch(changeCallFlowEntriesSuccess({ expanded })));
}
