import Promise from 'bluebird';

import { CHANGE_CALL_FLOW_ENTRY_SUCCESS } from '../../constants/CallFlowEntry';

// changeCallFlowEntry

export function changeCallFlowEntrySuccess({ id, name, value }) {
  return {
    type: CHANGE_CALL_FLOW_ENTRY_SUCCESS,
    updateValues: { id, name, value }
  };
}

export default function changeCallFlowEntry(id, name, value) {
  return dispatch => Promise.resolve(dispatch(changeCallFlowEntrySuccess({ id, name, value })));
}
