import React from 'react';

import { auth } from '../../pages/routes';

function AuthContainer() {
  return (
    <div id="main-wrapper">
      <div className="main-content" style={{ minHeight: '695px' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {auth}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthContainer;
