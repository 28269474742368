import { push } from 'connected-react-router';

import { apiRequest } from '../../utils';

import { authLoading, authUser, authError } from '../AuthActions';

// signinUser

export default function signinUser({ username, password }) {
  return dispatch => {
    dispatch(authLoading());

    return apiRequest
      .authPost('auth/token', { username, password, grant_type: 'password' })
      .then(
        ({
          data: {
            token_type: tokenType,
            access_token: token,
            data: { attributes: user },
          },
        }) => {
          dispatch(
            authUser({
              tokenType,
              username: username,
              accessToken: token,
              currentUser: user,
            })
          );
          dispatch(push('/'));
        }
      )
      .catch(error => dispatch(authError(error)));
  };
}
