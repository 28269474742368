import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

export default function(ComposedComponent) {
  class Authentication extends Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      push: PropTypes.func.isRequired,
    };

    static contextTypes = {
      router: PropTypes.object,
    };

    componentWillMount() {
      const { isAuthenticated } = this.props;
      if (!isAuthenticated) {
        this.props.push('/auth');
      }
    }

    componentWillUpdate(nextProps) {
      const { isAuthenticated } = nextProps;
      if (!isAuthenticated) {
        this.props.push('/auth');
      }
    }

    render() {
      const { isAuthenticated } = this.props;
      if (isAuthenticated) {
        return <ComposedComponent {...this.props} />;
      }

      return null;
    }
  }

  return connect(({ auth: { isAuthenticated } }) => ({ isAuthenticated }), {
    push,
  })(Authentication);
}
