import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { LinkTo } from '../../../helpers';

function FavoriteInfo({ item, organizationId }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'relationships.name')}</td>
        </tr>
        <tr>
          <td>Number</td>
          <td>{get(item, 'attributes.phone_number')}</td>
        </tr>
        <tr>
          <td>Index</td>
          <td>{get(item, 'attributes.index')}</td>
        </tr>
      </tbody>
    </table>
  );
}

FavoriteInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default FavoriteInfo;
