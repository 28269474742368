import { push } from 'connected-react-router';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import {
  authLoading,
  authStopLoading,
  authSuccessMessage,
  authError,
} from '../AuthActions';

// updatePassword

export default function updatePassword(code, user) {
  localStorage.removeItem('username');
  localStorage.removeItem('token_type');
  localStorage.removeItem('access_token');

  return dispatch => {
    dispatch(authLoading());

    return apiRequest
      .authPost('auth/update_password', {
        code,
        ...omit(user, 'passwordConfirmation'),
      })
      .then(() => {
        dispatch(push('/auth'));
        dispatch(authStopLoading());
        dispatch(
          authSuccessMessage(
            'Your password has been changed successfully. Please, login with your new login/password'
          )
        );
      })
      .catch(error => dispatch(authError(error)));
  };
}
