import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_PHONE_NUMBER_START,
  DELETE_PHONE_NUMBER_SUCCESS,
  DELETE_PHONE_NUMBER_ERROR
} from '../../constants/PhoneNumber';

// deletePhoneNumber

export function deletePhoneNumberStart() {
  return {
    type: DELETE_PHONE_NUMBER_START
  };
}

export function deletePhoneNumberSuccess(item = {}) {
  return {
    type: DELETE_PHONE_NUMBER_SUCCESS,
    item
  };
}

export function deletePhoneNumberError(error) {
  return {
    type: DELETE_PHONE_NUMBER_ERROR,
    errorMessage: get(error, 'response.data.message', 'Server-side error')
  };
}

export default function deletePhoneNumber(organizationId, phoneNumberId) {
  return dispatch => {
    dispatch(deletePhoneNumberStart());

    return apiRequest.delete(`organizations/${organizationId}/phone_numbers/${phoneNumberId}`)
      .then(({ data }) => Promise.resolve(dispatch(deletePhoneNumberSuccess(data))))
      .catch(error => Promise.reject(dispatch(deletePhoneNumberError(error))));
  };
}
