import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { push as pushAction } from 'connected-react-router';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchCallQueues,
  createCallQueue as createCallQueueAction,
} from '../../../actions/CallQueueActions';

import { fetchMessages as fetchMessagesAction } from '../../../actions/MessageActions';

import { CallQueueForm } from '../../../components';
import { Loading } from '../../../helpers';

class CallQueueAddPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    formValues: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      workflow_sid: PropTypes.string,
      business_hours: PropTypes.string,
      fallthrough_type: PropTypes.string,
      redirect_call_queue_id: PropTypes.number,
    }),
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        attributes: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ).isRequired,
    callQueues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        attributes: PropTypes.shape({
          name: PropTypes.string,
        }),
      })
    ).isRequired,
    callQueuesFetched: PropTypes.bool.isRequired,
    callQueuesErrorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    fetchCallQueues: PropTypes.func.isRequired,
    fetchMessages: PropTypes.func.isRequired,
    createCallQueue: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    callQueuesErrorMessage: null,
    formValues: {},
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchCallQueues(organizationId, { page: 1 });
    this.props.fetchMessages(organizationId, { page: 1 });
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      formValues,
      callQueues,
      callQueuesFetched,
      callQueuesErrorMessage,
      createCallQueue,
      push,
      messages,
    } = this.props;

    return (
      <div className="row" id="call-queue-add">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Create Call Queue</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <Loading loaded={callQueuesFetched}>
                <CallQueueForm
                  initialValues={{ fallthrough_type: 'voicemail' }}
                  formValues={formValues}
                  organizationId={organizationId}
                  callQueues={callQueues.map(callQueue => ({
                    id: callQueue.id,
                    name: get(callQueue, 'attributes.name'),
                  }))}
                  messages={messages}
                  errorMessage={callQueuesErrorMessage}
                  onSubmit={values =>
                    createCallQueue(organizationId, values).then(() =>
                      push(`/organizations/${organizationId}/callQueues`)
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('call-queue');

const formFields = [
  'name',
  'description',
  'workflow_sid',
  'business_hours',
  'fallthrough_type',
  'redirect_call_queue_id',
];

export default connect(
  state => {
    const {
      callQueues: {
        items: callQueues,
        itemsFetched: callQueuesFetched,
        itemsErrorMessage: callQueuesErrorMessage,
      },
      messages: {
        items: messages,
        itemsMeta: messagesMeta,
        itemsFetched: messagesFetched,
        itemsErrorMessage: messagesErrorMessage,
      },
    } = state;

    return {
      callQueues,
      callQueuesFetched,
      callQueuesErrorMessage,
      messages,
      messagesMeta,
      messagesFetched,
      messagesErrorMessage,
      formValues: fromPairs(
        formFields.map(formField => [formField, selector(state, formField)])
      ),
    };
  },
  {
    fetchOrganization,
    fetchCallQueues,
    fetchMessages: fetchMessagesAction,
    createCallQueue: createCallQueueAction,
    push: pushAction,
  }
)(CallQueueAddPage);
