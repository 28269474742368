import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { Button, renderField, renderDropdownList } from '../../../helpers';

function MessagesFilterForm({ agents, onClear, handleSubmit, onSubmit }) {
  return (
    <form onSubmit={handleSubmit(values => onSubmit(values))}>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <Field name="name" label="Name" component={renderField} />
            </div>
            <div className="col-3">
              <Field
                name="type"
                label="Type"
                component={renderDropdownList}
                data={[{ value: 'text/plain', label: 'Text' }, { value: 'audio/mp3', label: 'Audio' }]}
              />
            </div>
            <div className="col-3">
              <Field
                name="owner_user_role_id"
                label="Owner"
                component={renderDropdownList}
                data={agents}
              />
            </div>
            <div className="col-2 text-right">
              <div><label>&nbsp;</label></div>
              <Button color="secondary" type="primary" htmlType="submit">
            Search
          </Button>
              <Button color="secondary" onClick={() => onClear()}>Clear Filters</Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

MessagesFilterForm.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  onClear: PropTypes.func.isRequired
};

export default reduxForm({ form: 'messages-filter' })(MessagesFilterForm);
