import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  SHOW_CALL_QUEUE_START,
  SHOW_CALL_QUEUE_SUCCESS,
  SHOW_CALL_QUEUE_ERROR
} from '../../constants/CallQueue';

// fetchCallQueue

export function showCallQueueStart() {
  return {
    type: SHOW_CALL_QUEUE_START
  };
}

export function showCallQueue(item = {}) {
  return {
    type: SHOW_CALL_QUEUE_SUCCESS,
    item
  };
}

export function showCallQueueError(error) {
  return {
    type: SHOW_CALL_QUEUE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchCallQueue(callQueueId, options = {}) {
  return dispatch => {
    dispatch(showCallQueueStart());

    return apiRequest.get(`call_queues/${callQueueId}`, options)
      .then(({ data: { data: item } }) => Promise.resolve(dispatch(showCallQueue(item))))
      .catch(error => Promise.reject(dispatch(showCallQueueError(error))));
  };
}
