import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchRingGroup,
  deleteRingGroup as deleteRingGroupAction,
} from '../../../actions/RingGroupActions';

import { RingGroupDelete } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class RingGroupDeletePage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        ringGroupTag: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchRingGroup: PropTypes.func.isRequired,
    deleteRingGroup: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      match: {
        params: { organizationId, ringGroupTag },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchRingGroup(organizationId, ringGroupTag);
  }

  render() {
    const {
      match: {
        params: { organizationId, ringGroupTag },
      },
      item,
      itemFetched,
      deleteRingGroup,
      push,
    } = this.props;

    return (
      <div id="ring-group-delete">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">
              {isEmpty(item)
                ? 'Ring Group'
                : `Ring Group: ${item.id} | ${get(item, 'attributes.name')}`}
            </h3>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/ringGroups`}
          id={ringGroupTag}
          active="delete"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <RingGroupDelete
                    organizationId={organizationId}
                    item={item}
                    onDelete={({ id }) =>
                      deleteRingGroup(organizationId, id).then(() =>
                        push(`/organizations/${organizationId}/ringGroups`)
                      )
                    }
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ ringGroups: { item, itemFetched } }) => ({ item, itemFetched }),
  {
    fetchOrganization,
    fetchRingGroup,
    deleteRingGroup: deleteRingGroupAction,
    push: pushAction,
  }
)(RingGroupDeletePage);
