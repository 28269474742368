import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchPhoneNumber } from '../../../actions/PhoneNumberActions';

import { PhoneNumberInfo } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class PhoneNumberShowPage extends Component {

  static propTypes = {
    currentUser: PropTypes.shape({ organization_id: PropTypes.number.isRequired }).isRequired,
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        phoneNumberId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    itemErrorMessage: PropTypes.string,
    fetchPhoneNumber: PropTypes.func.isRequired
  };

  static defaultProps = {
    itemErrorMessage: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { match: { params: { phoneNumberId } }, currentUser } = this.props;

    this.props.fetchPhoneNumber(currentUser.organization_id, phoneNumberId);
  }

  render() {
    const { match: { params: { phoneNumberId } }, item, itemFetched, itemErrorMessage } = this.props;

    return (
      <div id="phone-number-show">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">Phone Number Info</h3>
          </div>
        </div>

        <CrudTabs
          add={false}
          edit={false}
          destroy={false}
          model="phone-numbers"
          id={phoneNumberId}
          active="show"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <PhoneNumberInfo item={item} errorMessage={itemErrorMessage} />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  auth: {
    currentUser
  },
  phoneNumbers: {
    item,
    itemFetched,
    itemErrorMessage
  }
}) => ({ currentUser, item, itemFetched, itemErrorMessage }), { fetchPhoneNumber })(PhoneNumberShowPage);
