import { parse, format } from 'libphonenumber-js';

export default (phone) => {

  if (!phone) {
    return;
  }
  const parsedPhone = parse(phone.replace(/^\+/g, ''), { country: { default: 'US' } });
  if (!parsedPhone.phone) {
    throw new Error('Invalid phone number: ' + phone);
  }
  return format(parsedPhone, 'International_plaintext');
};