import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { DELETE_FILE_START, DELETE_FILE_SUCCESS, DELETE_FILE_ERROR } from '../../constants/File';

// deleteFile

export function deleteFileStart() {
  return {
    type: DELETE_FILE_START
  };
}

export function deleteFileSuccess(item = {}) {
  return {
    type: DELETE_FILE_SUCCESS,
    item
  };
}

export function deleteFileError(error) {
  return {
    type: DELETE_FILE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteFile(organizationId, file) {
  return dispatch => {
    dispatch(deleteFileStart());

    return apiRequest
      .post(`organizations/${organizationId}/files`, null, file)
      .then(({ data }) => dispatch(deleteFileSuccess(data)))
      .catch(error => dispatch(deleteFileError(error)));
  };
}
