import { LOCATION_CHANGE } from 'connected-react-router';

import {
  AUTH_LOADING,
  AUTH_STOP_LOADING,
  AUTH_USER,
  UPDATE_CURRENT_USER,
  LOGOUT_USER,
  AUTH_ERROR,
  AUTH_SUCCESS_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
} from '../constants/Auth';

const defaultState = {
  authFetched: false,
  isAuthenticated: false,
  successMessage: null,
  errorMessage: null,
  currentUser: {},
  isLoading: false,
};

export default function AuthReducer(
  state = defaultState,
  { type, currentUser, errorMessage, successMessage }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return { ...state, successMessage: null, errorMessage: null };
    case AUTH_LOADING:
      return { ...state, isLoading: true };
    case AUTH_STOP_LOADING:
      return { ...state, isLoading: false };
    case AUTH_USER:
      return {
        ...state,
        authFetched: true,
        isAuthenticated: true,
        isLoading: false,
        errorMessage: null,
        currentUser,
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...currentUser },
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...defaultState,
        authFetched: true,
        isAuthenticated: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage,
        currentUser: {},
        authFetched: true,
      };
    case AUTH_SUCCESS_MESSAGE:
      return { ...state, successMessage, currentUser: {} };
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage, errorMessage: null };
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage, successMessage: null };
    default:
      return state;
  }
}
