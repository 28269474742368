import { LOCATION_CHANGE } from 'connected-react-router';

import {
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR,
  CREATE_MESSAGE_START,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR,
  FETCH_MESSAGE_START,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_ERROR,
  UPDATE_MESSAGE_START,
  UPDATE_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_ERROR,
  DELETE_MESSAGE_START,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
} from '../constants/Message';

const defaultState = {
  path: null,

  items: [],
  itemsFetched: false,
  itemsLoading: false,
  itemsMeta: { page: 1, pageSize: 10 },
  itemsErrorMessage: null,

  item: {},
  itemLoading: false,
  itemFetched: false,
  itemErrorMessage: null,
};

export default function MessageReducer(
  state = defaultState,
  { type, payload, items, meta, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case FETCH_MESSAGES_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsErrorMessage: null,
        itemsFetched: true,
        items,
        itemsMeta: meta,
      };

    case FETCH_MESSAGES_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: false,
        itemsErrorMessage: errorMessage,
      };

    case CREATE_MESSAGE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case CREATE_MESSAGE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    case FETCH_MESSAGE_START:
      return {
        ...state,
        itemFetched: false,
        itemLoading: true,
      };

    case FETCH_MESSAGE_SUCCESS:
      return {
        ...state,
        itemFetched: true,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case FETCH_MESSAGE_ERROR:
      return {
        ...state,
        itemFetched: true,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    case UPDATE_MESSAGE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case UPDATE_MESSAGE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    case DELETE_MESSAGE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item: {},
      };

    case DELETE_MESSAGE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    default:
      return state;
  }
}
