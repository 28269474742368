import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { Button, LinkTo, renderField } from '../../../helpers';

function SigninForm({ isLoading, successMessage, errorMessage, handleSubmit, onSubmit }) {
  return (
    <form className="floating-labels m-t-40" onSubmit={handleSubmit(user => onSubmit(user))}>
      {successMessage ? (
        <div className="alert alert-success">
          {successMessage}
        </div>
      ) : null}

      <Field
        material
        required
        name="username"
        component={renderField}
        className="form-control"
        label="Email"
      />
      <Field
        material
        required
        name="password"
        type="password"
        component={renderField}
        className="form-control"
        label="Password"
      />

      {errorMessage ? (
        <div className="alert alert-danger">
          {errorMessage}
        </div>
      ) : null}

      <div className="form-group m-b-40">
        <div className="col-md-12">
          <LinkTo href="auth/reset" className="text-dark pull-right">
            <i className="fa fa-lock" /> Forgot password?
          </LinkTo>
        </div>
      </div>

      <div className="form-group text-center m-t-20">
        <div className="col-xs-12">
          <Button
            submit
            disabled={isLoading}
            className="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
          >
            {isLoading ? 'Processing...' : 'Log In'}
          </Button>
        </div>
      </div>
    </form>
  );
}

SigninForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

SigninForm.defaultProps = {
  successMessage: null,
  errorMessage: null
};

function validate({ username, password }) {
  const errors = {};

  if (!username) {
    errors.username = 'Please enter a username';
  }

  if (!password) {
    errors.password = 'Please enter a password';
  }

  return errors;
}

export default reduxForm({ form: 'signin', validate })(SigninForm);
