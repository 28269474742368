import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  fetchCallFlow,
  updateCallFlow as updateCallFlowAction,
} from '../../../actions/CallFlowActions';

import { CallFlowForm } from '../../../components';
import { CrudTabs, LinkTo, Loading } from '../../../helpers';

class CallFlowEditPage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        callFlowId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    fetchCallFlow: PropTypes.func.isRequired,
    updateCallFlow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { callFlowId },
      },
    } = this.props;

    this.props.fetchCallFlow(callFlowId);
  }

  render() {
    const {
      match: {
        params: { organizationId, callFlowId },
      },
      item,
      itemFetched,
      errorMessage,
      updateCallFlow,
      push,
    } = this.props;

    return (
      <div className="row" id="call-flow-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">
                {isEmpty(item)
                  ? 'Call Flow'
                  : `Call Flow: ${item.id} | ${get(item, 'attributes.name')}`}
              </h3>
            </div>
          </div>

          <CrudTabs
            model={`organizations/${organizationId}/callFlows`}
            id={callFlowId}
            active="edit"
            afterTabs={[
              <LinkTo
                icon="list"
                className="nav-link"
                href={`organizations/${organizationId}/callFlows/${item.id}/entries`}
              >
                Call Flow Entries
              </LinkTo>,
            ]}
          />

          <div className="card">
            <div className="card-block">
              <Loading loaded={itemFetched}>
                <CallFlowForm
                  organizationId={organizationId}
                  item={item}
                  errorMessage={errorMessage}
                  onSubmit={values =>
                    updateCallFlow(callFlowId, values).then(() =>
                      push(`/organizations/${organizationId}/callFlows`)
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ callFlows: { item, itemFetched, errorMessage } }) => ({
    item,
    itemFetched,
    errorMessage,
  }),
  { fetchCallFlow, updateCallFlow: updateCallFlowAction, push: pushAction }
)(CallFlowEditPage);
