import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_ORGANIZATIONS_START,
  ADD_ORGANIZATIONS_SUCCESS,
  ADD_ORGANIZATIONS_ERROR
} from '../../constants/Organization';

// fetchOrganizations

export function addOrganizationsStart() {
  return {
    type: ADD_ORGANIZATIONS_START
  };
}

export function addOrganizations(items = [], meta = {}) {
  return {
    type: ADD_ORGANIZATIONS_SUCCESS,
    items,
    meta
  };
}

export function addOrganizationsError(error) {
  return {
    type: ADD_ORGANIZATIONS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchOrganizations({ page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addOrganizationsStart());

    return apiRequest.get('organizations', { page, pageSize })
      .then(({ data: { data: items } }) => dispatch(addOrganizations(items, { page, pageSize })))
      .catch(error => dispatch(addOrganizationsError(error)));
  };
}
