import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  fetchOrganization,
  deleteOrganization as deleteOrganizationAction
} from '../../../actions/OrganizationActions';

import { OrganizationDelete } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class OrganizationDeletePage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    deleteOrganization: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchOrganization(organizationId);
  }

  render() {
    const { match: { params: { organizationId } }, item, itemFetched, deleteOrganization } = this.props;

    return (
      <div id="organization-delete">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">
              {isEmpty(item) ? 'Organization' : `Organization: ${item.id} | ${get(item, 'attributes.name')}`}
            </h3>
          </div>
        </div>

        <CrudTabs model="organizations" id={organizationId} active="delete" />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <OrganizationDelete
                    item={item}
                    onDelete={({ id }) => deleteOrganization(id)}
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  organizations: {
    item,
    itemFetched
  }
}) => ({ item, itemFetched }), {
  fetchOrganization,
  deleteOrganization: deleteOrganizationAction
})(OrganizationDeletePage);
