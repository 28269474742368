import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { addSubGroupToRingGroup } from '../../../actions/RingGroupActions';

import { AttachSubGroupForm } from '../../../components';
import { Button } from '../../../helpers';

class AttachSubGroupModal extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    ringGroupTag: PropTypes.string.isRequired,
    ringGroup: PropTypes.shape({
      relationships: PropTypes.shape({
        users: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired
            })
          )
        })
      })
    }).isRequired,
    errorMessage: PropTypes.string,
    onRefresh: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null
  };

  state = {
    opened: false
  };

  toggleOpened() {
    this.setState({ opened: !this.state.opened });
  }

  render() {
    const {
      ringGroups,
      ringGroupTag,
      organizationId,
      ringGroup,
      errorMessage,
      onRefresh,
      addSubGroupToRingGroup
    } = this.props;

    const subGroupIds = get(ringGroup, 'relationships.subgroups.data', []).map(r => r.id);

    return (
      <Button rounded icon="plus" color="success" onClick={() => this.toggleOpened()}>
        <Modal show={this.state.opened} onHide={() => this.setState({ opened: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Subgroup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AttachSubGroupForm
              items={filter(ringGroups, ({ id }) => !includes(subGroupIds, id) && id !== get(ringGroup, 'id')).map(
                item => ({
                  label: `${get(item, 'attributes.name')} (${get(item, 'attributes.tag')})`,
                  value: item.id
                })
              )}
              errorMessage={errorMessage}
              onClose={() => this.setState({ opened: false })}
              onSubmit={({ ring_group_tag: subGroupTag }) =>
                addSubGroupToRingGroup(organizationId, ringGroupTag, subGroupTag)
                  .then(() => this.setState({ opened: false }))
                  .then(() => onRefresh())
              }
            />
          </Modal.Body>
        </Modal>
      </Button>
    );
  }
}

export default connect(({ ringGroups: { errorMessage } }) => ({ errorMessage }), {
  addSubGroupToRingGroup
})(AttachSubGroupModal);
