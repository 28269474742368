export default input => {
  if (!input) {
    return false;
  }

  switch (String(input).toLowerCase()) {
    case "true":
    case "1":
    case "y":
    case "yes":
      return true;
    default:
      return false;
  }
};
