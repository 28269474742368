import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { Boolean } from '../../../helpers';

function CallFlowInfo({ item }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'attributes.name')}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{get(item, 'attributes.description')}</td>
        </tr>
        <tr>
          <td>Application SID</td>
          <td>{get(item, 'attributes.application_sid')}</td>
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>{get(item, 'attributes.phone_number')}</td>
        </tr>
        <tr>
          <td>Voice Channel</td>
          <td><Boolean value={get(item, 'attributes.voice_channel')} /></td>
        </tr>
        <tr>
          <td>Chat Channel</td>
          <td><Boolean value={get(item, 'attributes.chat_channel')} /></td>
        </tr>
        <tr>
          <td>Video Channel</td>
          <td><Boolean value={get(item, 'attributes.video_channel')} /></td>
        </tr>
      </tbody>
    </table>
  );
}

CallFlowInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default CallFlowInfo;
