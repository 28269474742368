import get from 'lodash/get';

import { apiWSConnect } from '../../utils';

import {
  SUBSCRIBE_TO_LIVE_CALLS_START,
  SUBSCRIBE_TO_LIVE_CALLS_SUCCESS,
  SUBSCRIBE_TO_LIVE_CALLS_ERROR,
  ADD_LIVE_CALL,
  REMOVE_LIVE_CALL
} from '../../constants/Call';

// connectToLiveCalls

export function subscribeToLiveCallsStart() {
  return {
    type: SUBSCRIBE_TO_LIVE_CALLS_START
  };
}

export function subscribeToLiveCallsSuccess(items) {
  return {
    type: SUBSCRIBE_TO_LIVE_CALLS_SUCCESS,
    items
  };
}

export function subscribeToLiveCallsError(error) {
  return {
    type: SUBSCRIBE_TO_LIVE_CALLS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export function addLiveCall(item) {
  return {
    type: ADD_LIVE_CALL,
    item
  };
}

export function removeLiveCall(item) {
  return {
    type: REMOVE_LIVE_CALL,
    item
  };
}

export default function subscribeToLiveCalls(organizationId) {
  return dispatch => {
    dispatch(subscribeToLiveCallsStart());

    return apiWSConnect(client => {
      client.request(`/v1/organizations/${organizationId}/calls/live`).then(({
        payload: {
          data: items
        }
      }) => dispatch(subscribeToLiveCallsSuccess(items)));

      client.subscribe(`/v1/organizations/${organizationId}/calls/live`, ({
        data: liveCall
      }) => {
        dispatch(addLiveCall(liveCall));

        if (get(liveCall, 'attributes.status') === 'completed') {
          setTimeout(() => dispatch(removeLiveCall(liveCall)), 3000);
        }
      });
    });
  };
}
