import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import FavoritesIndexPage from './FavoritesIndexPage/FavoritesIndexPage';
import FavoriteAddPage from './FavoriteAddPage/FavoriteAddPage';
import FavoriteShowPage from './FavoriteShowPage/FavoriteShowPage';
import FavoriteEditPage from './FavoriteEditPage/FavoriteEditPage';
import FavoriteDeletePage from './FavoriteDeletePage/FavoriteDeletePage';

export default [
  <Route
    exact
    key="favorites"
    path="/organizations/:organizationId/favorites"
    component={RequireAuth(FavoritesIndexPage)}
  />,
  <Route
    exact
    key="favorite-new"
    path="/organizations/:organizationId/favorites/new"
    component={RequireAuth(FavoriteAddPage)}
  />,
  <Route
    exact
    key="favorite-show"
    path="/organizations/:organizationId/favorites/:id/show"
    component={RequireAuth(FavoriteShowPage)}
  />,
  <Route
    exact
    key="favorite-edit"
    path="/organizations/:organizationId/favorites/:id/edit"
    component={RequireAuth(FavoriteEditPage)}
  />,
  <Route
    exact
    key="favorite-delete"
    path="/organizations/:organizationId/favorites/:id/delete"
    component={RequireAuth(FavoriteDeletePage)}
  />
];
