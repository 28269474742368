import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import get from 'lodash/get';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { createRingGroup as createRingGroupAction } from '../../../actions/RingGroupActions';

import { RingGroupForm } from '../../../components';

class RingGroupAddPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    errorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    createRingGroup: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      errorMessage,
      createRingGroup,
      push,
    } = this.props;

    return (
      <div className="row" id="ring-group-add">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Create RingGroup</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <RingGroupForm
                isNew
                organizationId={organizationId}
                errorMessage={errorMessage}
                onSubmit={values =>
                  createRingGroup(organizationId, values).then(resp => {
                    push(
                      `/organizations/${organizationId}/ringGroups/${get(
                        resp,
                        'item.data.id'
                      )}/edit`
                    );
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ ringGroups: { errorMessage } }) => ({ errorMessage }),
  {
    fetchOrganization,
    createRingGroup: createRingGroupAction,
    push: pushAction,
  }
)(RingGroupAddPage);
