import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_AVAILABLE_PHONE_NUMBERS_START,
  ADD_AVAILABLE_PHONE_NUMBERS_SUCCESS,
  ADD_AVAILABLE_PHONE_NUMBERS_ERROR
} from '../../constants/PhoneNumber';

// fetchAvailablePhoneNumbers

export function addAvailablePhoneNumbersStart() {
  return {
    type: ADD_AVAILABLE_PHONE_NUMBERS_START
  };
}

export function addAvailablePhoneNumbers(items = [], meta = {}) {
  return {
    type: ADD_AVAILABLE_PHONE_NUMBERS_SUCCESS,
    items,
    meta
  };
}

export function addAvailablePhoneNumbersError(error) {
  return {
    type: ADD_AVAILABLE_PHONE_NUMBERS_ERROR,
    errorMessage: get(error, 'response.data.errMessage', 'Server-side Error')
  };
}

export default function fetchAvailablePhoneNumbers(organizationId, { areaCode, contains, country }) {
  return dispatch => {
    dispatch(addAvailablePhoneNumbersStart());
    return apiRequest.get(`organizations/${organizationId}/phone_numbers/available`, {
      areaCode,
      contains,
      country
    }, true)
      .then(({ data: { data } }) => Promise.resolve(dispatch(addAvailablePhoneNumbers(data, {
        areaCode,
        contains,
        country
      }))))
      .catch(error => Promise.reject(dispatch(addAvailablePhoneNumbersError(error))));
  };
}
