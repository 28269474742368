import axios from 'axios';
import compact from 'lodash/compact';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';

import { API_URI } from '../config';

function headers() {
  return {
    headers: {
      'Content-Type': 'application/json',
      authorization: [
        localStorage.getItem('token_type') || 'Bearer',
        localStorage.getItem('access_token')
      ].join(' ')
    }
  };
}

export default class apiRequest {
  static get(path, options = null, skipSnakeCase = false) {
    const url = isEmpty(options) ? path : [path, `${compact(keys(options).map(key => {
      if (options[key]) {
        if (skipSnakeCase) {
          return ([key, isObject(options[key]) ? JSON.stringify(options[key]) : encodeURIComponent(options[key])]).join('=');
        }
        return ([snakeCase(key), isObject(options[key]) ? JSON.stringify(options[key]) : encodeURIComponent(options[key])]).join('=');
      }

      return null;
    })).join('&')}`].join('?');

    return axios.get(`${API_URI}/${url}`, headers());
  }

  static post(path, data, replacedData = null) {
    return axios.post(`${API_URI}/${path}`, replacedData || { data: { attributes: data } }, headers());
  }

  static put(path, data, replacedData = null) {
    return axios.put(`${API_URI}/${path}`, replacedData || { data: { attributes: data } }, headers());
  }

  static deleteMany(path, data, replacedData = null) {
    const body = replacedData || { data: { attributes: data } };
    return axios.delete(`${API_URI}/${path}`, Object.assign({}, { data: body }, headers()));
  }

  static delete(path, id) {
    return axios.delete(`${API_URI}/${compact([path, id]).join('/')}`, headers());
  }

  static authGet(path) {
    return axios.get(`${API_URI}/${path}`, headers());
  }

  static authToken() {
    return axios.get(`${API_URI}/me`, headers());
  }

  static authPost(path, data) {
    return axios.post(`${API_URI}/${path}`, data);
  }
}
