import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import isArray from 'lodash/isArray';

import { Button } from '../helpers';

function renderRadiosTable({ label, data, input, meta: { touched, error, warning } }) {
  return (
    <div className={cl('form-group', { 'has-success': touched && !error && !warning, 'has-danger': touched && error, 'has-warning': touched && warning })}>
      {label ? (
        <div>
          <label className="form-control-label" htmlFor={`form-input-${input.name}`}>{label}</label>
        </div>
      ) : null}

      <table className="table">
        <tbody>
          {data.map(({ label: optionLabel, value }) => (
            <tr key={value}>
              {isArray(optionLabel) ? optionLabel.map((item, index) => <td key={`${item}-${index}`}>{item}</td>) : <td>{optionLabel}</td>}
              <td>
                <Button
                  rounded
                  size="sm"
                  icon="check"
                  color={input.value === value ? 'success' : 'secondary'}
                  onClick={() => input.onChange(value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderRadiosTable.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
  })).isRequired,
};

renderRadiosTable.defaultProps = {
  label: null
};

export default renderRadiosTable;
