import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  SHOW_ORGANIZATION_START,
  SHOW_ORGANIZATION_SUCCESS,
  SHOW_ORGANIZATION_ERROR
} from '../../constants/Organization';

// fetchOrganization

export function showOrganizationStart() {
  return {
    type: SHOW_ORGANIZATION_START
  };
}

export function showOrganization(item = {}) {
  return {
    type: SHOW_ORGANIZATION_SUCCESS,
    item
  };
}

export function showOrganizationError(error) {
  console.log('error', error);
  return {
    type: SHOW_ORGANIZATION_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchOrganization(organizationId, options = {}) {
  return dispatch => {
    dispatch(showOrganizationStart());

    return apiRequest.get(`organizations/${organizationId}`, options)
      .then(({ data: { data: item } }) => {
        /* TODO: bandaid - should just be returning the dispatch instead of the conditional needs_update */
        if (item.attributes.needs_update) {
          return apiRequest.put(`organizations/${organizationId}`, {})
          .then(({ data: { data: item } }) => dispatch(showOrganization(item)));
        }
        dispatch(showOrganization(item))
      })
      .catch(error => dispatch(showOrganizationError(error)));
  };
}
