import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';

import 'react-widgets/dist/css/react-widgets.css';
import 'react-select/dist/react-select.css';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'loaders.css/loaders.min.css';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './styles/assets/plugins/bootstrap/css/bootstrap.min.css';
import './styles/template/css/style.css';
import './styles/template/css/colors/blue.css';
import './styles/main.css';

import App from './App';
import configureStore from './store';
import { unregister } from './registerServiceWorker';

const history = createBrowserHistory();
const store = configureStore(window.__INITIAL_STATE__, history); // eslint-disable-line
const dest = document.getElementById('root');

ReactDOM.render(<App history={history} store={store} />, dest);
unregister();

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEVTOOLS) {
  // eslint-disable-line
  window.React = React; // enable debugger

  if (!window.devToolsExtension) {
    const { DevTools } = require('./components'); // eslint-disable-line global-require
    const devToolsDest = document.createElement('div');

    dest.parentNode.insertBefore(devToolsDest, dest.nextSibling);
    ReactDOM.render(
      <Provider store={store} key="provider">
        <DevTools />
      </Provider>,
      devToolsDest
    );
  }
}
