import React, { Component } from 'react';
import { Timer } from 'react-soundplayer/components';

import { withCustomAudio } from 'react-soundplayer/addons';
import { Button } from './';

class AWSAudioPlayer extends Component {
  render() {
    const { playing, hideTimer } = this.props;
    return (
      <div>
        <Button icon={playing ? 'pause' : 'play'} className="pull-left btn btn-primary" onClick={() => this.play()}>
          {playing ? 'Pause' : 'Play'}
        </Button>
        {hideTimer ? null : (
          <Timer
            {...this.props}
            className="pull-left"
            style={{ marginTop: '5px', fontSize: '10px', marginLeft: '10px' }}
          />
        )}
      </div>
    );
  }

  play() {
    const { soundCloudAudio, playing } = this.props;
    if (playing) {
      soundCloudAudio.pause();
    } else {
      soundCloudAudio.play();
    }
  }
}

export default withCustomAudio(AWSAudioPlayer);
