import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cl from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Menu, Icon } from 'antd';

class Navigation extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    userEmail: PropTypes.string,
    userName: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      subdomain: PropTypes.string,
    }),
    withoutOrganizations: PropTypes.bool,
    onLogout: PropTypes.func.isRequired,
    theme: PropTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    userEmail: null,
    userName: null,
    organization: null,
    withoutOrganizations: false,
    theme: 'dark',
  };

  render() {
    const {
      isAuthenticated,
      userEmail,
      userName,
      organization,
      withoutOrganizations,
      onLogout,
      theme,
    } = this.props;

    return (
      <Menu theme={theme} mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="1">
          <Link to="/organizations">
            <Icon type="user" />
            <span className="nav-text">Organizations</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/users`}
          >
            <Icon type="team" />
            <span className="nav-text">Users</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/callFlows`}
          >
            <Icon type="branches" />
            <span className="nav-text">Call Flows</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/callQueues`}
          >
            <Icon type="cluster" />
            <span className="nav-text">Call Queues</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="5">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/ringGroups`}
          >
            <Icon type="usergroup-add" />
            <span className="nav-text">Ring Groups</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="6">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/phoneNumbers`}
          >
            <Icon type="phone" />
            <span className="nav-text">Phone Numbers</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="7">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/calls`}
          >
            <Icon type="database" />
            <span className="nav-text">Calls History</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="livecalls">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/calls/live`}
          >
            <Icon type="database" />
            <span className="nav-text">Live</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="8">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/voicemails`}
          >
            <Icon type="sound" />
            <span className="nav-text">Voicemails</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="9">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/favorites`}
          >
            <Icon type="tags" />
            <span className="nav-text">Favorites</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/blacklists`}
          >
            <Icon type="exception" />
            <span className="nav-text">Blacklists</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="11">
          <Link
            to={`/organizations/${get(
              organization,
              'attributes.subdomain'
            )}/messages`}
          >
            <Icon type="message" />
            <span className="nav-text">Messages</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="requests-viewer">
          <Link to={`/requests`}>
            <Icon type="message" />
            <span className="nav-text">Requests</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

export default Navigation;
