import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import {
  UPDATE_BLACKLIST_START,
  UPDATE_BLACKLIST_SUCCESS,
  UPDATE_BLACKLIST_ERROR,
} from '../../constants/Blacklist';

export function updateBlacklistStart() {
  return {
    type: UPDATE_BLACKLIST_START,
  };
}

export function updateBlacklistSuccess(item = {}) {
  return {
    type: UPDATE_BLACKLIST_SUCCESS,
    item,
  };
}

export function updateBlacklistError(error) {
  return {
    type: UPDATE_BLACKLIST_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function updateBlacklist(organizationId, id, values) {
  return dispatch => {
    dispatch(updateBlacklistStart());

    return apiRequest
      .put(`organizations/${organizationId}/blacklist/${id}`)
      .then(({ data }) => {
        dispatch(updateBlacklistSuccess(data));
        dispatch(push(`/organizations/${organizationId}/blacklist/${id}/show`));
      })
      .catch(error => dispatch(updateBlacklistError(error)));
  };
}
