import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchCallFlows } from '../../../actions/CallFlowActions';
import { fetchUsers } from '../../../actions/UserActions';
import { fetchPhoneNumber, updatePhoneNumber as updatePhoneNumberAction } from '../../../actions/PhoneNumberActions';
import fetchRingGroup, { fetchRingGroups as fetchRingGroupsAction } from '../../../actions/RingGroupActions';

import { PhoneNumberForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class PhoneNumberEditPage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        phoneNumberId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    fetchCallFlows: PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
    fetchPhoneNumber: PropTypes.func.isRequired,
    updatePhoneNumber: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const { match: { params: { organizationId, phoneNumberId } } } = this.props;

    this.props.fetchCallFlows(organizationId, { page });
    this.props.fetchUsers(organizationId, { page });
    this.props.fetchPhoneNumber(organizationId, phoneNumberId);
    this.props.fetchRingGroups(organizationId, { page });
  }

  render() {
    const {
      match: { params: { organizationId, phoneNumberId } },
      item, itemFetched, errorMessage, updatePhoneNumber,
      callFlows, callFlowsFetched, callFlowsErrorMessage,
      users, usersFetched, usersErrorMessage,
      ringGroups, ringGroupsFetched, ringGroupsErrorMessage
    } = this.props;

    return (
      <div className="row" id="user-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Update Phone Number {get(item, 'attributes.name')}</h3>
            </div>
          </div>

          <CrudTabs
            add={false}
            show={false}
            destroy={false}
            model={`organizations/${organizationId}/phoneNumbers`}
            id={phoneNumberId}
            active="edit"
          />

          <div className="card">
            <div className="card-block">
              <Loading loaded={itemFetched && callFlowsFetched && usersFetched && ringGroupsFetched}>
                <PhoneNumberForm
                  initialValues={{
                    default: get(item, 'attributes.default'),
                    call_flow_id: get(item, 'relationships.call_flow.data.id'),
                    username: get(item, 'relationships.user_role.data.attributes.username'),
                    outgoing_ring_group: get(item, 'relationships.outgoing_ring_group.data.id')
                  }}
                  item={item}
                  callFlows={callFlows}
                  users={users}
                  ringGroups={ringGroups}
                  errorMessage={errorMessage || callFlowsErrorMessage || usersErrorMessage || ringGroupsErrorMessage}
                  onSubmit={values => updatePhoneNumber(organizationId, phoneNumberId, values)}
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  phoneNumbers: {
    item,
    itemFetched,
    errorMessage
  },
  callFlows: {
    items: callFlows,
    itemsFetched: callFlowsFetched,
    itemsErrorMessage: callFlowsErrorMessage
  },
  users: {
    items: users,
    itemsFetched: usersFetched,
    itemsErrorMessage: usersErrorMessage
  },
  ringGroups: {
    items: ringGroups,
    itemsFetched: ringGroupsFetched,
    errorMessage: ringGroupsErrorMessage
  }
}) => ({
  item,
  itemFetched,
  errorMessage,
  callFlows,
  callFlowsFetched,
  callFlowsErrorMessage,
  users,
  usersFetched,
  usersErrorMessage,
  ringGroups,
  ringGroupsFetched,
  ringGroupsErrorMessage
}), {
  fetchOrganization,
  fetchCallFlows,
  fetchUsers,
  fetchPhoneNumber,
  updatePhoneNumber: updatePhoneNumberAction,
  fetchRingGroups: fetchRingGroupsAction
})(PhoneNumberEditPage);
