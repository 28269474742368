import React from 'react';
import PropTypes from 'prop-types';

import { Button, LinkTo } from '../helpers';

function CancelSubmitButtons({ cancelUrl, isLoading, children }) {
  return (
    <div className="form-group m-t-20">
      <div className="btn-group col-xs-12">
        <LinkTo button disabled={isLoading} color="secondary" icon="times-circle" href={cancelUrl}>
          Cancel
        </LinkTo>
        <Button submit disabled={isLoading} color="success" icon="save">
          {isLoading ? 'Processing...' : children}
        </Button>
      </div>
    </div>
  );
}

CancelSubmitButtons.propTypes = {
  cancelUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default CancelSubmitButtons;
