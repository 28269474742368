import Promise from 'bluebird';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { apiRequest } from '../../utils';

import {
  ADD_PHONE_NUMBERS_START,
  ADD_PHONE_NUMBERS_SUCCESS,
  ADD_PHONE_NUMBERS_ERROR
} from '../../constants/PhoneNumber';

// fetchPhoneNumbers

export function addPhoneNumbersStart() {
  return {
    type: ADD_PHONE_NUMBERS_START
  };
}

export function addPhoneNumbers(items = [], meta = {}) {
  return {
    type: ADD_PHONE_NUMBERS_SUCCESS,
    items,
    meta
  };
}

export function addPhoneNumbersError(error) {
  return {
    type: ADD_PHONE_NUMBERS_ERROR,
    errorMessage: get(error, 'response.data.message', 'Server-side Error')
  };
}

export default function fetchPhoneNumbers(organizationId, { page = 1, pageSize = 50, q }) {
  return dispatch => {
    dispatch(addPhoneNumbersStart());

    return apiRequest
      .get(`organizations/${organizationId}/phone_numbers`, { page, pageSize, q })
      .then(({ data: { data } }) =>
        Promise.resolve(
          dispatch(
            addPhoneNumbers(sortBy(data, ['id']), {
              page,
              pageSize,
              q
            })
          )
        )
      )
      .catch(error => Promise.reject(dispatch(addPhoneNumbersError(error))));
  };
}
