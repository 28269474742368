import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  UPDATE_CALL_QUEUE_ENTRIES_START,
  UPDATE_CALL_QUEUE_ENTRIES_SUCCESS,
  UPDATE_CALL_QUEUE_ENTRIES_ERROR
} from '../../constants/CallQueue';

// updateCallQueueEntries

export function updateCallQueueEntriesStart() {
  return {
    type: UPDATE_CALL_QUEUE_ENTRIES_START
  };
}

export function updateCallQueueEntriesSuccess() {
  return {
    type: UPDATE_CALL_QUEUE_ENTRIES_SUCCESS
  };
}

export function updateCallQueueEntriesError(error) {
  return {
    type: UPDATE_CALL_QUEUE_ENTRIES_ERROR,
    errorMessage: get(error, 'response.data.errors[0].title', 'Server-side error')
  };
}

export default function updateCallQueueEntries(callQueueId, values) {
  return dispatch => {
    dispatch(updateCallQueueEntriesStart());

    return apiRequest
      .put(`call_queues/${callQueueId}/entries`, null, { data: values })
      .then(() => Promise.resolve(dispatch(updateCallQueueEntriesSuccess())))
      .catch(error => Promise.reject(dispatch(updateCallQueueEntriesError(error))));
  };
}
