import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import { createFavorite as createFavoriteAction } from '../../../actions/FavoriteActions';

import { FavoriteForm } from '../../../components';

class FavoriteAddPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    errorMessage: PropTypes.string,
    fetchOrganization: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    createFavorite: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  render() {
    const {
      match: {
        params: { organizationId },
      },
      errorMessage,
      createFavorite,
      push,
    } = this.props;

    return (
      <div className="row" id="user-add">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Create Favorite</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <FavoriteForm
                isNew
                organizationId={organizationId}
                errorMessage={errorMessage}
                onSubmit={values =>
                  createFavorite(organizationId, values).then(() =>
                    push(`/organizations/${organizationId}/favorites`)
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ favorites: { errorMessage } }) => ({
    errorMessage,
  }),
  {
    createFavorite: createFavoriteAction,
    push: pushAction,
  }
)(FavoriteAddPage);
