import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Modal } from 'react-bootstrap';
import get from 'lodash/get';
import size from 'lodash/size';

import { Button, Boolean, renderField, renderCheckboxesTable, Loading } from '../../../helpers';

class BuyPhoneNumberForm extends Component {

  static propTypes = {
    showModal: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      phoneNumber: PropTypes.string,
      friendlyName: PropTypes.string,
    })),
    itemsFetched: PropTypes.bool,
    errorMessage: PropTypes.string,
    modalTitle: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    showModal: false,
    items: [],
    itemsFetched: false,
    errorMessage: null,
    modalTitle: null
  };

  render() {
    const {
      showModal, modalTitle,
      items, itemsFetched, errorMessage,
      handleSubmit, onSubmit, onSearch, onHide
    } = this.props;

    if (!showModal) {
      return null;
    }

    return (
      <Modal bsSize="lg" show={showModal} onHide={onHide} backdrop="static">
        <form onSubmit={handleSubmit(values => onSubmit(values))}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <div className="col-4">
                <Field name="areaCode" component={renderField} placeholder="Area Code" />
              </div>
              <div className="col-4">
                <Field name="contains" component={renderField} placeholder="Contains" />
              </div>
              <div className="col-4">
                <Button icon="search" color="success" onClick={() => onSearch()}>Search</Button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <Loading loaded={itemsFetched}>
                  {size(items) > 0 ? (
                    <Field
                      single
                      name="phone_number"
                      component={renderCheckboxesTable}
                      headers={[
                        'Phone',
                        'Region',
                        'Postal Code',
                        'Voice',
                        'Fax',
                        'SMS',
                        'MMS'
                      ]}
                      data={items.map(item => ({
                        value: item.id,
                        label: [
                          get(item, 'attributes.name'),
                          get(item, 'attributes.region'),
                          get(item, 'attributes.postal_code'),
                          <Boolean value={get(item, 'attributes.capabilities.voice')} />,
                          <Boolean value={get(item, 'attributes.capabilities.fax')} />,
                          <Boolean value={get(item, 'attributes.capabilities.SMS')} />,
                          <Boolean value={get(item, 'attributes.capabilities.MMS')} />
                        ]
                      }))}
                    />
                  ) : <h2>No Records</h2>}
                </Loading>
              </div>
            </div>

            {errorMessage ? (
              <div className="alert alert-danger">
                {errorMessage}
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-group">
              <Button color="secondary" icon="times-circle" onClick={onHide}>
                Cancel
              </Button>
              <Button submit color="success" icon="money">
                Buy Phone Number
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

function validate({ phone_number: phoneNumber }) {
  const errors = {};

  if (!phoneNumber) {
    errors.phone_number = 'Please, select phone number';
  }

  return errors;
}

export default reduxForm({ form: 'buy-phone-number', validate })(BuyPhoneNumberForm);
