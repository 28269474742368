import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchCallQueues } from '../../../actions/CallQueueActions';

import { CallQueuesTable } from '../../../components';
import { LinkTo, Loading } from '../../../helpers';

class CallQueuesIndexPage extends Component {

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired
    })).isRequired,
    itemsMeta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    itemsFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchCallQueues: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchCallQueues(organizationId, { page });
  }

  render() {
    const { match: { params: { organizationId } }, items, itemsFetched, itemsMeta } = this.props;

    return (
      <div id="call-queues">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Call Queues</h3>
          </div>
          <div className="col-md-6 col-4 align-self-center">
            <LinkTo
              button
              href={`organizations/${organizationId}/callQueues/new`}
              color="success"
              addClass="pull-right hidden-sm-down"
            >
              <i className="mdi mdi-plus-circle" /> Create Call Queue
            </LinkTo>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemsFetched}>
                  <CallQueuesTable
                    organizationId={organizationId}
                    items={items}
                    meta={itemsMeta}
                    onPaginate={page => this.fetchData(page)}
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  callQueues: {
    items,
    itemsFetched,
    itemsMeta
  }
}) => ({ items, itemsFetched, itemsMeta }), { fetchOrganization, fetchCallQueues })(CallQueuesIndexPage);
