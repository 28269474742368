import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import compact from 'lodash/compact';
import get from 'lodash/get';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

import {
  Button,
  LinkTo,
  renderField,
  renderRangeSliderField,
  renderEditableTextField,
  renderDropdownList,
  AWSAudioPlayer
} from '../../../helpers';

class CallQueueForm extends Component {

  state = {
    activeMessage: null
  };

  toggleSelectedMessage = id => this.setState({
    activeMessage: first(this.props.messages.filter(msg => msg.id === id))
  });

  componentDidMount() {
    this.toggleSelectedMessage(get(this.props.item, 'relationships.voicemail_message.data.id'));
  }

  render() {
    const {
      formValues,
      callQueues,
      ringGroups,
      messages,
      organizationId,
      item,
      errorMessage,
      handleSubmit,
      onSubmit,
      onAddEntry,
      onRemoveEntry } = this.props;
    const { activeMessage } = this.state;

    return (
      <form
        onSubmit={handleSubmit(values =>
          onSubmit(
            isEmpty(item)
              ? values
              : {
                ...values,
                entries: compact(
                  (values.entries || []).map(entry => ({
                    attributes: {
                      ring_group: entry.ring_group,
                      timeout_sec: entry.timeout
                    }
                  }))
                )
              }
          )
        )}
      >
        <div className="row">
          <div className="col-12">
            <Field name="name" label="Name" component={renderField} />
            <Field
              name="description"
              label="Description"
              component={renderEditableTextField}
              isEditing={isEmpty(item) || !formValues.description}
            />
            <Field
              name="max_reserve_workers"
              label="How many agents should be notified of an incoming call?"
              type="number"
              min={1}
              max={50}
              component={renderField}
            />
            <Field
              name="task_reservation_timeout"
              label="How long should a reservation stay open before moving on to the next fallthrough?"
              type="number"
              min={15}
              component={renderField}
            />

            <Field name="business_hours" label="Business Hours" component={renderRangeSliderField} />
            <Field
              name="fallthrough_type"
              label="Fallthrough Type"
              component={renderDropdownList}
              data={[
                { label: 'Redirect', value: 'redirect' },
                { label: 'Callback', value: 'callback' },
                { label: 'Voicemail', value: 'voicemail' },
                { label: 'Terminate Call', value: 'terminate' }
              ]}
            />
            {formValues.fallthrough_type === 'redirect' ? (
              <Field
                name="redirect_call_queue_id"
                label="Redirect Call Queue"
                component={renderDropdownList}
                data={callQueues.map(callQueue => ({
                  label: callQueue.name,
                  value: callQueue.id
                }))}
              />
            ) : null}

            {formValues.fallthrough_type === 'voicemail' && (
              <Field
                name="voicemail_message_id"
                label="Select Prerecorded Message"
                component={renderDropdownList}
                data={messages.map(message => ({
                  value: message.id,
                  label: `${get(message, 'attributes.name')} |  type: ${get(message, 'attributes.type')}`
                }))}
                clearable={true}
                afterChange={value => this.toggleSelectedMessage(value)}
              />
            )}
            {activeMessage &&
              get(activeMessage, 'attributes.file_url') &&
              get(activeMessage, 'attributes.type') === 'audio' ? (
                <div className="col-xs-12 clearfix" style={{ marginBottom:20 }}>
                  <AWSAudioPlayer
                    key={activeMessage.id}
                    streamUrl={get(activeMessage, 'attributes.file_url')}
                    trackTitle={get(activeMessage, 'attributes.name')}
                    preloadType="metadata"
                  />
                </div>
              ) : null}

            {!isEmpty(item) ? <label>Queue Configuration</label> : null}

            {!isEmpty(item) ? (
              <div className="row">
                <div className="col-12">
                  {get(formValues, 'entries', []).map(entry => (
                    <div key={entry.id} className="row">
                      <div className="col-1">
                        <label>&nbsp;</label>
                        <div>{entry.id + 1}</div>
                      </div>
                      <div className="col-6">
                        <Field
                          name={`entries[${entry.id}][ring_group]`}
                          label="Ring Group"
                          component={renderDropdownList}
                          data={ringGroups.map(ringGroup => ({
                            label: ringGroup.name,
                            value: ringGroup.id
                          }))}
                        />
                      </div>
                      <div className="col-2">
                        <Field
                          name={`entries[${entry.id}][timeout]`}
                          label="Timeout"
                          type="number"
                          min={0}
                          component={renderField}
                        />
                      </div>
                      <div className="col-3">
                        <label>&nbsp;</label>
                        <div>
                          <Button icon="trash" color="outline-danger" onClick={() => onRemoveEntry(entry)}>
                            Remove
                        </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {!isEmpty(item) ? (
              <div className="row">
                <div className="col-12">
                  <Button icon="plus" color="outline-success" onClick={() => onAddEntry()}>
                    Add More
                </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {errorMessage ? <div className="alert alert-danger m-t-20">{errorMessage}</div> : null}

        <div className="form-group m-t-20">
          <div className="btn-group col-xs-12">
            <LinkTo button color="secondary" icon="times-circle" href={`organizations/${organizationId}/callQueues`}>
              Cancel
          </LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Call Queue' : 'Create Call Queue'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

CallQueueForm.propTypes = {
  organizationId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number
  }),
  formValues: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    business_hours: PropTypes.shape(),
    fallthrough_type: PropTypes.string,
    redirect_call_queue_sid: PropTypes.string
  }),
  callQueues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  ringGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  errorMessage: PropTypes.string,
  onAddEntry: PropTypes.func,
  onRemoveEntry: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

CallQueueForm.defaultProps = {
  item: null,
  errorMessage: null,
  formValues: {},
  ringGroups: [],
  onAddEntry: null,
  onRemoveEntry: null
};

function validate({ name, task_reservation_timeout: reservationTimeout, fallthrough_type: fallthroughType, entries }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please enter a name';
  }

  if (!fallthroughType) {
    errors.fallthrough_type = 'Please, select Fallthrough Type';
  }

  (entries || []).map(entry => {
    if (!entry.timeout) {
      if (!errors.entries) {
        errors.entries = {};
      }
      if (!errors.entries[entry.id]) {
        errors.entries[entry.id] = {};
      }
      errors.entries[entry.id].timeout = 'Please, enter timeout';
    }

    if (entry.timeout % reservationTimeout) {
      if (!errors.entries) {
        errors.entries = {};
      }
      if (!errors.entries[entry.id]) {
        errors.entries[entry.id] = {};
      }
      errors.entries[entry.id].timeout =
        'The fallthrough timeout must be a multiple or equal to the task reservation timeout';
    }

    if (!entry.ring_group) {
      if (!errors.entries) {
        errors.entries = {};
      }
      if (!errors.entries[entry.id]) {
        errors.entries[entry.id] = {};
      }
      errors.entries[entry.id].ring_group = 'Please, select Ring Group';
    }
  });

  return errors;
}

export default reduxForm({ form: 'call-queue', validate })(CallQueueForm);
