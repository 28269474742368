import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import {
  UPDATE_ORGANIZATION_START,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
} from '../../constants/Organization';

// updateOrganization

export function updateOrganizationStart() {
  return {
    type: UPDATE_ORGANIZATION_START,
  };
}

export function updateOrganizationSuccess(item = {}) {
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    item,
  };
}

export function updateOrganizationError(error) {
  return {
    type: UPDATE_ORGANIZATION_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function updateOrganization(organizationId, values) {
  return dispatch => {
    dispatch(updateOrganizationStart());

    return apiRequest
      .put(`organizations/${organizationId}`, omit(values, 'subdomain'))
      .then(({ data }) => {
        dispatch(updateOrganizationSuccess(data));
        dispatch(push('/organizations'));
      })
      .catch(error => dispatch(updateOrganizationError(error)));
  };
}
