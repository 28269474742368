import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_RING_GROUP_MEMBER_START,
  CREATE_RING_GROUP_MEMBER_SUCCESS,
  CREATE_RING_GROUP_MEMBER_ERROR
} from '../../constants/RingGroupMember';

// createRingGroupMember

export function createRingGroupMemberStart() {
  return {
    type: CREATE_RING_GROUP_MEMBER_START
  };
}

export function createRingGroupMemberSuccess(item = {}) {
  return {
    type: CREATE_RING_GROUP_MEMBER_SUCCESS,
    item
  };
}

export function createRingGroupMemberError(error) {
  return {
    type: CREATE_RING_GROUP_MEMBER_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function createRingGroupMember(organizationId, ringGroupTag, username) {
  return dispatch => {
    dispatch(createRingGroupMemberStart());

    return apiRequest
      .post(`organizations/${organizationId}/ring_groups/${ringGroupTag}/members/${username}`)
      .then(({ data: { data } }) => Promise.resolve(dispatch(createRingGroupMemberSuccess(data))))
      .catch(error => Promise.reject(dispatch(createRingGroupMemberError(error))));
  };
}
