import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_CALL_FLOW_START,
  CREATE_CALL_FLOW_SUCCESS,
  CREATE_CALL_FLOW_ERROR
} from '../../constants/CallFlow';

// createCallFlow

export function createCallFlowStart() {
  return {
    type: CREATE_CALL_FLOW_START
  };
}

export function createCallFlowSuccess(item = {}) {
  return {
    type: CREATE_CALL_FLOW_SUCCESS,
    item
  };
}

export function createCallFlowError(error) {
  return {
    type: CREATE_CALL_FLOW_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function createCallFlow(organizationId, values) {
  return dispatch => {
    dispatch(createCallFlowStart());

    return apiRequest.post(`organizations/${organizationId}/call_flows`, values)
      .then(({ data }) => Promise.resolve(dispatch(createCallFlowSuccess(data))))
      .catch(error => Promise.reject(dispatch(createCallFlowError(error))));
  };
}
