import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import without from 'lodash/without';

import { Button } from '../helpers';

function renderCheckboxesTable({ single, label, headers, data, input, meta: { touched, error, warning } }) {
  return (
    <div
      className={cl('form-group', {
        'has-success': touched && !error && !warning,
        'has-danger': touched && error,
        'has-warning': touched && warning
      })}
    >
      {label ? (
        <div>
          <label htmlFor={`form-input-${input.name}`} className="form-control-label">{label}</label>
        </div>
      ) : null}

      <table className="table">
        {headers ? (
          <thead>
            <tr>
              {headers.map(header => <th key={header}>{header}</th>)}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {data.map(({ label: optionLabel, value }) => (
            <tr key={value}>
              {isArray(optionLabel) ? optionLabel.map((item, index) => (
                <td key={`${item}-${index}`}>
                  {item}
                </td>
              )) : <td>{optionLabel}</td>}
              <td>
                <Button
                  size="sm"
                  icon="check"
                  color={includes(input.value, value) ? 'success' : 'secondary'}
                  onClick={() => {
                    if (single) {
                      return input.onChange(input.value === value ? null : value);
                    }

                    if (includes(input.value, value)) {
                      return input.onChange(without(input.value, value));
                    }

                    return input.onChange([...input.value, value]);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderCheckboxesTable.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  single: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
  })).isRequired
};

renderCheckboxesTable.defaultProps = {
  label: null,
  single: false,
  headers: null
};

export default renderCheckboxesTable;
