import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { SHOW_CALL_FLOW_START, SHOW_CALL_FLOW_SUCCESS, SHOW_CALL_FLOW_ERROR } from '../../constants/CallFlow';

// fetchCallFlow

export function showCallFlowStart() {
  return {
    type: SHOW_CALL_FLOW_START
  };
}

export function showCallFlow(item = {}) {
  return {
    type: SHOW_CALL_FLOW_SUCCESS,
    item
  };
}

export function showCallFlowError(error) {
  return {
    type: SHOW_CALL_FLOW_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchCallFlow(callFlowId, options = {}) {
  return dispatch => {
    dispatch(showCallFlowStart());

    return apiRequest
      .get(`call_flows/${callFlowId}`, options)
      .then(({ data: { data: item } }) => Promise.resolve(dispatch(showCallFlow(item))))
      .catch(error => Promise.reject(dispatch(showCallFlowError(error))));
  };
}
