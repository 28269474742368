import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import size from 'lodash/size';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchCallFlow } from '../../../actions/CallFlowActions';
import {
  createCallFlowEntry as createCallFlowEntryAction,
  changeCallFlowEntry as changeCallFlowEntryAction,
  copyCallFlowEntry as copyCallFlowEntryAction,
  removeCallFlowEntry as removeCallFlowEntryAction,
  changeCallFlowEntries as changeCallFlowEntriesAction,
  updateCallFlowEntries as updateCallFlowEntriesAction
} from '../../../actions/CallFlowEntryActions';
import { fetchCallQueues } from '../../../actions/CallQueueActions';
import { fetchMessages as fetchMessagesAction } from '../../../actions/MessageActions';

import { CallFlowEntries } from '../../../components';
import { Button, CrudTabs, LinkTo, Loading } from '../../../helpers';

class CallFlowEntriesPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        callFlowId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,

    callFlow: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    callFlowFetched: PropTypes.bool.isRequired,
    callFlowErrorMessage: PropTypes.string,

    callQueues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        attributes: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired,
    callQueuesFetched: PropTypes.bool.isRequired,
    callQueuesErrorMessage: PropTypes.string,

    callFlowEntriesFetched: PropTypes.bool.isRequired,
    callFlowEntriesErrorMessage: PropTypes.string,

    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        attributes: PropTypes.shape({
          name: PropTypes.string.isRequired
        }).isRequired
      })
    ).isRequired,
    messagesFetched: PropTypes.bool.isRequired,
    messagesErrorMessage: PropTypes.string,

    fetchCallFlow: PropTypes.func.isRequired,
    fetchCallQueues: PropTypes.func.isRequired,
    createCallFlowEntry: PropTypes.func.isRequired,
    changeCallFlowEntry: PropTypes.func.isRequired,
    copyCallFlowEntry: PropTypes.func.isRequired,
    removeCallFlowEntry: PropTypes.func.isRequired,
    changeCallFlowEntries: PropTypes.func.isRequired,
    updateCallFlowEntries: PropTypes.func.isRequired
  };

  static defaultProps = {
    callFlowErrorMessage: null,
    callQueuesErrorMessage: null,
    callFlowEntriesErrorMessage: null,
    messagesErrorMessage: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const {
      match: {
        params: { organizationId, callFlowId }
      }
    } = this.props;

    this.props.fetchCallFlow(callFlowId);
    this.props.fetchMessages(organizationId, { page });
    this.props.fetchCallQueues(organizationId, { page });
  }

  render() {
    const {
      match: {
        params: { organizationId, callFlowId }
      },
      callFlow,
      callFlowFetched,
      callFlowErrorMessage,
      callQueues,
      callQueuesFetched,
      callQueuesErrorMessage,
      callFlowEntries,
      callFlowEntriesFetched,
      callFlowEntriesErrorMessage,
      callFlowEntriesSuccessMessage,
      createCallFlowEntry,
      changeCallFlowEntry,
      copyCallFlowEntry,
      removeCallFlowEntry,
      changeCallFlowEntries,
      updateCallFlowEntries,
      messages,
      messagesMeta,
      messagesFetched,
      messagesErrorMessage
    } = this.props;

    return (
      <div id="call-flow-show">
        <div className="row page-titles">
          {callFlowEntriesSuccessMessage ? <div className="alert alert-success">{callFlowEntriesSuccessMessage}</div> : null}
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">
              {isEmpty(callFlow) ? 'Call Flow' : `Call Flow: ${callFlow.id} | ${get(callFlow, 'attributes.name')}`}
              &nbsp;-&nbsp; Call Flow Entries
            </h3>
          </div>
          <div className="col-md-6 col-4">
            <div className="btn-group pull-right">
              <Button
                color="secondary"
                addClass="pull-right hidden-sm-down"
                disabled={
                  size(filter(keys(callFlowEntries).map(key => callFlowEntries[key]), ({ expanded }) => expanded)) > 0
                }
                onClick={() => changeCallFlowEntries({ expanded: true })}
              >
                <i className="mdi mdi-plus-circle" /> Expand All
              </Button>
              <Button
                color="secondary"
                addClass="pull-right hidden-sm-down"
                disabled={
                  size(filter(keys(callFlowEntries).map(key => callFlowEntries[key]), ({ expanded }) => !expanded)) > 0
                }
                onClick={() => changeCallFlowEntries({ expanded: false })}
              >
                <i className="mdi mdi-minus-circle" /> Collapse All
              </Button>
              <Button
                color="primary"
                addClass="pull-right hidden-sm-down"
                onClick={() => updateCallFlowEntries(callFlowId, callFlowEntries)}
              >
                <i className="mdi mdi-plus-circle" /> Save Updates
              </Button>
            </div>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/callFlows`}
          id={callFlowId}
          active="entries"
          afterTabs={[
            <LinkTo
              icon="list"
              className="nav-link active"
              href={`organizations/${organizationId}/callFlows/${callFlow.id}/entries`}
            >
              Call Flow Entries
            </LinkTo>
          ]}
        />

        <div className="row">
          <div className="col-12">
            {callFlowErrorMessage || callQueuesErrorMessage || callFlowEntriesErrorMessage || messagesErrorMessage ? (
              <div className="alert alert-danger">
                {callFlowErrorMessage || callQueuesErrorMessage || callFlowEntriesErrorMessage || messagesErrorMessage}
              </div>
            ) : null}

            <Loading loaded={callFlowFetched && callQueuesFetched && callFlowEntriesFetched && messagesFetched}>
              <CallFlowEntries
                entries={keys(callFlowEntries).map(key => callFlowEntries[key])}
                callQueues={callQueues}
                messages={messages}
                onCreate={action => createCallFlowEntry(action)}
                onChange={(id, name, value) => changeCallFlowEntry(id, name, value)}
                onCopy={entry => copyCallFlowEntry(entry)}
                onRemove={entry => removeCallFlowEntry(entry)}
                onScrollToAction={entryId =>
                  scroller.scrollTo(entryId, {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: 50
                  })
                }
              />
            </Loading>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    callFlows: { item: callFlow, itemFetched: callFlowFetched, itemErrorMessage: callFlowErrorMessage },
    callQueues: { items: callQueues, itemsFetched: callQueuesFetched, itemsErrorMessage: callQueuesErrorMessage },
    callFlowEntries: {
      items: callFlowEntries,
      itemsFetched: callFlowEntriesFetched,
      itemsErrorMessage: callFlowEntriesErrorMessage,
      itemsSuccessMessage: callFlowEntriesSuccessMessage
    },
    messages: {
      items: messages,
      itemsMeta: messagesMeta,
      itemsFetched: messagesFetched,
      itemsErrorMessage: messagesErrorMessage
    }
  }) => ({
    callFlow,
    callFlowFetched,
    callFlowErrorMessage,

    callFlowEntries,
    callFlowEntriesFetched,
    callFlowEntriesErrorMessage,
    callFlowEntriesSuccessMessage,

    callQueues,
    callQueuesFetched,
    callQueuesErrorMessage,

    messages,
    messagesFetched,
    messagesErrorMessage
  }),
  {
    fetchCallFlow,
    fetchCallQueues,
    createCallFlowEntry: createCallFlowEntryAction,
    changeCallFlowEntry: changeCallFlowEntryAction,
    copyCallFlowEntry: copyCallFlowEntryAction,
    changeCallFlowEntries: changeCallFlowEntriesAction,
    removeCallFlowEntry: removeCallFlowEntryAction,
    updateCallFlowEntries: updateCallFlowEntriesAction,
    fetchMessages: fetchMessagesAction
  }
)(CallFlowEntriesPage);
