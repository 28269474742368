import React from 'react';
import { RequireAuth } from '../../utils';
import { Route } from 'react-router-dom';
import Requests from './Requests';

export default [
  <Route
    exact
    key="requests"
    path="/requests"
    component={RequireAuth(Requests)}
  />,
];
