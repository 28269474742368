import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import CallsReportPage from './CallsReportPage/CallsReportPage';
import CallsHistoryPage from './CallsHistoryPage/CallsHistoryPage';


export default [
  <Route
    exact
    key="calls"
    path="/organizations/:organizationId/calls"
    component={RequireAuth(CallsHistoryPage)}
  />,
  <Route
  exact
  key="live"
  path="/organizations/:organizationId/calls/live"
  component={RequireAuth(CallsReportPage)}
/>
];
