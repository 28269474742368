import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cl from 'classnames';
import get from 'lodash/get';
import size from 'lodash/size';

import { Button, Paginate } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';

import { MonitorControls } from '../../../components';

class LiveCallsTable extends Component {
  getDirection(call) {
    const direction = get(call, 'attributes.direction');

    return direction;
  }

  getAgentOrPhone(call, column) {
    const direction = get(call, 'attributes.direction');
    const callToAgent = get(
      call,
      'relationships.agent.data.attributes.username'
    );
    const callToType = get(call, 'relationships.to.data.type');
    const callToId = get(call, 'relationships.to.data.id');
    const callFromId = get(call, 'relationships.from.data.id');
    const fromUsername = get(
      call,
      'relationships.from.data.attributes.username'
    );
    const toUsername = get(call, 'relationships.to.data.attributes.username');

    if (column === 'from') {
      if (direction === 'outbound') {
        if (callToType === 'phone_numbers' || callToType === 'user_roles') {
          return fromUsername;
        }
      }
      return callFromId;
    }

    if (column === 'to') {
      if (direction === 'inbound') {
        if (callToType === 'user_roles') {
          return toUsername;
        }
        if (callToAgent) {
          return callToAgent;
        }
      }
      return callToId;
    }
    return 'na';
  }

  render() {
    const {
      activeCall,
      isActive,
      onMonitorCall,
      isMonitoring,
      monitoringMode,
      items,
      meta,
      onPaginate,
      organizationId,
    } = this.props;

    if (size(items) === 0) {
      return <h1>No Records</h1>;
    }

    return (
      <div style={{ overflowX: 'auto' }}>
        <table className="table table-hover footable-loaded footable">
          <thead>
            <tr>
              <th className="footable-sortable">
                Direction
                <span className="footable-sort-indicator" />
              </th>
              <th className="footable-sortable">
                Status
                <span className="footable-sort-indicator" />
              </th>

              <th className="footable-sortable">
                From
                <span className="footable-sort-indicator" />
              </th>
              <th className="footable-sortable">
                To
                <span className="footable-sort-indicator" />
              </th>
              <th className="footable-sortable">
                Started
                <span className="footable-sort-indicator" />
              </th>
              {/* <th className="footable-sortable">
              Ended
              <span className="footable-sort-indicator" />
            </th> */}
              <th className="footable-sortable">Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr
                key={item.id}
                className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}
              >
                <td>
                  {get(item, 'attributes.direction') === 'inbound' ? (
                    <i className="fa fa-arrow-down alert-success" />
                  ) : (
                    <i className="fa fa-arrow-up alert-warning" />
                  )}
                </td>
                <td>
                  <div
                    className={cl('label label-table', {
                      'label-success':
                        get(item, 'attributes.status') !== 'completed',
                      'label-danger':
                        get(item, 'attributes.status') === 'completed',
                    })}
                  >
                    {get(item, 'attributes.status')}
                  </div>
                </td>

                {/* <td>{this.getDirection(item)}</td> */}
                <td>{this.getAgentOrPhone(item, 'from')}</td>
                <td>{this.getAgentOrPhone(item, 'to')}</td>

                <td>
                  {new Date(
                    get(item, 'attributes.started_at')
                  ).toLocaleTimeString('en-US')}
                </td>
                {/* <td>{renderHumanDateTime(get(item, 'attributes.ended_at'))}</td> */}
                <td>
                  {get(item, 'attributes.status') !== 'completed' &&
                  get(activeCall, 'id') !== item.id ? (
                    <Button
                      color="secondary"
                      size="xs"
                      onClick={() => onMonitorCall(item.id)}
                    >
                      Monitor Call
                    </Button>
                  ) : null}
                  {isMonitoring && get(activeCall, 'id') === item.id ? (
                    <MonitorControls
                      call={activeCall}
                      organizationId={organizationId}
                      showMonitoringControls={isMonitoring}
                      monitoringMode={monitoringMode}
                      isActive={isActive}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}>
                <Paginate
                  count={meta.count}
                  pageSize={meta.pageSize}
                  activePage={meta.page}
                  onSelect={page => onPaginate(page)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

LiveCallsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number,
  }).isRequired,
  onPaginate: PropTypes.func.isRequired,
  onMonitorCall: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isMonitoring: PropTypes.bool,
  monitoringMode: PropTypes.string,
  activeCall: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
};

export default connect(
  ({ calls: { data: activeCall, isMonitoring, monitoringMode } }) => ({
    activeCall,
    isMonitoring,
    monitoringMode,
  }),
  null
)(LiveCallsTable);
