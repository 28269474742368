import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import omit from "lodash/omit";

import {
  fetchVoicemails as fetchVoicemailsAction,
  updateVoicemail as updateVoicemailAction
} from "../../../actions/VoicemailActions";
import { fetchPhoneNumbers as fetchPhoneNumbersAction } from "../../../actions/PhoneNumberActions";
import { fetchRingGroups } from "../../../actions/RingGroupActions";
import { fetchCallFlows } from '../../../actions/CallFlowActions';

import { VoicemailsFilterForm } from "../../../components";
import { ErrorMessage } from "../../../helpers";
import { renderHumanDateTime } from '../../../utils';

import { Skeleton, Table, Button, Divider } from "antd";

class VoicemailsIndexPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,

    voicemails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    voicemailsMeta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    voicemailsFetched: PropTypes.bool.isRequired,
    voicemailsErrorMessage: PropTypes.string,

    ringGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isReqiured,
        attributes: PropTypes.shape({
          name: PropTypes.string.isReqiured
        }).isRequired
      })
    ).isRequired,
    ringGroupsFetched: PropTypes.bool.isRequired,
    ringGroupsErrorMessage: PropTypes.string,

    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isReqiured,
        attributes: PropTypes.shape({
          name: PropTypes.string.isReqiured
        }).isRequired
      })
    ).isRequired,
    phoneNumbersFetched: PropTypes.bool.isRequired,
    phoneNumbersErrorMessage: PropTypes.string,

    fetchRingGroups: PropTypes.func.isRequired,
    fetchPhoneNumbers: PropTypes.func.isRequired,
    fetchVoicemails: PropTypes.func.isRequired,
    updateVoicemail: PropTypes.func.isRequired,
    fetchCallFlows: PropTypes.func.isRequired,
  };

  static defaultProps = {
    voicemailsErrorMessage: null,
    ringGroupsErrorMessage: null,
    phoneNumbersErrorMessage: null
  };

  state = {
    pagination: {},
    filters: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const {
      match: {
        params: { organizationId }
      },
      voicemailsMeta
    } = this.props;


    this.props
      .fetchVoicemails(organizationId, { status: "*", page, ...voicemailsMeta })
      .then(result => {
        this.setState({
          pagination: { ...result.meta, total: result.meta.count }
        });
      });
    this.props.fetchPhoneNumbers(organizationId, { page });
    this.props.fetchRingGroups(organizationId, { page });
    this.props.fetchCallFlows(organizationId, { page })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.page = pagination.current;
    pager.current = pagination.current;

    this.setState({
      pagination: pager
    });

    this.props
      .fetchVoicemails(this.props.match.params.organizationId, { ...omit(this.props.voicemailsMeta, [
        'pageSize',
        'count',
        'pageCount',
        'page_count',
        'page_size',
        'count',
      ]), ...pager });
  };

  render() {
    const {
      match: {
        params: { organizationId }
      },
      voicemails,
      voicemailsFetched,
      voicemailsMeta,
      voicemailsErrorMessage,
      callFlows,
      ringGroups,
      ringGroupsFetched,
      ringGroupsErrorMessage,
      phoneNumbers,
      phoneNumbersFetched,
      phoneNumbersErrorMessage,
      fetchVoicemails,
      updateVoicemail,
      organization
    } = this.props;

    const columns = [
      {
        title: "Created At",
        dataIndex: "attributes.created_at",
        render: (text, record) => renderHumanDateTime(get(record, 'attributes.created_at'))
      },
      {
        title: "Duration",
        dataIndex: "attributes.duration"
      },
      {
        title: "From",
        dataIndex: "relationships.call.data.relationships.from.data.id"
      },
      {
        title: "To",
        dataIndex:
          "relationships.call.data.relationships.to.data.attributes.name",
        render: (text, record) => {
          if (get(record, "relationships.user_role.data")) {
            return get(
              record,
              "relationships.user_role.data.attributes.username"
            );
          } else if (
            get(
              record,
              "relationships.call.data.relationships.to.data.type"
            ) === "phone_numbers"
          ) {
            return get(
              record,
              "relationships.call.data.relationships.to.data.id"
            );
          }
          return get(
            record,
            "relationships.call.data.relationships.to.data.attributes.name"
          );
        }
      },
      {
        title: "Status",
        dataIndex: "attributes.status"
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button icon="play-circle" href={get(record, 'attributes.recording_url')} target="_blank">Play</Button>
            <Divider type="vertical" />
            { get(record, 'attributes.status') === 'seen' ? (<Button icon="eye" onClick={() => updateVoicemail(organizationId, record.id, 'unseen')}>Mark as unseen</Button>) : (<Button icon="eye" onClick={() => updateVoicemail(organizationId, record.id, 'seen')}>Mark as seen</Button>)}
            <Divider type="vertical" />
            <Button icon="download" onClick={() => updateVoicemail(organizationId, record.id, 'archived')} disabled={record.attributes.status === 'archived'}>Archive</Button>
          </span>
        ),
      }
    ];

    return (
      <div id="voicemails">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Voicemails</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <ErrorMessage>
                  {voicemailsErrorMessage ||
                    ringGroupsErrorMessage ||
                    phoneNumbersErrorMessage}
                </ErrorMessage>
                <Skeleton loading={!voicemailsFetched}>
                <VoicemailsFilterForm
                    initialValues={voicemailsMeta}
                    ringGroups={ringGroups.map(ringGroup => ({
                      value: ringGroup.id,
                      label: get(ringGroup, "attributes.name")
                    }))}
                    phoneNumbers={phoneNumbers.map(phoneNumber => ({
                      value: phoneNumber.id,
                      label: [
                        get(phoneNumber, "attributes.name"),
                        get(phoneNumber, "attributes.description", "")
                      ].join(" | ")
                    }))}
                    callFlows={callFlows.map(flow => ({
                      value: flow.id,
                      label: flow.attributes.name
                    }))}
                    agents={get(
                      organization,
                      "relationships.user_roles.data",
                      []
                    ).map(userRole => ({
                      value: get(userRole, "attributes.username"),
                      label: [
                        get(userRole, "attributes.first_name"),
                        get(userRole, "attributes.last_name")
                      ].join(" ")
                    }))}
                    onSubmit={values => {
                      fetchVoicemails(organizationId, {
                        ...voicemailsMeta,
                        ...values
                      })
                    }
                     
                    }
                    
                    onClear={() =>
                      fetchVoicemails(organizationId, { status: "*", page: 1 })
                    }
                  />
                  <Table
                    columns={columns}
                    dataSource={voicemails}
                    loading={!voicemailsFetched}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    expandedRowRender={record => (
                      <p style={{ margin: 0, padding: 10 }}>
                        {get(record, "attributes.transcription_text")}
                      </p>
                    )}
                  />
                </Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    organizations: { item: organization },
    voicemails: {
      voicemails,
      voicemailsFetched,
      voicemailsMeta,
      voicemailsErrorMessage
    },
    ringGroups: {
      items: ringGroups,
      itemsFetched: ringGroupsFetched,
      itemsErrorMessage: ringGroupsErrorMessage
    },
    phoneNumbers: {
      items: phoneNumbers,
      itemsFetched: phoneNumbersFetched,
      itemsErrorMessage: phoneNumbersErrorMessage
    },
    callFlows: {
      items: callFlows,
    }
  }) => ({
    organization,

    voicemails,
    voicemailsFetched,
    voicemailsMeta,
    voicemailsErrorMessage,

    ringGroups,
    ringGroupsFetched,
    ringGroupsErrorMessage,

    phoneNumbers,
    phoneNumbersFetched,
    phoneNumbersErrorMessage,
    
    callFlows
  }),
  {
    fetchRingGroups,
    fetchPhoneNumbers: fetchPhoneNumbersAction,
    fetchCallFlows,
    fetchVoicemails: fetchVoicemailsAction,
    updateVoicemail: updateVoicemailAction
  }
)(VoicemailsIndexPage);
