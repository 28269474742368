import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { LinkTo, Paginate } from '../../../helpers';

function UsersTable({ organizationId, items, meta, onPaginate }) {

  if (size(items) === 0) {
    return <h1>No Records</h1>;
  }

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
      <tr>
      <th className="footable-sortable">
          First Name
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Last Name
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Username
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Extension
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Email
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Role
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Ring Groups
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">Actions</th>
      </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item.id} className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}>
          <td>
          {get(item, 'attributes.first_name')}
            </td>
            <td>
            {get(item, 'attributes.last_name')}
              </td>
            <td>
              <LinkTo href={`organizations/${organizationId}/users/${get(item, 'relationships.user_role.data.attributes.username')}/show`}>
                {get(item, 'relationships.user_role.data.attributes.username')}
              </LinkTo>
            </td>
            <td>
              {get(item, 'relationships.user_role.data.attributes.extension')}
            </td>
            <td>
              {get(item, 'attributes.email')}
            </td>
            <td>
              {get(item, 'relationships.user_role.data.attributes.role')}
            </td>
            <td>
              {get(item, 'relationships.ring_groups.data', []).map((ringGroup, i) => (
                <span key={ringGroup.id}>
                  {!!i && ' / '}
                  <LinkTo
                    href={[
                      'organizations',
                      organizationId,
                      'ringGroups',
                      ringGroup.id,
                      'show'
                    ].join('/')}
                  >
                    {get(ringGroup, 'attributes.name')}
                  </LinkTo>
                </span>
              ))}
            </td>
            <td>
              <div className="btn-group">
                <LinkTo
                  button
                  color="outline-success"
                  icon="search"
                  href={`organizations/${organizationId}/users/${item.relationships.user_role.data.attributes.username}/show`}
                >
                  Show
                </LinkTo>
                <LinkTo
                  button
                  color="outline-info"
                  icon="pencil"
                  href={`organizations/${organizationId}/users/${item.relationships.user_role.data.attributes.username}/edit`}
                >
                  Edit
                </LinkTo>
                <LinkTo
                  button
                  color="outline-danger"
                  icon="trash"
                  href={`organizations/${organizationId}/users/${item.relationships.user_role.data.attributes.username}/delete`}
                >
                  Delete
                </LinkTo>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={5}>
            <Paginate
              count={meta.count}
              pageSize={meta.pageSize}
              activePage={meta.page}
              onSelect={page => onPaginate(page)}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

UsersTable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number
  }).isRequired,
  onPaginate: PropTypes.func.isRequired
};

export default UsersTable;
