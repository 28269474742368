import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_BLACKLIST_START,
  DELETE_BLACKLIST_SUCCESS,
  DELETE_BLACKLIST_ERROR,
} from '../../constants/Blacklist';

export function deleteBlacklistStart() {
  return {
    type: DELETE_BLACKLIST_START,
  };
}

export function deleteBlacklistSuccess(item = {}) {
  return {
    type: DELETE_BLACKLIST_SUCCESS,
    item,
  };
}

export function deleteBlacklistError(error) {
  return {
    type: DELETE_BLACKLIST_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function deleteBlacklist(organizationId, id) {
  return dispatch => {
    dispatch(deleteBlacklistStart());

    return apiRequest
      .delete(`organizations/${organizationId}/blacklist/${id}`)
      .then(({ data }) => {
        dispatch(deleteBlacklistSuccess(data));
      })
      .catch(error => dispatch(deleteBlacklistError(error)));
  };
}
