import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import MessagesIndexPage from './MessagesIndexPage/MessagesIndexPage';
import MessageAddPage from './MessageAddPage/MessageAddPage';
import MessageShowPage from './MessageShowPage/MessageShowPage';
import MessageEditPage from './MessageEditPage/MessageEditPage';
import MessageDeletePage from './MessageDeletePage/MessageDeletePage';

export default [
  <Route
    exact
    key="organization-messages"
    path="/organizations/:organizationId/messages"
    component={RequireAuth(MessagesIndexPage)}
  />,
  <Route
    exact
    key="organization-message-new"
    path="/organizations/:organizationId/messages/new"
    component={RequireAuth(MessageAddPage)}
  />,
  <Route
    exact
    key="organization-message-show"
    path="/organizations/:organizationId/messages/:messageId/show"
    component={RequireAuth(MessageShowPage)}
  />,
  <Route
    exact
    key="organization-message-edit"
    path="/organizations/:organizationId/messages/:messageId/edit"
    component={RequireAuth(MessageEditPage)}
  />,
  <Route
    exact
    key="organization-message-delete"
    path="/organizations/:organizationId/messages/:messageId/delete"
    component={RequireAuth(MessageDeletePage)}
  />
];
