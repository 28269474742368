import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import get from 'lodash/get';

import { Button, LinkTo, renderCheckbox, renderField, renderDropdownList } from '../../../helpers';

class PhoneNumberForm extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string
    }).isRequired,
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null
  };

  state = {
    activeTab: get(this.props.item, 'relationships.user_role.data.attributes.username') ? 1 : 0
  };

  render() {
    const { callFlows, ringGroups, users, item, errorMessage, handleSubmit, onSubmit } = this.props;
    const { activeTab } = this.state;

    return (
      <form
        onSubmit={handleSubmit(values => onSubmit({
          ...(activeTab === 1 ? { username: values.username } : { call_flow_id: values.call_flow_id }),
          default: values.default,
          description: values.description,
          outgoing_ring_group: values.outgoing_ring_group
        }))}
      >

        <Tabs
          className="nav-pills"
          id="phone-number-tabs"
          selected={activeTab}
          onSelect={tabKey => this.setState({ activeTab: tabKey })}
        >
          <Tab eventKey={0} label="Call Flow">
            <Field
              addClass="m-t-20"
              name="call_flow_id"
              label="Call Flow"
              component={renderDropdownList}
              data={callFlows.map(callFlow => ({
                value: callFlow.id,
                label: get(callFlow, 'attributes.name')
              }))}
            />
          </Tab>
          <Tab eventKey={1} label="Username">
            <Field
              addClass="m-t-20"
              name="username"
              label="Username"
              component={renderDropdownList}
              data={users.map(user => ({
                value: get(user, 'relationships.user_role.data.attributes.username'),
                label: get(user, 'relationships.user_role.data.attributes.username')
              }))}
            />
          </Tab>
        </Tabs>

        <Field name="default" label="Default" component={renderCheckbox} />
        <Field name="description" label="Description" component={renderField} />
        <Field
          addClass="m-t-20"
          name="outgoing_ring_group"
          label="Available to which parent ring group?"
          component={renderDropdownList}
          data={ringGroups.map(ringGroup => ({
            value: get(ringGroup, 'id'),
            label: get(ringGroup, 'attributes.name')
          }))}
        />

        {errorMessage ? (
          <div className="alert alert-danger">
            {errorMessage}
          </div>
        ) : null}

        <div className="form-group m-t-20">
          <div className="btn-group col-xs-12">
            <LinkTo button color="secondary" icon="times-circle" href="organizations">Cancel</LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Phone Number' : 'Create Phone Number'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({ form: 'organization' })(PhoneNumberForm);
