import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchFavorite,
  deleteFavorite as deleteFavoriteAction,
} from '../../../actions/FavoriteActions';

import { FavoriteDelete } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class FavoriteDeletePage extends Component {
  static propTypes = {
    fetchOrganization: PropTypes.func.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    fetchFavorite: PropTypes.func.isRequired,
    deleteFavorite: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const {
      match: {
        params: { organizationId, id },
      },
    } = this.props;

    this.props.fetchFavorite(organizationId, id);
  }

  render() {
    const {
      match: {
        params: { organizationId, id },
      },
      item,
      itemFetched,
      deleteFavorite,
      push,
    } = this.props;

    return (
      <div id="user-delete">
        <div className="row page-titles">
          <div className="col-12">
            <h3 className="text-themecolor">Delete Favorite</h3>
          </div>
        </div>

        <CrudTabs
          model={`organizations/${organizationId}/favorites`}
          id={id}
          active="delete"
        />

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemFetched}>
                  <FavoriteDelete
                    organizationId={organizationId}
                    item={item}
                    onDelete={id =>
                      deleteFavorite(organizationId, id).then(() =>
                        push(`/organizations/${organizationId}/favorites`)
                      )
                    }
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ favorites: { item, itemFetched } }) => ({ item, itemFetched }),
  {
    fetchOrganization,
    fetchFavorite,
    deleteFavorite: deleteFavoriteAction,
    push: pushAction,
  }
)(FavoriteDeletePage);
