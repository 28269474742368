import { apiRequest } from '../../utils';

import {
  STOP_MONITORING_CALL_START,
  STOP_MONITORING_CALL_SUCCESS,
  STOP_MONITORING_CALL_ERROR
} from '../../constants/Call';

function stopMonitoringCallStart() {
  return {
    type: STOP_MONITORING_CALL_START
  };
}

function stopMonitoringCallSuccess(data) {
  return {
    type: STOP_MONITORING_CALL_SUCCESS,
    data
  };
}

function stopMonitoringCallError(errorMessage) {
  console.log('stopMonitoringCallError', errorMessage);
  return {
    type: STOP_MONITORING_CALL_ERROR,
    errorMessage
  };
}

export default function stopMonitoringCall(organizationId, id) {
  return dispatch => {
    dispatch(stopMonitoringCallStart());

    return apiRequest
      .delete(`organizations/${organizationId}/agent/calls/${id}`)
      .then(({ data: { data } }) => dispatch(stopMonitoringCallSuccess(data)))
      .catch(err => dispatch(stopMonitoringCallError(err)));
  };
}
