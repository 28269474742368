import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  ADD_BLACKLISTS_START,
  ADD_BLACKLISTS_SUCCESS,
  ADD_BLACKLISTS_ERROR
} from '../../constants/Blacklist';

// fetchUsers

export function addBlacklistsStart() {
  return {
    type: ADD_BLACKLISTS_START
  };
}

export function addBlacklists(items = [], meta = {}) {
  return {
    type: ADD_BLACKLISTS_SUCCESS,
    items,
    meta
  };
}

export function addBlacklistsError(error) {
  return {
    type: ADD_BLACKLISTS_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchBlacklists(organizationId, { page = 1, pageSize = 50 }) {
  return dispatch => {
    dispatch(addBlacklistsStart());
    return apiRequest.get(`organizations/${organizationId}/blacklist`, { page, pageSize })
      .then(({ data: { data: items } }) => dispatch(addBlacklists(items, {
        page,
        pageSize
      })))
      .catch(error => dispatch(addBlacklistsError(error)));
  };
}
