import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { fetchOrganizations } from '../../../actions/OrganizationActions';

import { OrganizationsList } from '../../../components';
import { LinkTo, Loading } from '../../../helpers';

class OrganizationsIndexPage extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    meta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    itemsFetched: PropTypes.bool.isRequired,
    fetchOrganizations: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    this.props.fetchOrganizations({ page });
  }

  render() {
    const { items, itemsFetched, meta, agentSession } = this.props;

    return (
      <div id="organizations">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">What organization do you want to configure?</h3>
          </div>
          <div className="col-md-6 col-4 align-self-center">
            <LinkTo button href="organizations/new" color="success" addClass="pull-right hidden-sm-down">
              <i className="mdi mdi-plus-circle" /> Create Organization
            </LinkTo>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Loading loaded={itemsFetched}>
              <OrganizationsList
                items={items}
                meta={meta}
                onPaginate={page => this.fetchData(page)}
                currentAgentOrg={get(agentSession, 'data.relationships.organization.data.attributes.subdomain')}
              />
            </Loading>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ organizations: { items, itemsFetched, meta }, agentSessions: { session: agentSession } }) => ({
    items,
    itemsFetched,
    meta,
    agentSession
  }),
  {
    fetchOrganizations
  }
)(OrganizationsIndexPage);
