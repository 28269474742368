import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_CALL_QUEUE_START,
  CREATE_CALL_QUEUE_SUCCESS,
  CREATE_CALL_QUEUE_ERROR
} from '../../constants/CallQueue';

// createCallQueue

export function createCallQueueStart() {
  return {
    type: CREATE_CALL_QUEUE_START
  };
}

export function createCallQueueSuccess(item = {}) {
  return {
    type: CREATE_CALL_QUEUE_SUCCESS,
    item
  };
}

export function createCallQueueError(error) {
  return {
    type: CREATE_CALL_QUEUE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function createCallQueue(organizationId, values) {
  return dispatch => {
    dispatch(createCallQueueStart());

    return apiRequest.post(`organizations/${organizationId}/call_queues`, values)
      .then(({ data }) => Promise.resolve(dispatch(createCallQueueSuccess(data))))
      .catch(error => Promise.reject(dispatch(createCallQueueError(error))));
  };
}
