import { apiRequest } from '../../utils';

import { MONITOR_SPEAK_CALL_START, MONITOR_SPEAK_CALL_SUCCESS, MONITOR_SPEAK_CALL_ERROR } from '../../constants/Call';

function monitorSpeakCallStart() {
  return {
    type: MONITOR_SPEAK_CALL_START
  };
}

function monitorSpeakCallSuccess(data) {
  return {
    type: MONITOR_SPEAK_CALL_SUCCESS,
    data
  };
}

function monitorSpeakCallError(errorMessage) {
  return {
    type: MONITOR_SPEAK_CALL_ERROR,
    errorMessage
  };
}

export default function monitorSpeakCall(organizationId, id) {
  return dispatch => {
    dispatch(monitorSpeakCallStart());

    return apiRequest
      .put(`organizations/${organizationId}/agent/calls/${id}`, null, {
        data: {
          attributes: {
            mode: 'speak'
          }
        }
      })
      .then(({ data: { data } }) => dispatch(monitorSpeakCallSuccess(data)))
      .catch(err => dispatch(monitorSpeakCallError(err)));
  };
}
