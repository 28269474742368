import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { push as pushAction } from 'connected-react-router';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { createMessage as createMessageAction } from '../../../actions/MessageActions';
import { uploadFile as uploadFileAction } from '../../../actions/FileActions';

import { MessageForm } from '../../../components';
import { ErrorMessage, Loading } from '../../../helpers';

class MessageAddPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    formValues: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      owner_username: PropTypes.string,
      message: PropTypes.string,
      file_url: PropTypes.string,
    }).isRequired,

    organization: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    organizationFetched: PropTypes.bool.isRequired,
    organizationErrorMessage: PropTypes.string,

    messageLoading: PropTypes.bool.isRequired,
    messageErrorMessage: PropTypes.string,

    fileLoading: PropTypes.bool.isRequired,
    fileErrorMessage: PropTypes.string,

    fetchOrganization: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    organizationErrorMessage: null,
    messageErrorMessage: null,
    fileErrorMessage: null,
  };

  componentDidMount() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      formValues,
      organization,
      organizationFetched,
      organizationErrorMessage,
      messageLoading,
      messageErrorMessage,
      fileLoading,
      fileErrorMessage,
      createMessage,
      uploadFile,
      push,
    } = this.props;

    return (
      <div className="row" id="message-add">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Create Message</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <ErrorMessage>{organizationErrorMessage}</ErrorMessage>
              <Loading loaded={organizationFetched}>
                <MessageForm
                  organizationId={organizationId}
                  formValues={formValues}
                  isLoading={messageLoading || fileLoading}
                  isFileLoading={fileLoading}
                  errorMessage={messageErrorMessage || fileErrorMessage}
                  agents={get(
                    organization,
                    'relationships.user_roles.data',
                    []
                  ).map(userRole => ({
                    value: get(userRole, 'attributes.username'),
                    label: [
                      get(userRole, 'attributes.first_name'),
                      get(userRole, 'attributes.last_name'),
                    ].join(' '),
                  }))}
                  onUpload={file => uploadFile(organizationId, file)}
                  onSubmit={values =>
                    createMessage(organizationId, values).then(() =>
                      push(`/organizations/${organizationId}/messages`)
                    )
                  }
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('message');

const formFields = [
  'name',
  'description',
  'type',
  'owner_username',
  'message',
  'file_url',
];

export default connect(
  state => {
    const {
      organizations: {
        item: organization,
        itemFetched: organizationFetched,
        itemErrorMessage: organizationErrorMessage,
      },
      messages: {
        itemLoading: messageLoading,
        itemErrorMessage: messageErrorMessage,
      },
      files: { itemLoading: fileLoading, itemErrorMessage: fileErrorMessage },
    } = state;

    return {
      organization,
      organizationFetched,
      organizationErrorMessage,

      messageLoading,
      messageErrorMessage,

      fileLoading,
      fileErrorMessage,

      formValues: fromPairs(
        formFields.map(formField => [formField, selector(state, formField)])
      ),
    };
  },
  {
    fetchOrganization,
    createMessage: createMessageAction,
    uploadFile: uploadFileAction,
    push: pushAction,
  }
)(MessageAddPage);
