import { submit, reset } from 'redux-form';

export default function remoteSubmitUserForm() {
    return dispatch => {
           return Promise.resolve(dispatch(submit('user')))
           .then(
               () => dispatch(reset('user'))
           );

    }
}