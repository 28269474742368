export const ADD_CALL_QUEUES_START = 'ADD_CALL_QUEUES_START';
export const ADD_CALL_QUEUES_SUCCESS = 'ADD_CALL_QUEUES_SUCCESS';
export const ADD_CALL_QUEUES_ERROR = 'ADD_CALL_QUEUES_ERROR';

export const SHOW_CALL_QUEUE_START = 'SHOW_CALL_QUEUE_START';
export const SHOW_CALL_QUEUE_SUCCESS = 'SHOW_CALL_QUEUE_SUCCESS';
export const SHOW_CALL_QUEUE_ERROR = 'SHOW_CALL_QUEUE_ERROR';

export const CREATE_CALL_QUEUE_START = 'CREATE_CALL_QUEUE_START';
export const CREATE_CALL_QUEUE_SUCCESS = 'CREATE_CALL_QUEUE_SUCCESS';
export const CREATE_CALL_QUEUE_ERROR = 'CREATE_CALL_QUEUE_ERROR';

export const UPDATE_CALL_QUEUE_START = 'UPDATE_CALL_QUEUE_START';
export const UPDATE_CALL_QUEUE_SUCCESS = 'UPDATE_CALL_QUEUE_SUCCESS';
export const UPDATE_CALL_QUEUE_ERROR = 'UPDATE_CALL_QUEUE_ERROR';

export const UPDATE_CALL_QUEUE_ENTRIES_START = 'UPDATE_CALL_QUEUE_ENTRIES_START';
export const UPDATE_CALL_QUEUE_ENTRIES_SUCCESS = 'UPDATE_CALL_QUEUE_ENTRIES_SUCCESS';
export const UPDATE_CALL_QUEUE_ENTRIES_ERROR = 'UPDATE_CALL_QUEUE_ENTRIES_ERROR';

export const DELETE_CALL_QUEUE_START = 'DELETE_CALL_QUEUE_START';
export const DELETE_CALL_QUEUE_SUCCESS = 'DELETE_CALL_QUEUE_SUCCESS';
export const DELETE_CALL_QUEUE_ERROR = 'DELETE_CALL_QUEUE_ERROR';
