import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import VoicemailsIndexPage from './VoicemailsIndexPage/VoicemailsIndexPage';

export default [
  <Route
    exact
    key="organization-voicemails"
    path="/organizations/:organizationId/voicemails"
    component={RequireAuth(VoicemailsIndexPage)}
  />
];
