import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_MESSAGE_START,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR
} from '../../constants/Message';

// deleteMessage

export function deleteMessageStart() {
  return {
    type: DELETE_MESSAGE_START
  };
}

export function deleteMessageSuccess() {
  return {
    type: DELETE_MESSAGE_SUCCESS
  };
}

export function deleteMessageError(error) {
  return {
    type: DELETE_MESSAGE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteMessage(organizationId, messageId) {
  return dispatch => {
    dispatch(deleteMessageStart());

    return apiRequest.delete(`organizations/${organizationId}/messages/${messageId}`)
      .then(() => Promise.resolve(dispatch(deleteMessageSuccess())))
      .catch(error => Promise.reject(dispatch(deleteMessageError(error))));
  };
}
