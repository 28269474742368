import React, { Component } from 'react';
import get from 'lodash/get';
import size from 'lodash/size';
import { Pagination } from 'antd';

import { LinkTo } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';

class CallLogsTable extends Component {
  getDirection(call, username) {
    const fromUsername = get(
      call,
      'relationships.from.data.attributes.username'
    );
    const direction = get(call, 'attributes.direction');
    const isFromUserRole =
      get(call, 'relationships.from.data.type') === 'user_roles';
    const isToUserRole =
      get(call, 'relationships.to.data.type') === 'user_roles';

    if (fromUsername !== username && isFromUserRole && isToUserRole) {
      return 'inbound';
    }
    return direction;
  }

  getAgentOrPhone(call, username) {
    const direction = get(call, 'attributes.direction');
    const callFromType = get(call, 'relationships.from.data.type');
    const callToType = get(call, 'relationships.to.data.type');
    const callToId = get(call, 'relationships.to.data.id');
    const callFromId = get(call, 'relationships.from.data.id');
    const fromUsername = get(
      call,
      'relationships.from.data.attributes.username'
    );
    const toUsername = get(call, 'relationships.to.data.attributes.username');

    if (direction === 'outbound' && callToType === 'phone_numbers') {
      return callToId;
    } else if (callFromType === 'phone_numbers') {
      return callFromId;
    } else if (
      callToType === 'user_roles' &&
      callFromType === 'user_roles' &&
      fromUsername !== username
    ) {
      return fromUsername;
    } else if (callFromType === 'user_roles' && toUsername !== username) {
      return toUsername;
    }
    return 'not available';
  }

  render() {
    const { items, meta, username, onPaginate } = this.props;

    if (size(items) === 0) {
      return <h4>No recent calls</h4>;
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <table className="table table-hover table-bordered table-striped">
            <thead>
              <tr>
                <th>Type</th>
                <th>Number</th>
                <th>Started</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map(call => (
                <tr key={call.id}>
                  <td>{this.getDirection(call, username)}</td>
                  <td>{this.getAgentOrPhone(call, username)}</td>
                  <td>
                    {renderHumanDateTime(
                      new Date(get(call, 'attributes.started_at')),
                      { shorttime: true }
                    )}
                  </td>

                  <td>
                    {Math.max(
                      Math.floor(
                        (new Date(get(call, 'attributes.ended_at', null)) -
                          new Date(get(call, 'attributes.started_at'))) /
                          1000
                      ),
                      0
                    )}
                    s
                  </td>
                  <td>{get(call, 'attributes.status')}</td>
                  <td>
                    {get(call, 'relationships.voicemail') ? (
                      <LinkTo
                        button
                        className="btn-sm"
                        color="outline-success"
                        icon="play"
                        href={get(
                          call,
                          'relationships.voicemail.data.attributes.recording_url'
                        )}
                        target="_blank"
                        pureLink
                      >
                        Play Voicemail (
                        {get(
                          call,
                          'relationships.voicemail.data.attributes.duration'
                        )}
                        s)
                      </LinkTo>
                    ) : null}
                    {Array.isArray(call.relationships.recordings.data) &&
                      call.relationships.recordings.data.map(recording => (
                        <LinkTo
                          key={recording.id}
                          button
                          className="btn-sm"
                          color="outline-danger"
                          icon="play"
                          href={get(recording, 'attributes.recording_url')}
                          target="_blank"
                          pureLink
                        >
                          Play Recording (
                          {get(recording, 'attributes.duration')}s)
                        </LinkTo>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <Pagination
                    total={meta.count}
                    current={meta.page}
                    showTotal={total => `Total ${total} items`}
                    onChange={page => onPaginate(page)}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    );
  }
}

export default CallLogsTable;
