import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { LinkTo } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';

function RingGroupInfo({ item, organizationId }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'attributes.name')}</td>
        </tr>
        <tr>
          <td>Description</td>
          <td>{get(item, 'attributes.description')}</td>
        </tr>
        <tr>
          <td>Members</td>
          <td>
            {get(item, 'relationships.members.data', []).map((user, index) => (
              <span key={user.id}>
                {!!index && ', '}
                <LinkTo icon="user" href={`organizations/${organizationId}/users/${user.attributes.username}/show`}>
                  {`${get(user, 'attributes.first_name')} ${get(user, 'attributes.last_name')}`}
                </LinkTo>
              </span>
            ))}
          </td>
        </tr>
        <tr>
          <td>Calls</td>
          <td>
            {size(get(item, 'relationships.calls.data', [])) > 0 ? (
              <table className="table table-hover table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Reservation SID</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  {get(item, 'relationships.calls.data', []).map(call => (
                    <tr key={call.id}>
                      <td>{get(call, 'attributes.reservation_sid')}</td>
                      <td>{renderHumanDateTime(get(call, 'attributes.created_at'))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

RingGroupInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};

export default RingGroupInfo;
