import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';

import { createCallFlow as createCallFlowAction } from '../../../actions/CallFlowActions';

import { CallFlowForm } from '../../../components';

class CallFlowAddPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    errorMessage: PropTypes.string,
    createCallFlow: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: null,
  };

  render() {
    const {
      match: {
        params: { organizationId },
      },
      errorMessage,
      createCallFlow,
      push,
    } = this.props;

    return (
      <div className="row" id="call-flow-add">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Create Call Flow</h3>
            </div>
          </div>

          <div className="card">
            <div className="card-block">
              <CallFlowForm
                organizationId={organizationId}
                errorMessage={errorMessage}
                onSubmit={values =>
                  createCallFlow(organizationId, values).then(() =>
                    push(`/organizations/${organizationId}/callFlows`)
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ callFlows: { errorMessage } }) => ({ errorMessage }),
  {
    createCallFlow: createCallFlowAction,
    push: pushAction,
  }
)(CallFlowAddPage);
