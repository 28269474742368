import { LOCATION_CHANGE } from 'connected-react-router';
import first from 'lodash/first';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import keys from 'lodash/keys';
import last from 'lodash/last';
import omit from 'lodash/omit';
import set from 'lodash/set';
import size from 'lodash/size';

import {
  SHOW_CALL_FLOW_START,
  SHOW_CALL_FLOW_SUCCESS,
  SHOW_CALL_FLOW_ERROR,
} from '../constants/CallFlow';

import {
  CREATE_CALL_FLOW_ENTRY_SUCCESS,
  CHANGE_CALL_FLOW_ENTRY_SUCCESS,
  COPY_CALL_FLOW_ENTRY_SUCCESS,
  REMOVE_CALL_FLOW_ENTRY_SUCCESS,
  CHANGE_CALL_FLOW_ENTRIES_SUCCESS,
  UPDATE_CALL_FLOW_ENTRIES_START,
  UPDATE_CALL_FLOW_ENTRIES_SUCCESS,
  UPDATE_CALL_FLOW_ENTRIES_ERROR,
} from '../constants/CallFlowEntry';

const defaultState = {
  path: null,

  callFlow: {},
  callFlowFetched: false,
  callFlowErrorMessage: false,

  items: {},
  itemsLoading: false,
  itemsFetched: false,
  itemsErrorMessage: null,
  itemsSuccessMessage: null,
};

export default function CallFlowReducer(
  state = defaultState,
  { type, payload, item, action, updateValues, errorMessage, successMessage }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case SHOW_CALL_FLOW_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case SHOW_CALL_FLOW_SUCCESS: {
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: true,
        itemsErrorMessage: null,
        items:
          fromPairs(
            get(item, 'relationships.entries.data', []).map((entry, id) => {
              const prompt = get(entry, 'attributes.prompt') ? {} : null;

              if (prompt) {
                keys(get(entry, 'attributes.prompt', {})).map((key, index) => {
                  prompt[`number_${index}`] = first(key.split(':'));
                  prompt[`key_${index}`] = last(key.split(':'));
                  prompt[`value_${index}`] = get(
                    entry,
                    'attributes.prompt',
                    {}
                  )[key];
                  prompt.numbers = [
                    ...(prompt.numbers || []),
                    get(entry, 'attributes.prompt', {})[key],
                  ];
                });
              }

              return [
                id,
                {
                  id,
                  name: get(entry, 'attributes.name'),
                  type: get(entry, 'attributes.type'),
                  message: get(entry, 'attributes.message'),
                  message_id: get(entry, 'attributes.message_id'),
                  prompt,
                  call_queue_id: get(entry, 'attributes.call_queue_id'),
                  timeout: get(entry, 'attributes.timeout'),
                  next: get(entry, 'attributes.next'),
                  expanded: true,
                },
              ];
            })
          ) || {},
      };
    }

    case SHOW_CALL_FLOW_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: false,
        itemsErrorMessage: errorMessage,
      };

    case CREATE_CALL_FLOW_ENTRY_SUCCESS: {
      const id = size(state.items);

      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            id,
            type: action,
            expanded: true,
          },
        },
      };
    }

    case CHANGE_CALL_FLOW_ENTRY_SUCCESS: {
      const { id, name, value } = updateValues;

      const entry = state.items[id];

      set(entry, name, value);

      return {
        ...state,
        items: {
          ...state.items,
          [id]: entry,
        },
      };
    }

    case COPY_CALL_FLOW_ENTRY_SUCCESS: {
      const id = size(state.items);

      return {
        ...state,
        items: {
          ...state.items,
          [id]: {
            ...item,
            id,
            expanded: true,
          },
        },
      };
    }

    case REMOVE_CALL_FLOW_ENTRY_SUCCESS: {
      return {
        ...state,
        items: omit(state.entries, [item.id]),
      };
    }

    case CHANGE_CALL_FLOW_ENTRIES_SUCCESS: {
      const { expanded } = updateValues;

      return {
        ...state,
        items: fromPairs(
          keys(state.items)
            .map(key => state.items[key])
            .map(entry => [
              entry.id,
              {
                ...entry,
                expanded,
              },
            ])
        ),
      };
    }

    case UPDATE_CALL_FLOW_ENTRIES_START:
      return {
        ...state,
        itemsLoading: true,
      };

    case UPDATE_CALL_FLOW_ENTRIES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        itemsErrorMessage: null,
        itemsSuccessMessage: successMessage,
      };

    case UPDATE_CALL_FLOW_ENTRIES_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsErrorMessage: errorMessage,
      };

    default:
      return state;
  }
}
