export const ADD_VOICEMAILS_START = 'ADD_VOICEMAILS_START';
export const ADD_VOICEMAILS_SUCCESS = 'ADD_VOICEMAILS_SUCCESS';
export const ADD_VOICEMAILS_ERROR = 'ADD_VOICEMAILS_ERROR';

export const SHOW_VOICEMAIL_START = 'SHOW_VOICEMAIL_START';
export const SHOW_VOICEMAIL_SUCCESS = 'SHOW_VOICEMAIL_SUCCESS';
export const SHOW_VOICEMAIL_ERROR = 'SHOW_VOICEMAIL_ERROR';

export const CREATE_VOICEMAIL_START = 'CREATE_VOICEMAIL_START';
export const CREATE_VOICEMAIL_SUCCESS = 'CREATE_VOICEMAIL_SUCCESS';
export const CREATE_VOICEMAIL_ERROR = 'CREATE_VOICEMAIL_ERROR';

export const UPDATE_VOICEMAIL_START = 'UPDATE_VOICEMAIL_START';
export const UPDATE_VOICEMAIL_SUCCESS = 'UPDATE_VOICEMAIL_SUCCESS';
export const UPDATE_VOICEMAIL_ERROR = 'UPDATE_VOICEMAIL_ERROR';

export const DELETE_VOICEMAIL_START = 'DELETE_VOICEMAIL_START';
export const DELETE_VOICEMAIL_SUCCESS = 'DELETE_VOICEMAIL_SUCCESS';
export const DELETE_VOICEMAIL_ERROR = 'DELETE_VOICEMAIL_ERROR';
