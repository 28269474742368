import { LOCATION_CHANGE } from 'connected-react-router';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import {
  FETCH_LIVE_CALLS_START,
  FETCH_LIVE_CALLS_SUCCESS,
  FETCH_LIVE_CALLS_ERROR,
  SUBSCRIBE_TO_LIVE_CALLS_SUCCESS,
  ADD_LIVE_CALL,
  REMOVE_LIVE_CALL,
} from '../constants/Call';

const defaultState = {
  path: null,

  liveCalls: [],
  liveCallsMeta: { page: 1, pageSize: 10 },
  liveCallsFetched: false,
  liveCallsLoading: false,
  liveCallsErrorMessage: null,
};

export default function CallReducer(
  state = defaultState,
  { type, payload, items, meta, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case FETCH_LIVE_CALLS_START:
      return {
        ...state,
        liveCallsLoading: true,
        liveCallsFetched: false,
      };

    case FETCH_LIVE_CALLS_SUCCESS:
      return {
        ...state,
        liveCallsLoading: false,
        liveCallsErrorMessage: null,
        liveCallsFetched: true,
        liveCalls: items,
        liveCallsMeta: meta,
      };

    case FETCH_LIVE_CALLS_ERROR:
      return {
        ...state,
        liveCallsLoading: false,
        liveCallsFetched: true,
        liveCallsErrorMessage: errorMessage,
      };

    case SUBSCRIBE_TO_LIVE_CALLS_SUCCESS:
      return {
        ...state,
        items,
      };

    case ADD_LIVE_CALL:
      return {
        ...state,
        liveCalls: uniqBy([item, ...state.liveCalls], i => i.id),
      };

    case REMOVE_LIVE_CALL:
      return {
        ...state,
        liveCalls: filter(state.liveCalls, i => i.id !== item.id),
      };

    default:
      return state;
  }
}
