import get from 'lodash/get';

import {
  AUTH_USER,
  AUTH_LOADING,
  AUTH_STOP_LOADING,
  AUTH_ERROR,
  AUTH_SUCCESS_MESSAGE,
  SET_SUCCESS_MESSAGE,
  SET_ERROR_MESSAGE,
  UPDATE_CURRENT_USER
} from '../../constants/Auth';

export function authLoading() {
  return {
    type: AUTH_LOADING
  };
}

export function authStopLoading() {
  return {
    type: AUTH_STOP_LOADING
  };
}

export function authUser({ username, tokenType, accessToken, currentUser }) {
  localStorage.setItem('username', username);
  localStorage.setItem('token_type', tokenType);
  localStorage.setItem('access_token', accessToken);

  return {
    type: AUTH_USER,
    currentUser
  };
}

export function setAuthenticatedUser(currentUser) {
  return {
    type: AUTH_USER,
    currentUser
  };
}

export function authError(error) {
  const errorMessage = get(error, 'response.data.errors[0].detail', 'Server error');

  return {
    type: AUTH_ERROR,
    errorMessage
  };
}


export function authSuccessMessage(successMessage = null) {
  return {
    type: AUTH_SUCCESS_MESSAGE,
    successMessage
  };
}

export function setSuccessMessage(successMessage) {
  return {
    type: SET_SUCCESS_MESSAGE,
    successMessage
  };
}

export function setErrorMessage(error) {
  const errorMessage = get(error, 'response.data.message', 'Server error');

  return {
    type: SET_ERROR_MESSAGE,
    errorMessage
  };
}


export function setCurrentUser(currentUser) {

  return {
    type: UPDATE_CURRENT_USER,
    currentUser
  };
}
