import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  FETCH_MESSAGE_START,
  FETCH_MESSAGE_SUCCESS,
  FETCH_MESSAGE_ERROR
} from '../../constants/Message';

// fetchMessage

export function fetchMessageStart() {
  return {
    type: FETCH_MESSAGE_START
  };
}

export function fetchMessageSuccess(item = {}) {
  return {
    type: FETCH_MESSAGE_SUCCESS,
    item
  };
}

export function fetchMessageError(error) {
  return {
    type: FETCH_MESSAGE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchMessage(organizationId, messageId) {
  return dispatch => {
    dispatch(fetchMessageStart());

    return apiRequest
      .get(`organizations/${organizationId}/messages/${messageId}`)
      .then(({ data: { data } }) => Promise.resolve(dispatch(fetchMessageSuccess(data))))
      .catch(error => Promise.reject(dispatch(fetchMessageError(error))));
  };
}
