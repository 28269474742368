import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_BLACKLIST_START,
  CREATE_BLACKLIST_SUCCESS,
  CREATE_BLACKLIST_ERROR,
} from '../../constants/Blacklist';

export function createBlacklistStart() {
  return {
    type: CREATE_BLACKLIST_START,
  };
}

export function createBlacklistSuccess(item = {}) {
  return {
    type: CREATE_BLACKLIST_SUCCESS,
    item,
  };
}

export function createBlacklistError(error) {
  return {
    type: CREATE_BLACKLIST_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function createBlacklist(organizationId, values) {
  return dispatch => {
    dispatch(createBlacklistStart());

    return apiRequest
      .post(`organizations/${organizationId}/blacklist`, values)
      .then(({ data }) => {
        dispatch(createBlacklistSuccess(data));
      })
      .catch(error => dispatch(createBlacklistError(error)));
  };
}
