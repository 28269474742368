import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchBlacklist, updateBlacklist as updateBlacklistAction } from '../../../actions/BlacklistActions';

import { BlacklistForm } from '../../../components';
import { CrudTabs, Loading } from '../../../helpers';

class BlacklistEditPage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    itemFetched: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    fetchBlacklist: PropTypes.func.isRequired,
    updateBlacklist: PropTypes.func.isRequired
  };

  static defaultProps = {
    errorMessage: null
  };

  componentDidMount() {
    const { match: { params: { organizationId, id } } } = this.props;

    this.props.fetchBlacklist(organizationId, id);
  }

  render() {
    const { match: { params: { organizationId, id } }, item, itemFetched, errorMessage, updateBlacklist } = this.props;

    return (
      <div className="row" id="blacklist-edit">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-12">
              <h3 className="text-themecolor">Update Blacklisted Item</h3>
            </div>
          </div>

          <CrudTabs model={`organizations/${organizationId}/blacklists`} id={id} active="edit" />

          <div className="card">
            <div className="card-block">
              <Loading loaded={itemFetched}>
                <BlacklistForm
                  item={item}
                  errorMessage={errorMessage}
                  organizationId={organizationId}
                  onSubmit={values => updateBlacklist(organizationId, id, values)}
                />
              </Loading>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({
  blacklists: {
    item,
    itemFetched,
    errorMessage
  }
}) => ({
  item,
  itemFetched,
  errorMessage
}), { fetchBlacklist, updateBlacklist: updateBlacklistAction })(BlacklistEditPage);
