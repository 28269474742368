import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';
import omit from 'lodash/omit';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchCalls } from '../../../actions/CallActions';

import { fetchPhoneNumbers } from '../../../actions/PhoneNumberActions';

import { CallsHistoryTable, CallsHistoryFilterForm } from '../../../components';
import { ErrorMessage } from '../../../helpers';

import { Skeleton } from 'antd';

class CallsHistoryPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,

    organization: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
    organizationFetched: PropTypes.bool.isRequired,
    organizationErrorMessage: PropTypes.string,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ).isRequired,
    itemsMeta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number,
    }).isRequired,
    itemsFetched: PropTypes.bool.isRequired,
    itemsErrorMessage: PropTypes.string,

    fetchOrganization: PropTypes.func.isRequired,
  };

  static defaultProps = {
    organizationErrorMessage: null,
    itemsErrorMessage: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1, q = null) {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    this.props.fetchCalls(organizationId, { page });
    this.props.fetchOrganization(organizationId);
    this.props.fetchPhoneNumbers(organizationId, { page, q });
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      organization,
      organizationFetched,
      organizationErrorMessage,
      items,
      itemsMeta,
      itemsFetched,
      itemsErrorMessage,
      fetchCalls,
      phoneNumbers,
      phoneNumbersFetched,
    } = this.props;

    return (
      <div id="items-report">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Calls History</h3>
          </div>
        </div>

        <ErrorMessage>{itemsErrorMessage}</ErrorMessage>

        <div className="row">
          <div className="col-12">
            <Skeleton active loading={!itemsFetched}>
              <div className="card">
                <div className="card-block">
                  <CallsHistoryFilterForm
                    initialValues={itemsMeta}
                    agents={get(
                      organization,
                      'relationships.user_roles.data',
                      []
                    ).map(userRole => ({
                      value: get(userRole, 'attributes.username'),
                      label: [
                        get(userRole, 'attributes.first_name'),
                        get(userRole, 'attributes.last_name'),
                      ].join(' '),
                    }))}
                    onSubmit={values =>
                      fetchCalls(organizationId, {
                        ...values,
                        ...itemsMeta,
                        ...omit(itemsMeta, [
                          'pageSize',
                          'count',
                          'pageCount',
                          'page_count',
                          'page_size',
                          'count',
                        ]),
                      })
                    }
                    onClear={() => fetchCalls(organizationId, { page: 1 })}
                  />
                  <CallsHistoryTable
                    phoneNumbers={phoneNumbers}
                    items={items}
                    meta={itemsMeta}
                    onPaginate={page =>
                      fetchCalls(organizationId, {
                        ...omit(itemsMeta, [
                          'pageSize',
                          'count',
                          'pageCount',
                          'page_count',
                          'page_size',
                          'count',
                        ]),
                        page,
                      })
                    }
                  />
                </div>
              </div>
            </Skeleton>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    organizations: {
      item: organization,
      itemFetched: organizationFetched,
      itemErrorMessage: organizationErrorMessage,
    },
    calls: { items, itemsMeta, itemsFetched, itemsErrorMessage },
    phoneNumbers: {
      items: phoneNumbers,
      itemsFetched: phoneNumbersFetched,
      itemsErrorMessage: phoneNumbersErrorMessage,
    },
  }) => ({
    organization,
    organizationFetched,
    organizationErrorMessage,

    items,
    itemsMeta,
    itemsFetched,
    itemsErrorMessage,

    phoneNumbers,
    phoneNumbersFetched,
    phoneNumbersErrorMessage,
  }),
  {
    fetchOrganization,
    fetchCalls,
    fetchPhoneNumbers,
  }
)(CallsHistoryPage);
