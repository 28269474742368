import { LOCATION_CHANGE } from 'connected-react-router';

import {
  UPLOAD_FILE_START,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_ERROR,
} from '../constants/File';

const defaultState = {
  path: null,

  item: {},
  itemLoading: false,
  itemErrorMessage: null,
};

export default function FileReducer(
  state = defaultState,
  { type, payload, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case UPLOAD_FILE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: null,
        item,
      };

    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemErrorMessage: errorMessage,
      };

    default:
      return state;
  }
}
