import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import compact from 'lodash/compact';

import { renderField, renderDropdownList } from '../../../../../helpers';
import { actionTypes } from '../../utils';

function CollapsedForm({ entries, errorMessage, afterChange, handleSubmit, onSubmit }) {
  return (
    <form onSubmit={handleSubmit(values => onSubmit(values))}>

      <div className="row">
        <div className="col-4">
          <Field
            name="name"
            label="Name"
            component={renderField}
            afterChange={value => afterChange('name', value)}
          />
        </div>
        <div className="col-4">
          <Field
            name="type"
            label="Type"
            component={renderDropdownList}
            data={actionTypes}
            afterChange={value => afterChange('type', value)}
          />
        </div>
        <div className="col-4">
          <Field
            name="next"
            label="Next Action?"
            component={renderDropdownList}
            data={entries.map(({ id: value, name }) => ({ value, label: compact([name, `ID: ${value}`]).join(' | ') }))}
            afterChange={value => afterChange('next', value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {errorMessage ? (
            <div className="alert alert-danger">
              {errorMessage}
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
}

CollapsedForm.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string
  })).isRequired,
  errorMessage: PropTypes.string,
  afterChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

CollapsedForm.defaultProps = {
  errorMessage: null,
  onSubmit: null
};

function validate({ name, type, next }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please, enter a name';
  }

  if (!type) {
    errors.type = 'Please, select type';
  }

  if (!next) {
    errors.next = 'Please, select next action';
  }

  return errors;
}

export default reduxForm({ form: 'collapsed', validate })(CollapsedForm);
