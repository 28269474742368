import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_ORGANIZATION_START,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_ERROR,
} from '../../constants/Organization';

// createOrganization

export function createOrganizationStart() {
  return {
    type: CREATE_ORGANIZATION_START,
  };
}

export function createOrganizationSuccess(item = {}) {
  return {
    type: CREATE_ORGANIZATION_SUCCESS,
    item,
  };
}

export function createOrganizationError(error) {
  return {
    type: CREATE_ORGANIZATION_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function createOrganization(values) {
  return dispatch => {
    dispatch(createOrganizationStart());

    return apiRequest
      .post('organizations', values)
      .then(({ data }) => {
        dispatch(createOrganizationSuccess(data));
        dispatch(push('/organizations'));
      })
      .catch(error => dispatch(createOrganizationError(error)));
  };
}
