import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { LinkTo } from '../../../helpers';

function BlacklistInfo({ item, organizationId }) {
  return (
    <table className="table table-hover table-bordered table-striped">
      <tbody>
        <tr>
          <td>Name</td>
          <td>{get(item, 'attributes.name')}</td>
        </tr>
        <tr>
          <td>Number</td>
          <td>{get(item, 'attributes.number')}</td>
        </tr>
        <tr>
          <td>Added By</td>
          <td>{get(item, 'attributes.added_by')}</td>
        </tr>
      </tbody>
    </table>
  );
}

BlacklistInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default BlacklistInfo;
