import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { apiRequest, apiWSConnect } from '../utils';
import { nanoid } from 'nanoid';
import { compareDesc } from 'date-fns';

export const fetchRequests = createAsyncThunk('requests/fetch', async () => {
  const {
    data: { requests },
  } = await apiRequest.get('requests');
  return requests.map(req => ({ id: nanoid(), ...req }));
});

export const subscribeToRequests = createAsyncThunk(
  'requests/subscribe',
  async (_, { dispatch, getState }) => {
    const { subscribed } = getState().requests;

    return apiWSConnect(async client => {
      const {
        data: { requests },
      } = await apiRequest.get('requests');

      if (!subscribed) {
        // create the WS channel... this should move to middleware
        await client.subscribe(`/v1/requests`, request => {
          dispatch(addRequest({ id: nanoid(), ...request }));
        });
      }

      return requests.map(req => ({ id: nanoid(), ...req }));
    });
  }
);

const adapter = createEntityAdapter({
  sortComparer: (a, b) =>
    compareDesc(new Date(a.started_at), new Date(b.started_at)),
});

const slice = createSlice({
  name: 'requests',
  initialState: adapter.getInitialState({ loading: false, subscribed: false }),
  reducers: {
    addRequest: adapter.upsertOne,
  },
  extraReducers: builder => {
    builder.addCase(fetchRequests.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchRequests.fulfilled, (state, action) => {
      state.loading = false;
      adapter.upsertMany(state, action.payload);
    });
    builder.addCase(fetchRequests.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(subscribeToRequests.fulfilled, (state, action) => {
      state.loading = false;
      state.subscribed = true;
      adapter.upsertMany(state, action.payload);
    });
  },
});

export const { addRequest } = slice.actions;

export default slice.reducer;

export const {
  selectAll: selectAllRequests,
  selectById: selectRequestById,
  selectEntities: selectRequestEntities,
  selectIds: selectRequestIds,
  selectTotal: selectTotalRequests,
} = adapter.getSelectors(state => state.requests);

export const selectRequestsLoading = state => state.requests.loading;
