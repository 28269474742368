export const ADD_FAVORITES_START = 'ADD_FAVORITES_START';
export const ADD_FAVORITES_SUCCESS = 'ADD_FAVORITES_SUCCESS';
export const ADD_FAVORITES_ERROR = 'ADD_FAVORITES_ERROR';

export const SHOW_FAVORITE_START = 'SHOW_FAVORITE_START';
export const SHOW_FAVORITE_SUCCESS = 'SHOW_FAVORITE_SUCCESS';
export const SHOW_FAVORITE_ERROR = 'SHOW_FAVORITE_ERROR';

export const CREATE_FAVORITE_START = 'CREATE_FAVORITE_START';
export const CREATE_FAVORITE_SUCCESS = 'CREATE_FAVORITE_SUCCESS';
export const CREATE_FAVORITE_ERROR = 'CREATE_FAVORITE_ERROR';

export const UPDATE_FAVORITE_START = 'UPDATE_FAVORITE_START';
export const UPDATE_FAVORITE_SUCCESS = 'UPDATE_FAVORITE_SUCCESS';
export const UPDATE_FAVORITE_ERROR = 'UPDATE_FAVORITE_ERROR';

export const DELETE_FAVORITE_START = 'DELETE_FAVORITE_START';
export const DELETE_FAVORITE_SUCCESS = 'DELETE_FAVORITE_SUCCESS';
export const DELETE_FAVORITE_ERROR = 'DELETE_FAVORITE_ERROR';

export const ORDER_FAVORITES_START = 'ORDER_FAVORITES_START';
export const ORDER_FAVORITES_SUCCESS = 'ORDER_FAVORITES_SUCCESS';
export const ORDER_FAVORITES_ERROR = 'ORDER_FAVORITES_ERROR';