import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  SHOW_BLACKLIST_START,
  SHOW_BLACKLIST_SUCCESS,
  SHOW_BLACKLIST_ERROR
} from '../../constants/Blacklist';

export function showBlacklistStart() {
  return {
    type: SHOW_BLACKLIST_START
  };
}

export function showBlacklist(item = {}) {
  return {
    type: SHOW_BLACKLIST_SUCCESS,
    item
  };
}

export function showBlacklistError(error) {
  return {
    type: SHOW_BLACKLIST_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchBlacklist(organizationId, id, options = {}) {
  return dispatch => {
    dispatch(showBlacklistStart());
    
    return apiRequest.get(`organizations/${organizationId}/blacklist/${id}`, options)
      .then(({ data: { data: item } }) => dispatch(showBlacklist(item)))
      .catch(error => dispatch(showBlacklistError(error)));
  };
}
