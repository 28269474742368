import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_CALL_FLOW_START,
  DELETE_CALL_FLOW_SUCCESS,
  DELETE_CALL_FLOW_ERROR
} from '../../constants/CallFlow';

// deleteCallFlow

export function deleteCallFlowStart() {
  return {
    type: DELETE_CALL_FLOW_START
  };
}

export function deleteCallFlowSuccess(item = {}) {
  return {
    type: DELETE_CALL_FLOW_SUCCESS,
    item
  };
}

export function deleteCallFlowError(error) {
  return {
    type: DELETE_CALL_FLOW_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteCallFlow(callFlowId) {
  return dispatch => {
    dispatch(deleteCallFlowStart());

    return apiRequest.delete(`call_flows/${callFlowId}`)
      .then(() => Promise.resolve(dispatch(deleteCallFlowSuccess())))
      .catch(error => Promise.reject(dispatch(deleteCallFlowError(error))));
  };
}
