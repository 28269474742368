import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest, camelCaseObjectKeys } from '../../utils';

import {
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_ERROR
} from '../../constants/Message';

// fetchMessages

export function fetchMessagesStart() {
  return {
    type: FETCH_MESSAGES_START
  };
}

export function fetchMessagesSuccess(items = [], meta = {}) {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    items,
    meta
  };
}

export function fetchMessagesError(error) {
  console.log('error', error);
  return {
    type: FETCH_MESSAGES_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function fetchMessages(organizationId, { page = 1, pageSize = 50, ...params }) {
  return dispatch => {
    dispatch(fetchMessagesStart());

    return apiRequest.get(`organizations/${organizationId}/messages`, { page, page_size: pageSize, ...params })
      .then(({ data: { data: items, meta } }) => Promise.resolve(dispatch(fetchMessagesSuccess(items, {
        page,
        pageSize,
        ...params,
        ...camelCaseObjectKeys(get(meta, 'pagination', {}))
      }))))
      .catch(error => Promise.reject(dispatch(fetchMessagesError(error))));
  };
}
