import LogRocket from 'logrocket';
import isStringTrue from './isStringTrue';

import { LOGROCKET_ENABLED, LOGROCKET_DOMAIN } from '../config';

let logrocketClient = {
  track: eventName => {
    console.log('LOGROCKET_TRACK:', eventName);
    return true;
  },
  identify: (uid, options) => {
    console.log('LOGROCKET_IDENTIFY', uid, options);
    return true;
  },
  log: (...props) => {
    console.log('LOGROCKET_LOG', props);
    return true;
  },
  info: (...props) => {
    console.log('LOGROCKET_INFO', props);
    return true;
  },
  warn: (...props) => {
    console.log('LOGROCKET_WARN', props);
    return true;
  },
  error: (...props) => {
    console.log('LOGROCKET_ERROR', props);
    return true;
  },
  debug: (...props) => {
    console.log('LOGROCKET_DEBUG', props);
    return true;
  },
  reduxMiddleware: options => {
    console.log('LOGROCKET_REDUXMIDDLEWARE', options);
    return true;
  },
  startNewSession: () => {
    console.log('LOGROCKET_STARTNEWSESSION');
    return true;
  },

  captureMessage: (message, options) => {
    console.log('LOGROCKET_CAPTUREMESSAGE', message, options);
    return true;
  },
  captureException: (exception, options) => {
    console.log('LOGROCKET_CAPTUREEXCEPTION', exception, options);
    return true;
  },
};

if (isStringTrue(LOGROCKET_ENABLED) && LOGROCKET_DOMAIN) {
  logrocketClient = require('logrocket/setup')({
    sdkServer: 'https://lr.scoreshuttle.com',
    ingestServer: 'https://lr.scoreshuttle.com',
  });
  logrocketClient.init(LOGROCKET_DOMAIN);
}

export default logrocketClient;
