import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import get from 'lodash/get';
import size from 'lodash/size';
import filter from 'lodash/filter';
import first from 'lodash/first';

import { LinkTo } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';
import { Pagination } from 'antd';

function CallsHistoryTable({ items, meta, onPaginate, phoneNumbers }) {
  if (size(items) === 0) {
    return <h1>No Records</h1>;
  }

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
        <tr>
          <th className="footable-sortable">
            Status
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Direction
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            To
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            From
            <span className="footable-sort-indicator" />
          </th>

          <th className="footable-sortable">
            Started
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">
            Duration
            <span className="footable-sort-indicator" />
          </th>
          <th className="footable-sortable">Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr
            key={item.id}
            className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}
          >
            <td>
              <div
                className={cl('label label-table', {
                  'label-success':
                    get(item, 'attributes.status') !== 'completed',
                  'label-danger':
                    get(item, 'attributes.status') === 'completed',
                })}
              >
                {get(item, 'attributes.status')}
              </div>
            </td>
            <td>{get(item, 'attributes.direction')}</td>
            <td>
              {get(item, 'attributes.direction') === 'outbound' &&
              get(item, 'relationships.to.data.type') !== 'user_roles'
                ? get(item, 'relationships.to.data.id')
                : null}
              {get(item, 'relationships.to.data.type') === 'user_roles'
                ? get(item, 'relationships.to.data.attributes.username')
                : null}
              {get(item, 'relationships.to.data.type') === 'call_flows'
                ? [
                    get(item, 'relationships.agent.data.attributes.username') ||
                      get(item, 'attributes.caller_id'),
                    'via',
                    get(item, 'relationships.to.data.attributes.name'),
                    '|',
                    get(
                      first(
                        filter(phoneNumbers, {
                          id: get(item, 'attributes.caller_id'),
                        })
                      ),
                      'attributes.description'
                    ),
                  ].join(' ')
                : null}
            </td>
            <td>
              {get(item, 'attributes.direction') === 'inbound'
                ? get(item, 'relationships.from.data.id')
                : get(item, 'relationships.agent.data.attributes.username')}
            </td>

            <td>{renderHumanDateTime(get(item, 'attributes.started_at'))}</td>
            <td>
              {Math.max(
                Math.floor(
                  (new Date(get(item, 'attributes.ended_at', null)) -
                    new Date(get(item, 'attributes.started_at'))) /
                    1000
                ),
                0
              )}
              s
            </td>
            <td>
              {get(item, 'relationships.voicemail') ? (
                <LinkTo
                  button
                  color="outline-success"
                  icon="play"
                  href={get(
                    item,
                    'relationships.voicemail.data.attributes.recording_url'
                  )}
                  target="_blank"
                  pureLink
                >
                  Play Voicemail (
                  {get(
                    item,
                    'relationships.voicemail.data.attributes.duration'
                  )}
                  s)
                </LinkTo>
              ) : null}
              {Array.isArray(item.relationships.recordings.data) &&
                item.relationships.recordings.data.map(recording => (
                  <LinkTo
                    button
                    className="btn-sm"
                    color="outline-danger"
                    icon="play"
                    href={get(recording, 'attributes.recording_url')}
                    target="_blank"
                    pureLink
                  >
                    Play Recording ({get(recording, 'attributes.duration')}s)
                  </LinkTo>
                ))}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={6}>
            <Pagination
              total={meta.count}
              current={meta.page}
              showTotal={total => `Total ${total} items`}
              onChange={page => onPaginate(page)}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

CallsHistoryTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number,
  }).isRequired,
  onPaginate: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
};

export default CallsHistoryTable;
