import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';
import includes from 'lodash/includes';
import without from 'lodash/without';

import { Button } from '../helpers';

function renderCheckboxesList({ label, data, input, meta: { touched, error, warning } }) {
  return (
    <div className={cl('form-group', { 'has-success': touched && !error && !warning, 'has-danger': touched && error, 'has-warning': touched && warning })}>
      {label ? (
        <div>
          <label htmlFor={`form-input-${input.name}`} className="form-control-label">{label}</label>
        </div>
      ) : null}
      <div className="btn-group">
        {data.map(({ label: optionLabel, value }) => (
          <Button
            key={value}
            color={includes(input.value, value) ? 'success' : 'secondary'}
            onClick={() => {
              if (includes(input.value, value)) {
                return input.onChange(without(input.value, value));
              }

              return input.onChange([...input.value, value]);
            }}
          >
            {optionLabel}
          </Button>
        ))}
      </div>
      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderCheckboxesList.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
};

renderCheckboxesList.defaultProps = {
  label: null
};

export default renderCheckboxesList;
