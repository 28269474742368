import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_MESSAGE_START,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_ERROR
} from '../../constants/Message';

// createMessage

export function createMessageStart() {
  return {
    type: CREATE_MESSAGE_START
  };
}

export function createMessageSuccess(item = {}) {
  return {
    type: CREATE_MESSAGE_SUCCESS,
    item
  };
}

export function createMessageError(error) {
  return {
    type: CREATE_MESSAGE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function createMessage(organizationId, values) {
  return dispatch => {
    dispatch(createMessageStart());

    return apiRequest
      .post(`organizations/${organizationId}/messages`, values)
      .then(({ data }) => Promise.resolve(dispatch(createMessageSuccess(data))))
      .catch(error => Promise.reject(dispatch(createMessageError(error))));
  };
}
