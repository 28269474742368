import PropTypes from 'prop-types';

function If({ condition, children, otherwise }) {
  if (condition) {
    return children;
  }

  return otherwise;
}

If.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.node,
  otherwise: PropTypes.node
};

If.defaultProps = {
  children: null,
  otherwise: null
};

export default If;
