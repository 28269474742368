import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { createRingGroupMember as createRingGroupMemberAction } from '../../../actions/RingGroupMemberActions';

import { AttachUserForm } from '../..';
import { Button } from '../../../helpers';

class AttachUserModal extends Component {
  static propTypes = {
    organization: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    ringGroupTag: PropTypes.string.isRequired,
    ringGroup: PropTypes.shape({
      relationships: PropTypes.shape({
        users: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired
            })
          )
        })
      })
    }).isRequired,
    ringGroupMemberErrorMessage: PropTypes.string,
    createRingGroupMember: PropTypes.func.isRequired,
    onRefresh: PropTypes.func.isRequired
  };

  static defaultProps = {
    ringGroupMemberErrorMessage: null
  };

  state = {
    opened: false
  };

  toggleOpened() {
    this.setState(prevState => ({ opened: !prevState.opened }));
  }

  render() {
    const {
      ringGroupTag,
      organization,
      organizationId,
      ringGroup,
      ringGroupMemberErrorMessage,
      createRingGroupMember,
      onRefresh
    } = this.props;

    const users = get(organization, 'relationships.user_roles.data', []);
    const ringGroupMemberIds = get(ringGroup, 'relationships.members.data', []).map(r => r.id);

    return (
      <Button rounded icon="plus" color="success" onClick={() => this.toggleOpened()}>
        <Modal show={this.state.opened} onHide={() => this.setState({ opened: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Attach Member</Modal.Title>
          </Modal.Header>
          <Modal.Body onClick={e => e.stopPropagation()}>
            <AttachUserForm
              items={filter(users, ({ id }) => !includes(ringGroupMemberIds, id)).map(item => ({
                label: `${get(item, 'attributes.first_name')} ${get(item, 'attributes.last_name')} (${get(
                  item,
                  'attributes.email'
                )})`,
                value: item.id
              }))}
              errorMessage={ringGroupMemberErrorMessage}
              onClose={() => this.setState({ opened: false })}
              onSubmit={({ user_id: userId }) => createRingGroupMember(organizationId, ringGroupTag, userId)
                .then(() => this.setState({ opened: false }))
                .then(() => onRefresh())
              }
            />
          </Modal.Body>
        </Modal>
      </Button>
    );
  }
}

export default connect(
  ({ organizations: { item: organization }, ringGroupMembers: { itemErrorMessage: ringGroupMemberErrorMessage } }) => ({
    organization,
    ringGroupMemberErrorMessage
  }),
  {
    createRingGroupMember: createRingGroupMemberAction
  }
)(AttachUserModal);
