import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import { UPDATE_RING_GROUP_START, UPDATE_RING_GROUP_SUCCESS, UPDATE_RING_GROUP_ERROR } from '../../constants/RingGroup';

// updateRingGroup

export function updateRingGroupStart() {
  return {
    type: UPDATE_RING_GROUP_START
  };
}

export function updateRingGroupSuccess(item = {}) {
  return {
    type: UPDATE_RING_GROUP_SUCCESS,
    item
  };
}

export function updateRingGroupError(error) {
  return {
    type: UPDATE_RING_GROUP_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail').includes('ring_groups_organization_id_tag_unique')
      ? 'Tag is already taken. Please try another.'
      : get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function updateRingGroup(organizationId, ringGroupId, values) {
  return dispatch => {
    dispatch(updateRingGroupStart());

    return apiRequest
      .put(`organizations/${organizationId}/ring_groups/${ringGroupId}`, values)
      .then(({ data }) => Promise.resolve(dispatch(updateRingGroupSuccess(data))))
      .catch(error => Promise.reject(dispatch(updateRingGroupError(error))));
  };
}
