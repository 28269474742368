import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { LinkTo, Paginate, Button } from '../../../helpers';

function BlacklistsTable({ organizationId, items, meta, onPaginate, onDelete }) {

  if (size(items) === 0) {
    return <h1>No blacklisted numbers found</h1>;
  }

  return (
    <table className="table table-hover footable-loaded footable">
      <thead>
      <tr>
      <th className="footable-sortable">
          Name
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Number
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable">
          Description
          <span className="footable-sort-indicator" />
        </th>
        <th className="footable-sortable"></th>
      </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item.id} className={`footable-${index % 2 === 0 ? 'even' : 'odd'}`}>
          <td>
          {get(item, 'attributes.name')}
            </td>
            <td>
            {get(item, 'attributes.phone_number')}
              </td>
              <td>
          {get(item, 'attributes.description')}
            </td>
            <td>
              <LinkTo href={`organizations/${organizationId}/blacklists/${get(item, 'id')}/show`}>
                {get(item, 'relationships.user_role.data.attributes.username')}
              </LinkTo>
            </td>
            <td>
              <div className="btn-group">
                <Button
                  color="outline-danger"
                  icon="trash"
                  onClick={() => onDelete(item.id)}
                >
                  Remove
                </Button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={5}>
            <Paginate
              count={meta.count}
              pageSize={meta.pageSize}
              activePage={meta.page}
              onSelect={page => onPaginate(page)}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

BlacklistsTable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired
  })).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number
  }).isRequired,
  onPaginate: PropTypes.func.isRequired
};

export default BlacklistsTable;
