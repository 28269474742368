import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_VOICEMAILS_START,
  ADD_VOICEMAILS_SUCCESS,
  ADD_VOICEMAILS_ERROR,
  SHOW_VOICEMAIL_START,
  SHOW_VOICEMAIL_SUCCESS,
  SHOW_VOICEMAIL_ERROR,
  CREATE_VOICEMAIL_START,
  CREATE_VOICEMAIL_SUCCESS,
  CREATE_VOICEMAIL_ERROR,
  UPDATE_VOICEMAIL_START,
  UPDATE_VOICEMAIL_SUCCESS,
  UPDATE_VOICEMAIL_ERROR,
} from '../constants/Voicemail';

const defaultState = {
  path: null,

  voicemail: {},
  voicemailLoading: false,
  voicemailFetched: false,

  voicemails: [],
  voicemailsFetched: false,
  voicemailsLoading: false,
  voicemailsMeta: { page: 1, pageSize: 10 },

  errorMessage: null,
};

export default function VoicemailReducer(
  state = defaultState,
  { type, payload, voicemails, meta, errorMessage, voicemail }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case ADD_VOICEMAILS_START:
      return {
        ...state,
        voicemailsLoading: true,
        voicemailsFetched: false,
      };

    case ADD_VOICEMAILS_SUCCESS:
      return {
        ...state,
        voicemailsLoading: false,
        errorMessage: null,
        voicemailsFetched: true,
        voicemails,
        voicemailsMeta: meta,
      };

    case ADD_VOICEMAILS_ERROR:
      return {
        ...state,
        voicemailsLoading: false,
        voicemailsFetched: false,
        errorMessage,
      };

    case SHOW_VOICEMAIL_START:
      return {
        ...state,
        voicemailLoading: true,
        voicemailFetched: false,
      };

    case SHOW_VOICEMAIL_SUCCESS:
      return {
        ...state,
        voicemailLoading: false,
        voicemailFetched: true,
        errorMessage: null,
        voicemail,
      };

    case SHOW_VOICEMAIL_ERROR:
      return {
        ...state,
        voicemailLoading: false,
        voicemailFetched: false,
        errorMessage,
      };

    case CREATE_VOICEMAIL_START:
      return {
        ...state,
        voicemailLoading: true,
      };

    case CREATE_VOICEMAIL_SUCCESS:
      return {
        ...state,
        voicemailLoading: false,
        errorMessage: null,
        voicemail,
      };

    case CREATE_VOICEMAIL_ERROR:
      return {
        ...state,
        voicemailLoading: false,
        errorMessage,
      };

    case UPDATE_VOICEMAIL_START:
      return {
        ...state,
        voicemailLoading: true,
      };

    case UPDATE_VOICEMAIL_SUCCESS:
      return {
        ...state,
        voicemailLoading: false,
        errorMessage: null,
        voicemail,
        voicemails: state.voicemails.map(v =>
          v.id === voicemail.id ? voicemail : v
        ),
      };

    case UPDATE_VOICEMAIL_ERROR:
      return {
        ...state,
        voicemailLoading: false,
        errorMessage,
      };

    default:
      return state;
  }
}
