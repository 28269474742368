import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_FAVORITES_START,
  ADD_FAVORITES_SUCCESS,
  ADD_FAVORITES_ERROR,
  SHOW_FAVORITE_START,
  SHOW_FAVORITE_SUCCESS,
  SHOW_FAVORITE_ERROR,
  CREATE_FAVORITE_START,
  CREATE_FAVORITE_SUCCESS,
  CREATE_FAVORITE_ERROR,
  UPDATE_FAVORITE_START,
  UPDATE_FAVORITE_SUCCESS,
  UPDATE_FAVORITE_ERROR,
  ORDER_FAVORITES_START,
  ORDER_FAVORITES_SUCCESS,
  ORDER_FAVORITES_ERROR
} from '../constants/Favorite';

const defaultState = {
  path: null,

  item: {},
  itemLoading: false,
  itemFetched: false,

  items: [],
  itemsFetched: false,
  itemsLoading: false,
  meta: { page: 1, pageSize: 10 },

  errorMessage: null,
};

export default function FavoriteReducer(
  state = defaultState,
  { type, payload, items, meta, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case ADD_FAVORITES_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case ORDER_FAVORITES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        errorMessage: null,
        itemsFetched: true,
        items,
      };
    case ADD_FAVORITES_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        errorMessage: null,
        itemsFetched: true,
        items,
        meta,
      };

    case ADD_FAVORITES_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: false,
        errorMessage,
      };

    case SHOW_FAVORITE_START:
      return {
        ...state,
        itemLoading: true,
        itemFetched: false,
      };

    case SHOW_FAVORITE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        errorMessage: null,
        item,
      };

    case SHOW_FAVORITE_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemFetched: false,
        errorMessage,
      };

    case CREATE_FAVORITE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case CREATE_FAVORITE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        errorMessage: null,
        item,
      };

    case CREATE_FAVORITE_ERROR:
      return {
        ...state,
        itemLoading: false,
        errorMessage,
      };

    case UPDATE_FAVORITE_START:
      return {
        ...state,
        itemLoading: true,
      };

    case UPDATE_FAVORITE_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        errorMessage: null,
        item,
      };

    case UPDATE_FAVORITE_ERROR:
      return {
        ...state,
        itemLoading: false,
        errorMessage,
      };

    default:
      return state;
  }
}
