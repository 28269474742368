import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import PhoneNumbersIndexPage from './PhoneNumbersIndexPage/PhoneNumbersIndexPage';
import PhoneNumberShowPage from './PhoneNumberShowPage/PhoneNumberShowPage';
import PhoneNumberEditPage from './PhoneNumberEditPage/PhoneNumberEditPage';

export default [
  <Route
    exact
    key="phone-numbers"
    path="/organizations/:organizationId/phoneNumbers"
    component={RequireAuth(PhoneNumbersIndexPage)}
  />,
  <Route
    exact
    key="phone-number-show"
    path="/organizations/:organizationId/phoneNumbers/:phoneNumberId/show"
    component={RequireAuth(PhoneNumberShowPage)}
  />,
  <Route
    exact
    key="phone-number-edit"
    path="/organizations/:organizationId/phoneNumbers/:phoneNumberId/edit"
    component={RequireAuth(PhoneNumberEditPage)}
  />
];
