import React from 'react';
import PropTypes from 'prop-types';
import cl from 'classnames';

import { Button } from '../helpers';

function renderCheckbox({ label, input, meta: { touched, error, warning } }) {
  return (
    <div className={cl('form-group', { 'has-success': touched && !error && !warning, 'has-danger': touched && error, 'has-warning': touched && warning })}>
      <Button
        icon="check"
        color={input.value ? 'success' : 'secondary'}
        onClick={() => input.onChange(!input.value)}
      >
        {label}
      </Button>
      {touched && error && <div className="form-control-feedback">{error}</div>}
      {touched && warning && <div className="form-control-feedback">{warning}</div>}
    </div>
  );
}

renderCheckbox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string
};

renderCheckbox.defaultProps = {
  label: null
};

export default renderCheckbox;
