import { push } from 'connected-react-router';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_FAVORITE_START,
  DELETE_FAVORITE_SUCCESS,
  DELETE_FAVORITE_ERROR,
} from '../../constants/Favorite';

// deleteFavorite

export function deleteFavoriteStart() {
  return {
    type: DELETE_FAVORITE_START,
  };
}

export function deleteFavoriteSuccess(item = {}) {
  return {
    type: DELETE_FAVORITE_SUCCESS,
    item,
  };
}

export function deleteFavoriteError(error) {
  return {
    type: DELETE_FAVORITE_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function deleteFavorite(organizationId, id) {
  return dispatch => {
    dispatch(deleteFavoriteStart());

    return apiRequest
      .delete(`organizations/${organizationId}/favorites/${id}`)
      .then(({ data }) => {
        dispatch(deleteFavoriteSuccess(data));
      })
      .catch(error => dispatch(deleteFavoriteError(error)));
  };
}
