import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import pick from 'lodash/pick';

import {
  Button,
  LinkTo,
  renderField
} from '../../../helpers';

const fields = ['name', 'number'];

class BlacklistForm extends Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    item: PropTypes.shape({
      id: PropTypes.number
    }),
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
  };

  static defaultProps = {
    item: null,
    errorMessage: null
    };

  componentDidMount() {
    const { item, initialize } = this.props;

    if (item) {
      initialize(
        pick(get(item, 'attributes'), fields)
      );
    }
  }

  render() {
    const {
      organizationId,
      item,
      errorMessage,
      handleSubmit,
      onSubmit
    } = this.props;

    return (
      <form onSubmit={handleSubmit(values => onSubmit(values))}>
        <div className="row">
          <div className="col-12">
            <Field
              name="name"
              label="Name"
              component={renderField}
            />
            <Field name="phone_number" label="Phone Number" component={renderField} />

          </div>
        </div>

        {errorMessage ? (
          <div className="alert alert-danger">{errorMessage}</div>
        ) : null}

        <div className="form-group m-t-20">
          <div className="btn-group col-xs-12">
            <LinkTo button color="secondary" icon="times-circle" href={`/organizations/${organizationId}/blacklists`}>
              Cancel
            </LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Blacklist Item' : 'Create Blacklist Entry'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

function validate({ name, number }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please enter a name for this item';
  }

  if (!number) {
    errors.number = 'Please enter a number';
  }

  return errors;
}

export default reduxForm({ form: 'blacklist', validate })(BlacklistForm);
