import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  SHOW_PHONE_NUMBER_START,
  SHOW_PHONE_NUMBER_SUCCESS,
  SHOW_PHONE_NUMBER_ERROR
} from '../../constants/PhoneNumber';

// fetchPhoneNumber

export function showPhoneNumberStart() {
  return {
    type: SHOW_PHONE_NUMBER_START
  };
}

export function showPhoneNumber(item = {}) {
  return {
    type: SHOW_PHONE_NUMBER_SUCCESS,
    item
  };
}

export function showPhoneNumberError(error) {
  return {
    type: SHOW_PHONE_NUMBER_ERROR,
    errorMessage: get(error, 'response.data.message', 'Server-side Error')
  };
}

export default function fetchPhoneNumber(organizationId, phoneNumberId) {
  return dispatch => {
    dispatch(showPhoneNumberStart());

    return apiRequest.get(`organizations/${organizationId}/phone_numbers/${phoneNumberId}`)
      .then(({ data: { data } }) => Promise.resolve(dispatch(showPhoneNumber(data))))
      .catch(error => Promise.reject(dispatch(showPhoneNumberError(error))));
  };
}
