import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import {
  ORDER_FAVORITES_START,
  ORDER_FAVORITES_SUCCESS,
  ORDER_FAVORITES_ERROR,
} from '../../constants/Favorite';

// orderFavorites

export function orderFavoritesStart() {
  return {
    type: ORDER_FAVORITES_START,
  };
}

export function orderFavoritesSuccess(items = []) {
  return {
    type: ORDER_FAVORITES_SUCCESS,
    items,
  };
}

export function orderFavoritesError(error) {
  return {
    type: ORDER_FAVORITES_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function orderFavorites(organizationId, values) {
  return async (dispatch) => {
    dispatch(orderFavoritesStart());
    
    return apiRequest
      .put(`organizations/${organizationId}/favorites/order`, null, {
        data: values,
      })
      .then(({ data: { data: items } }) => {
        dispatch(orderFavoritesSuccess(items));
      })
      .catch((error) => dispatch(orderFavoritesError(error)));
  };
}
