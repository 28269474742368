import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import {
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from '../../constants/User';

// updateUser

export function updateUserStart() {
  return {
    type: UPDATE_USER_START,
  };
}

export function updateUserSuccess(item = {}) {
  return {
    type: UPDATE_USER_SUCCESS,
    item,
  };
}

export function updateUserError(error) {
  return {
    type: UPDATE_USER_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function updateUser(organizationId, username, values) {
  return dispatch => {
    dispatch(updateUserStart());
    const omitItems = ['username', 'email'];
    if (values.role === 'owner') {
      omitItems.push('role');
    }
    let updateValues = omit(values, omitItems);

    updateValues = Object.keys(updateValues).reduce((acc, curr) => {
      if (updateValues[curr] === '') {
        acc[curr] = null;
      } else {
        acc[curr] = updateValues[curr];
      }
      return acc;
    }, {});

    return apiRequest
      .put(`organizations/${organizationId}/users/${username}`, updateValues)
      .then(({ data: { data } }) => {
        dispatch(updateUserSuccess(data));
        // dispatch(push(`/organizations/${organizationId}/users`));
      })
      .catch(error => dispatch(updateUserError(error)));
  };
}
