import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { Boolean, Button, LinkTo, Paginate } from '../../../helpers';

function PhoneNumbersTable({ organizationId, items, meta, onPaginate, onDelete }) {
  if (size(items) === 0) {
    return <h1>No Records</h1>;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th>Phone</th>
                  <th>Description</th>
                  <th>Ring group</th>
                  <th>Route</th>
                  <th>Default?</th>
                  <th>Voice?</th>
                  <th>Fax?</th>
                  <th>MMS?</th>
                  <th>SMS?</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {items.map(item => (
                  <tr key={item.id}>
                    <td>{get(item, 'attributes.name')}</td>
                    <td>{get(item, 'attributes.description')}</td>
                    <td>
                    {get(item, 'relationships.outgoing_ring_group.data') ? (
                        <LinkTo
                          button
                          icon="phone"
                          color="outline-success"
                          href={[
                            'organizations',
                            organizationId,
                            'ringGroups',
                            get(item, 'relationships.outgoing_ring_group.data.id'),
                            'edit'
                          ].join('/')}
                        >
                          {get(item, 'relationships.outgoing_ring_group.data.attributes.name')}
                        </LinkTo>
                      ) : null}
                    </td>
                    <td>
                      {get(item, 'relationships.user_role.data') ? (
                        <LinkTo
                          button
                          icon="user"
                          color="outline-info"
                          href={[
                            'organizations',
                            organizationId,
                            'users',
                            get(item, 'relationships.user_role.data.attributes.username'),
                            'show'
                          ].join('/')}
                        >
                          {[
                            get(item, 'relationships.user_role.data.attributes.first_name'),
                            get(item, 'relationships.user_role.data.attributes.last_name')
                          ].join(' ')}
                        </LinkTo>
                      ) : null}
                      {get(item, 'relationships.call_flow.data') ? (
                        <LinkTo
                          button
                          icon="sort-amount-desc"
                          color="outline-warning"
                          href={[
                            'organizations',
                            organizationId,
                            'callFlows',
                            get(item, 'relationships.call_flow.data.id'),
                            'show'
                          ].join('/')}
                        >
                          {get(item, 'relationships.call_flow.data.attributes.name')}
                        </LinkTo>
                      ) : null}
                    </td>
                    
                    <td>
                      <Boolean value={get(item, 'attributes.default')} />
                    </td>
                    <td>
                      <Boolean value={get(item, 'attributes.capabilities.voice')} />
                    </td>
                    <td>
                      <Boolean value={get(item, 'attributes.capabilities.fax')} />
                    </td>
                    <td>
                      <Boolean value={get(item, 'attributes.capabilities.mms')} />
                    </td>
                    <td>
                      <Boolean value={get(item, 'attributes.capabilities.sms')} />
                    </td>
                    <td>
                      <div className="btn-group">
                        <LinkTo
                          button
                          icon="pencil"
                          color="outline-info"
                          href={`organizations/${organizationId}/phoneNumbers/${item.id}/edit`}
                        >
                          Edit
                        </LinkTo>
                        <Button icon="trash" color="outline-danger" onClick={() => onDelete(item.id)}>
                          Delete
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {meta ? (
          <div className="row">
            <div className="col-12 footable">
              <Paginate
                count={meta.count}
                pageSize={meta.pageSize}
                activePage={meta.page}
                onSelect={page => onPaginate(page)}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

PhoneNumbersTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sid: PropTypes.string,
      number: PropTypes.string
    })
  ).isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
    count: PropTypes.number
  }),
  onPaginate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

PhoneNumbersTable.defaultProps = {
  meta: null
};

export default PhoneNumbersTable;
