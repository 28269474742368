import React from 'react';
import { Route } from 'react-router-dom';

import SigninPage from './SigninPage/SigninPage';
import SignupPage from './SignupPage/SignupPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import UpdatePasswordPage from './UpdatePasswordPage/UpdatePasswordPage';

export default [
  <Route exact key="signin" path="/auth" component={SigninPage} />,
  <Route exact key="signup" path="/auth/signup" component={SignupPage} />,
  <Route exact key="reset" path="/auth/reset" component={ResetPasswordPage} />,
  <Route exact key="password" path="/auth/password/:code" component={UpdatePasswordPage} />
];
