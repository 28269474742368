import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  CREATE_RING_GROUP_START,
  CREATE_RING_GROUP_SUCCESS,
  CREATE_RING_GROUP_ERROR
} from '../../constants/RingGroup';

// createRingGroup

export function createRingGroupStart() {
  return {
    type: CREATE_RING_GROUP_START
  };
}

export function createRingGroupSuccess(item = {}) {
  return {
    type: CREATE_RING_GROUP_SUCCESS,
    item
  };
}

export function createRingGroupError(error) {
  return {
    type: CREATE_RING_GROUP_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function createRingGroup(organizationId, values) {
  return dispatch => {
    dispatch(createRingGroupStart());

    return apiRequest.post(`organizations/${organizationId}/ring_groups`, values)
      .then(({ data }) => Promise.resolve(dispatch(createRingGroupSuccess(data))))
      .catch(error => Promise.reject(dispatch(createRingGroupError(error))));
  };
}
