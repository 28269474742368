import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { signupUser as signupUserAction } from '../../../actions/AuthActions';

import { SignupForm } from '../../../components';
import { LinkTo } from '../../../helpers';

function SignupPage({ isLoading, successMessage, errorMessage, signupUser }) {
  return (
    <div className="login-box card">
      <div className="card-block">

        <h2 className="text-center db">
          <i className="fa fa-envelope" /> ContactFrame Admin
        </h2>

        <h3 className="box-title m-t-40 m-b-0">Register Now</h3>
        <small>Create your account and enjoy</small>

        <SignupForm
          isLoading={isLoading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          onSubmit={user => signupUser(user)}
        />

        <div className="form-group m-b-0">
          <div className="col-sm-12 text-center">
            <p>Already have an account? <LinkTo href="auth" className="m-l-5"><b>Sign In</b></LinkTo></p>
          </div>
        </div>
      </div>
    </div>
  );
}

SignupPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  signupUser: PropTypes.func.isRequired
};

SignupPage.defaultProps = {
  successMessage: null,
  errorMessage: null
};

export default connect(({
  auth: {
    isLoading,
    successMessage,
    errorMessage
  }
}) => ({ isLoading, successMessage, errorMessage }), { signupUser: signupUserAction })(SignupPage);
