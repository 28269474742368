import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import pick from 'lodash/pick';
import size from 'lodash/size';

import { AttachUserModal, AttachSubGroupModal } from '../../../components';
import { Button, LinkTo, renderField } from '../../../helpers';
import { renderHumanDateTime } from '../../../utils';

const fields = ['name', 'description', 'tag'];

class RingGroupForm extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
    ringGroupTag: PropTypes.string,
    item: PropTypes.shape({
      id: PropTypes.string
    }).isRequired,
    organization: PropTypes.shape({
      id: PropTypes.number
    }).isRequired,
    errorMessage: PropTypes.string,
    onRemoveMember: PropTypes.func,
    onRefresh: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired
  };

  static defaultProps = {
    item: null,
    errorMessage: null
  };

  componentDidMount() {
    const { item, initialize } = this.props;

    if (item) {
      initialize(pick(get(item, 'attributes'), fields));
    }
  }

  render() {
    const {
      organizationId,
      ringGroupTag,
      item,
      organization,
      errorMessage,
      onRefresh,
      handleSubmit,
      onSubmit,
      onRemoveMember,
      isNew,
      ringGroups,
      onRemoveSubGroup
    } = this.props;

    return (
      <form onSubmit={handleSubmit(values => onSubmit(values))}>
        <div className="row">
          <div className="col-12">
            <Field name="name" label="Name" component={renderField} />
            <Field name="description" label="Description" component={renderField} />
            <Field name="tag" label="Tag" component={renderField} />
          </div>
        </div>

        {isNew ? null : (
          <div>
            <div className="row">
              <div className="col-12">
                <div className="m-b-20 clearfix">
                  <h2 id="members" className="m-r-10 pull-left">
                    Members
                  </h2>
                  <AttachUserModal
                    organizationId={organizationId}
                    ringGroupTag={ringGroupTag}
                    organization={organization}
                    ringGroup={item}
                    onRefresh={onRefresh}
                  />
                </div>

                {size(get(item, 'relationships.members.data', [])) > 0 ? (
                  <ul className="list">
                    {get(item, 'relationships.members.data', []).map(user => (
                      <li key={user.id}>
                        <LinkTo
                          icon="user"
                          href={`organizations/${organizationId}/users/${user.attributes.username}/show`}
                        >
                          {`${get(user, 'attributes.first_name')} ${get(user, 'attributes.last_name')}`}
                        </LinkTo>
                        &nbsp; <Button color="danger" icon="trash" size="sm" onClick={() => onRemoveMember(user.id)} />
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="m-b-20 clearfix">
                  <h2 id="members" className="m-r-10 pull-left">
                    Subgroups
                  </h2>
                  <AttachSubGroupModal
                    organizationId={organizationId}
                    ringGroupTag={ringGroupTag}
                    organization={organization}
                    ringGroup={item}
                    ringGroups={ringGroups}
                    onRefresh={onRefresh}
                  />
                </div>
                {size(get(item, 'relationships.subgroups.data', [])) > 0 ? (
                  <ul className="list">
                    {get(item, 'relationships.subgroups.data', []).map(subGroup => (
                      <li key={subGroup.id}>
                        <LinkTo icon="user" href={`organizations/${organizationId}/ringGroups/${subGroup.id}/show`}>
                          {get(subGroup, 'attributes.name')}
                        </LinkTo>
                        &nbsp;{' '}
                        <Button color="danger" icon="trash" size="sm" onClick={() => onRemoveSubGroup(subGroup.id)} />
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            {size(get(item, 'relationships.calls.data', [])) > 0 ? <h2 id="members">Calls</h2> : null}

            {size(get(item, 'relationships.calls.data', [])) > 0 ? (
              <table className="table table-hover table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Reservation SID</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  {get(item, 'relationships.calls.data', []).map(call => (
                    <tr key={call.id}>
                      <td>{get(call, 'attributes.reservation_sid')}</td>
                      <td>{renderHumanDateTime(get(call, 'attributes.created_at'))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>

        {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}

        <div className="form-group m-t-40">
          <div className="btn-group col-xs-12">
            <LinkTo button color="secondary" icon="times-circle" href={`organizations/${organizationId}/ringGroups`}>
              Cancel
            </LinkTo>
            <Button submit color="success" icon="save">
              {item ? 'Update Ring Group' : 'Create Ring Group'}
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

function validate({ name, display_name: displayName, timezone }) {
  const errors = {};

  if (!name) {
    errors.name = 'Please enter a name';
  }

  if (!displayName) {
    errors.display_name = 'Please enter a display name';
  }

  if (!timezone) {
    errors.timezone = 'Please enter a timezone';
  }

  return errors;
}

export default reduxForm({ form: 'ringGroup', validate })(RingGroupForm);
