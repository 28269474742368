import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import momentLocalizer from 'react-widgets/lib/localizers/moment';
import cl from 'classnames';

momentLocalizer(moment);

function renderTimePicker({ input, label, meta: { touched, error, warning } }) {
  return (
    <fieldset className={cl('form-group', { 'has-error': (touched && error) })}>
      {label ? <label htmlFor={`form-input-${input.name}`}>{label}</label> : null}
      <DateTimePicker
        id={`form-input-${input.name}`}
        time
        calendar={false}
        {...input}
        format={'hh:mm:ss A'}
        timeFormat={'hh:mm:ss A'}
        onChange={(_, value) => input.onChange(moment(value, 'hh:mm:ss A').format('HH:mm:ss'))}
        onBlur={() => input.onBlur()}
        value={input.value ? moment(input.value, 'HH:mm:ss').toDate() : null}
      />
      {touched && ((error && <div className="error help-block">{error}</div>) || (warning && <div className="error">{warning}</div>))}
    </fieldset>
  );
}

renderTimePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string
  }).isRequired,
  label: PropTypes.string
};

renderTimePicker.defaultProps = {
  label: null,
  type: 'text'
};

export default renderTimePicker;
