export const ADD_ORGANIZATIONS_START = 'ADD_ORGANIZATIONS_START';
export const ADD_ORGANIZATIONS_SUCCESS = 'ADD_ORGANIZATIONS_SUCCESS';
export const ADD_ORGANIZATIONS_ERROR = 'ADD_ORGANIZATIONS_ERROR';

export const SHOW_ORGANIZATION_START = 'SHOW_ORGANIZATION_START';
export const SHOW_ORGANIZATION_SUCCESS = 'SHOW_ORGANIZATION_SUCCESS';
export const SHOW_ORGANIZATION_ERROR = 'SHOW_ORGANIZATION_ERROR';

export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';

export const UPDATE_ORGANIZATION_START = 'UPDATE_ORGANIZATION_START';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_ERROR = 'UPDATE_ORGANIZATION_ERROR';

export const DELETE_ORGANIZATION_START = 'DELETE_ORGANIZATION_START';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_ERROR = 'DELETE_ORGANIZATION_ERROR';
