import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  DELETE_CALL_QUEUE_START,
  DELETE_CALL_QUEUE_SUCCESS,
  DELETE_CALL_QUEUE_ERROR
} from '../../constants/CallQueue';

// deleteCallQueue

export function deleteCallQueueStart() {
  return {
    type: DELETE_CALL_QUEUE_START
  };
}

export function deleteCallQueueSuccess(item = {}) {
  return {
    type: DELETE_CALL_QUEUE_SUCCESS,
    item
  };
}

export function deleteCallQueueError(error) {
  return {
    type: DELETE_CALL_QUEUE_ERROR,
    errorMessage: get(error, 'response.data.errors[0].detail', 'Server-side error')
  };
}

export default function deleteCallQueue(callQueueId) {
  return dispatch => {
    dispatch(deleteCallQueueStart());

    return apiRequest.delete(`call_queues/${callQueueId}`)
      .then(() => Promise.resolve(dispatch(deleteCallQueueSuccess())))
      .catch(error => Promise.reject(dispatch(deleteCallQueueError(error))));
  };
}
