import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';

import { apiRequest } from '../../utils';

import {
  UPDATE_FAVORITE_START,
  UPDATE_FAVORITE_SUCCESS,
  UPDATE_FAVORITE_ERROR,
} from '../../constants/Favorite';

// updateFavorite

export function updateFavoriteStart() {
  return {
    type: UPDATE_FAVORITE_START,
  };
}

export function updateFavoriteSuccess(item = {}) {
  return {
    type: UPDATE_FAVORITE_SUCCESS,
    item,
  };
}

export function updateFavoriteError(error) {
  return {
    type: UPDATE_FAVORITE_ERROR,
    errorMessage: get(
      error,
      'response.data.errors[0].detail',
      'Server-side error'
    ),
  };
}

export default function updateFavorite(organizationId, id, values) {
  return dispatch => {
    dispatch(updateFavoriteStart());

    return apiRequest
      .put(`organizations/${organizationId}/favorites/${id}`, values)
      .then(({ data }) => {
        dispatch(updateFavoriteSuccess(data));
        // dispatch(push(`/organizations/${organizationId}/favorites/${id}/show`));
      })
      .catch(error => dispatch(updateFavoriteError(error)));
  };
}
