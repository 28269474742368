import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { push as pushAction } from 'connected-react-router';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import {
  fetchPhoneNumbers as fetchPhoneNumbersAction,
  fetchAvailablePhoneNumbers as fetchAvailablePhoneNumbersAction,
  buyPhoneNumber as buyPhoneNumberAction,
  deletePhoneNumber as deletePhoneNumberAction,
} from '../../../actions/PhoneNumberActions';

import { PhoneNumbersTable, BuyPhoneNumberForm } from '../../../components';
import { Button, LinkTo, Loading } from '../../../helpers';

import { Skeleton } from 'antd';

const country = 'US';

class PhoneNumbersIndexPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        sid: PropTypes.string,
      })
    ).isRequired,
    phoneNumbersFetched: PropTypes.bool.isRequired,

    availablePhoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        phoneNumber: PropTypes.string,
        friendlyName: PropTypes.string,
      })
    ).isRequired,
    availablePhoneNumbersFetched: PropTypes.bool.isRequired,
    availablePhoneNumbersErrorMessage: PropTypes.string,
    phoneNumbersErrorMessage: PropTypes.string,
    buyPhoneNumberFormValues: PropTypes.shape({
      areaCode: PropTypes.string,
      contains: PropTypes.string,
    }).isRequired,

    fetchOrganization: PropTypes.func.isRequired,
    fetchPhoneNumbers: PropTypes.func.isRequired,
    fetchAvailablePhoneNumbers: PropTypes.func.isRequired,
    buyPhoneNumber: PropTypes.func.isRequired,
    deletePhoneNumber: PropTypes.func.isRequired,
  };

  static defaultProps = {
    availablePhoneNumbersErrorMessage: null,
    phoneNumbersErrorMessage: null,
  };

  state = {
    showBuyPhoneNumberModal: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1, q = null) {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    this.props.fetchOrganization(organizationId);

    if (organizationId) {
      this.props.fetchPhoneNumbers(organizationId, { page, q });
    }
  }

  toggleBuyPhoneNumberModal() {
    const {
      match: {
        params: { organizationId },
      },
    } = this.props;

    console.log('togglingmodal');

    this.setState(
      { showBuyPhoneNumberModal: !this.state.showBuyPhoneNumberModal },
      () => {
        if (this.state.showBuyPhoneNumberModal) {
          this.props.fetchAvailablePhoneNumbers(organizationId, { country });
        }
      }
    );
  }

  render() {
    const {
      match: {
        params: { organizationId },
      },
      phoneNumbers,
      phoneNumbersFetched,
      availablePhoneNumbers,
      availablePhoneNumbersFetched,
      availablePhoneNumbersErrorMessage,
      phoneNumbersErrorMessage,
      buyPhoneNumberFormValues,
      fetchPhoneNumbers,
      fetchAvailablePhoneNumbers,
      buyPhoneNumber,
      deletePhoneNumber,
      push,
    } = this.props;
    const { showBuyPhoneNumberModal } = this.state;

    if (!organizationId) {
      return (
        <div className="row" id="organization-create">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <LinkTo href="organizations/new">
                  you need to be in an org to buy numbers!
                </LinkTo>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="row" id="phone-numbers">
        <div className="col-12">
          <div className="row page-titles">
            <div className="col-md-6 col-8 align-self-center">
              <h3 className="text-themecolor m-b-0 m-t-0">Phone Numbers</h3>
            </div>
            <div className="col-md-6 col-4 text-right">
              <div className="btn-group">
                <Button
                  icon="plus"
                  color="success"
                  onClick={() => this.toggleBuyPhoneNumberModal()}
                >
                  Buy New Number
                </Button>
                <BuyPhoneNumberForm
                  modalTitle="Buy New Number"
                  showModal={showBuyPhoneNumberModal}
                  items={availablePhoneNumbers}
                  itemsFetched={availablePhoneNumbersFetched}
                  errorMessage={
                    availablePhoneNumbersErrorMessage ||
                    phoneNumbersErrorMessage
                  }
                  onHide={() =>
                    this.setState({ showBuyPhoneNumberModal: false })
                  }
                  onSearch={() =>
                    fetchAvailablePhoneNumbers(organizationId, {
                      ...buyPhoneNumberFormValues,
                      country,
                    })
                  }
                  onSubmit={values =>
                    buyPhoneNumber(organizationId, values)
                      .then(this.setState({ showBuyPhoneNumberModal: false }))
                      .then(resp => {
                        push(
                          `/organizations/${organizationId}/phoneNumbers/${get(
                            resp,
                            'item.data.id'
                          )}/edit`
                        );
                      })
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-block">
                  <Skeleton active loading={!phoneNumbersFetched}>
                    <PhoneNumbersTable
                      organizationId={organizationId}
                      items={phoneNumbers}
                      onPaginate={page => this.fetchData(page)}
                      onDelete={id =>
                        deletePhoneNumber(organizationId, id).then(() =>
                          this.fetchData()
                        )
                      }
                    />
                  </Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const buyPhoneNumberFormSelector = formValueSelector('buy-phone-number');

export default connect(
  state => {
    const {
      auth: { currentUser },
      phoneNumbers: {
        items: phoneNumbers,
        itemsFetched: phoneNumbersFetched,
        itemsErrorMessage: phoneNumbersErrorMessage,

        availableItemsIsLoading: availablePhoneNumbersIsLoading,
        availableItemsFetched: availablePhoneNumbersFetched,
        availableItems: availablePhoneNumbers,
        availableItemsErrorMessage: availablePhoneNumbersErrorMessage,
      },
    } = state;

    return {
      currentUser,
      phoneNumbers,
      phoneNumbersFetched,
      phoneNumbersErrorMessage,
      availablePhoneNumbersIsLoading,
      availablePhoneNumbersFetched,
      availablePhoneNumbers,
      availablePhoneNumbersErrorMessage,
      buyPhoneNumberFormValues: fromPairs(
        ['areaCode', 'contains', 'test'].map(formField => [
          formField,
          buyPhoneNumberFormSelector(state, formField),
        ])
      ),
    };
  },
  {
    fetchOrganization,
    fetchPhoneNumbers: fetchPhoneNumbersAction,
    fetchAvailablePhoneNumbers: fetchAvailablePhoneNumbersAction,
    buyPhoneNumber: buyPhoneNumberAction,
    deletePhoneNumber: deletePhoneNumberAction,
    push: pushAction,
  }
)(PhoneNumbersIndexPage);
