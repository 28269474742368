import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchLiveCalls, subscribeToLiveCalls } from '../../../actions/CallActions';

import { monitorCall } from '../../../actions/MonitorActions';

import { LiveCallsTable } from '../../../components';
import { ErrorMessage, Loading } from '../../../helpers';

class CallsReportPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,

    organizationFetched: PropTypes.bool.isRequired,
    organizationErrorMessage: PropTypes.string,

    liveCalls: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    ).isRequired,
    liveCallsMeta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    liveCallsFetched: PropTypes.bool.isRequired,
    liveCallsErrorMessage: PropTypes.string,

    fetchOrganization: PropTypes.func.isRequired,
    fetchLiveCalls: PropTypes.func.isRequired,

    monitorCall: PropTypes.func
  };

  static defaultProps = {
    organizationErrorMessage: null,
    liveCallsErrorMessage: null
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchOrganization(organizationId);
    this.props.fetchLiveCalls(organizationId, { page });
    this.props.subscribeToLiveCalls(organizationId);
  }

  render() {
    const {
      match: { params: { organizationId } },
      organizationFetched,
      organizationErrorMessage,
      liveCalls,
      liveCallsMeta,
      liveCallsFetched,
      liveCallsErrorMessage
    } = this.props;

    return (
      <div id="calls-report">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Live Calls</h3>
          </div>
        </div>

        <ErrorMessage>{organizationErrorMessage || liveCallsErrorMessage}</ErrorMessage>

        <div className="row">
          <div className="col-12">
            <Loading loaded={organizationFetched && liveCallsFetched}>
              <div className="card">
                <div className="card-block">
                  <LiveCallsTable
                    organizationId={organizationId}
                    items={liveCalls}
                    meta={liveCallsMeta}
                    onPaginate={page => this.fetchData(page)}
                    onMonitorCall={id => this.props.monitorCall(organizationId, id)}
                  />
                </div>
              </div>
            </Loading>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    organizations: { itemFetched: organizationFetched, itemErrorMessage: organizationErrorMessage },
    liveCalls: { liveCalls, liveCallsMeta, liveCallsFetched, liveCallsErrorMessage }
  }) => ({
    organizationFetched,
    organizationErrorMessage,

    liveCalls,
    liveCallsMeta,
    liveCallsFetched,
    liveCallsErrorMessage
  }),
  { fetchOrganization, fetchLiveCalls, subscribeToLiveCalls, monitorCall }
)(CallsReportPage);
