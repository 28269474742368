import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ADD_CALL_FLOWS_START,
  ADD_CALL_FLOWS_SUCCESS,
  ADD_CALL_FLOWS_ERROR,
  SHOW_CALL_FLOW_START,
  SHOW_CALL_FLOW_SUCCESS,
  SHOW_CALL_FLOW_ERROR,
  CREATE_CALL_FLOW_START,
  CREATE_CALL_FLOW_SUCCESS,
  CREATE_CALL_FLOW_ERROR,
  UPDATE_CALL_FLOW_START,
  UPDATE_CALL_FLOW_SUCCESS,
  UPDATE_CALL_FLOW_ERROR,
} from '../constants/CallFlow';

const defaultState = {
  path: null,

  item: {},
  itemLoading: false,
  itemFetched: false,
  itemErrorMessage: null,

  items: [],
  itemsFetched: false,
  itemsLoading: false,
  meta: { page: 1, pageSize: 10 },

  errorMessage: null,
};

export default function CallFlowReducer(
  state = defaultState,
  { type, payload, items, meta, errorMessage, item }
) {
  switch (type) {
    case LOCATION_CHANGE:
      return state.path === payload.pathname
        ? { ...state }
        : { ...defaultState, path: payload.pathname };

    case ADD_CALL_FLOWS_START:
      return {
        ...state,
        itemsLoading: true,
        itemsFetched: false,
      };

    case ADD_CALL_FLOWS_SUCCESS:
      return {
        ...state,
        itemsLoading: false,
        errorMessage: null,
        itemsFetched: true,
        items,
        meta,
      };

    case ADD_CALL_FLOWS_ERROR:
      return {
        ...state,
        itemsLoading: false,
        itemsFetched: false,
        errorMessage,
      };

    case SHOW_CALL_FLOW_START:
      return {
        ...state,
        itemLoading: true,
        itemFetched: false,
      };

    case SHOW_CALL_FLOW_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        itemFetched: true,
        errorMessage: null,
        item,
      };

    case SHOW_CALL_FLOW_ERROR:
      return {
        ...state,
        itemLoading: false,
        itemFetched: false,
        errorMessage,
      };

    case CREATE_CALL_FLOW_START:
      return {
        ...state,
        itemLoading: true,
      };

    case CREATE_CALL_FLOW_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        errorMessage: null,
        item,
      };

    case CREATE_CALL_FLOW_ERROR:
      return {
        ...state,
        itemLoading: false,
        errorMessage,
      };

    case UPDATE_CALL_FLOW_START:
      return {
        ...state,
        itemLoading: true,
      };

    case UPDATE_CALL_FLOW_SUCCESS:
      return {
        ...state,
        itemLoading: false,
        errorMessage: null,
        item,
      };

    case UPDATE_CALL_FLOW_ERROR:
      return {
        ...state,
        itemLoading: false,
        errorMessage,
      };

    default:
      return state;
  }
}
