import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchOrganization } from '../../../actions/OrganizationActions';
import { fetchRingGroups } from '../../../actions/RingGroupActions';

import { RingGroupsTable } from '../../../components';
import { LinkTo, Loading } from '../../../helpers';

class RingGroupsIndexPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        organizationId: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    ).isRequired,
    meta: PropTypes.shape({
      page: PropTypes.number.isRequired,
      pageSize: PropTypes.number.isRequired,
      count: PropTypes.number
    }).isRequired,
    itemsFetched: PropTypes.bool.isRequired,
    fetchOrganization: PropTypes.func.isRequired,
    fetchRingGroups: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData(page = 1) {
    const { match: { params: { organizationId } } } = this.props;

    this.props.fetchRingGroups(organizationId, { page });
  }

  render() {
    const { match: { params: { organizationId } }, items, itemsFetched, meta } = this.props;

    return (
      <div id="ring-groups">
        <div className="row page-titles">
          <div className="col-md-6 col-8 align-self-center">
            <h3 className="text-themecolor m-b-0 m-t-0">Ring Groups</h3>
          </div>
          <div className="col-md-6 col-4 align-self-center">
            <LinkTo
              button
              href={`organizations/${organizationId}/ringGroups/new`}
              color="success"
              addClass="pull-right hidden-sm-down"
            >
              <i className="mdi mdi-plus-circle" /> Create RingGroup
            </LinkTo>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-block">
                <Loading loaded={itemsFetched}>
                  <RingGroupsTable
                    organizationId={organizationId}
                    items={items}
                    meta={meta}
                    onPaginate={page => this.fetchData(page)}
                  />
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ ringGroups: { items, itemsFetched, meta } }) => ({ items, itemsFetched, meta }), {
  fetchRingGroups
})(RingGroupsIndexPage);
