import Promise from 'bluebird';

import { CREATE_CALL_FLOW_ENTRY_SUCCESS } from '../../constants/CallFlowEntry';

// createCallFlowEntry

export function createCallFlowEntrySuccess(action) {
  return {
    type: CREATE_CALL_FLOW_ENTRY_SUCCESS,
    action
  };
}

export default function createCallFlowEntry(action) {
  return dispatch => Promise.resolve(dispatch(createCallFlowEntrySuccess(action)));
}
