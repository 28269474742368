import React from 'react';
import { Route } from 'react-router-dom';

import { RequireAuth } from '../../utils';

import CallQueuesIndexPage from './CallQueuesIndexPage/CallQueuesIndexPage';
import CallQueueAddPage from './CallQueueAddPage/CallQueueAddPage';
import CallQueueShowPage from './CallQueueShowPage/CallQueueShowPage';
import CallQueueEditPage from './CallQueueEditPage/CallQueueEditPage';
import CallQueueDeletePage from './CallQueueDeletePage/CallQueueDeletePage';

export default [
  <Route
    exact
    key="organization-call-queues"
    path="/organizations/:organizationId/callQueues"
    component={RequireAuth(CallQueuesIndexPage)}
  />,
  <Route
    exact
    key="organization-call-queue-new"
    path="/organizations/:organizationId/callQueues/new"
    component={RequireAuth(CallQueueAddPage)}
  />,
  <Route
    exact
    key="organization-call-queue-show"
    path="/organizations/:organizationId/callQueues/:callQueueId/show"
    component={RequireAuth(CallQueueShowPage)}
  />,
  <Route
    exact
    key="organization-call-queue-edit"
    path="/organizations/:organizationId/callQueues/:callQueueId/edit"
    component={RequireAuth(CallQueueEditPage)}
  />,
  <Route
    exact
    key="organization-call-queue-delete"
    path="/organizations/:organizationId/callQueues/:callQueueId/delete"
    component={RequireAuth(CallQueueDeletePage)}
  />
];
