import Promise from 'bluebird';

import { apiRequest } from '../../utils';

import { setAuthenticatedUser, authError, logoutUser } from '../AuthActions';

// validateToken

export default function validateToken() {
  const accessToken = localStorage.getItem('access_token');

  return dispatch => {
    if (accessToken) {
      return apiRequest.authToken(accessToken)
        .then(({ data: { data: user } }) => dispatch(setAuthenticatedUser(user)))
        .catch(error => dispatch(authError(error)));
    }

    return Promise.resolve(dispatch(logoutUser()));
  };
}
