import Promise from 'bluebird';
import get from 'lodash/get';

import { apiRequest } from '../../utils';

import {
  BUY_PHONE_NUMBER_START,
  BUY_PHONE_NUMBER_SUCCESS,
  BUY_PHONE_NUMBER_ERROR
} from '../../constants/PhoneNumber';

// buyPhoneNumber

export function buyPhoneNumberStart() {
  return {
    type: BUY_PHONE_NUMBER_START
  };
}

export function buyPhoneNumberSuccess(item = {}) {
  return {
    type: BUY_PHONE_NUMBER_SUCCESS,
    item
  };
}

export function buyPhoneNumberError(error) {
  return {
    type: BUY_PHONE_NUMBER_ERROR,
    errorMessage: get(error, 'response.data.message', 'Server-side error')
  };
}

export default function buyPhoneNumber(organizationId, { phone_number: phoneNumber }) {
  return dispatch => {
    dispatch(buyPhoneNumberStart());

    return apiRequest.post(`organizations/${organizationId}/phone_numbers/${phoneNumber}`, {})
      .then(({ data }) => Promise.resolve(dispatch(buyPhoneNumberSuccess(data))))
      .catch(error => Promise.reject(dispatch(buyPhoneNumberError(error))));
  };
}
